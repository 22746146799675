import { combineReducers, configureStore } from '@reduxjs/toolkit';
import customerReducer from '../features/customer/customerSlice';
import messageReducer from '../features/messages/messageSlice';
import sidepanelReducer from '../features/sidepanel/sidepanelSlice';
import userInfoReducer from '../features/sidepanel/userInfoSlice';
import inputReducer from '../features/input/inputSlice';

import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

const persistConfig = {
    key: 'root',
    storage: sessionStorage,
};

const rootReducer = combineReducers({
    customer: customerReducer,
    message: messageReducer,
    sidepanel: sidepanelReducer,
    input: inputReducer,
    userInfo: userInfoReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});
