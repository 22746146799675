import {
    Box,
    Button,
    Select,
    InputBase,
    IconButton,
    Divider,
    Grid,
    Menu,
    MenuItem,
    Stack,
    Typography,
    Modal,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import DataGrid from '../../components/DataGrid';
import { useMenu } from '../../hooks/useMenu';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { conditions } from '../../data/filters';
import { useDebouncedValue } from '@mantine/hooks';
import AddIcon from '@mui/icons-material/Add';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Filter from '../../components/Filter';
import useModal from '../../hooks/useModal';
import CustomerView from './CustomerView';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';

const columns = [
    {
        field: 'id',
        headerName: 'Id',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 100,
    },
    {
        field: 'customer',
        headerName: 'Cliente 1',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 300,
        renderCell: params => params.row.customer.first_name + ' ' + params.row.customer.last_name,
    },
    {
        field: 'similar_customer',
        headerName: 'Cliente 2',
        sortable: false,
        width: 300,headerAlign: 'center',
        align: 'center',
        renderCell: params =>
            params.row.similar_customer.first_name + ' ' + params.row.similar_customer.last_name,
    },
    {
        field: 'merged',
        headerName: 'Dati Clienti Uniti',
        sortable: false,
        width: 150,headerAlign: 'center',
        align: 'center',
        align: 'center',
        renderCell: params => {
            if (params.row.merged) return <CheckCircleIcon sx={{ color: green['A700'] }} />;
            return <CancelRoundedIcon sx={{ color: red['500'] }} />;
        },
    },
];

const Index = () => {
    const customerId = useParams().id;
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });
    const [rowCount, setRowCount] = useState(0);
    const {
        anchorEl: anchorElFilter,
        openMenu: openFilterMenu,
        closeMenu: closeFilterMenu,
    } = useMenu();

    const [filters, setFilters] = useState([{ field: '', condition: '', value: '' }]);
    const [options, setOptions] = useState({ filter_operator: 'and', filter: false });
    const [search, setSearch] = useState('');
    const searchText = useDebouncedValue(search, 400)[0];
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const { modalState, openModal, closeModal } = useModal();

    const onFilterHandler = (e, i) => {
        const { name, value } = e.target;
        setFilters(prev => {
            const newFilters = [...prev];
            newFilters[i][name] = value;
            return newFilters;
        });
    };

    const addFilter = () => {
        setFilters(prev => [...prev, { field: '', condition: '', value: '' }]);
    };

    const removeFilter = i => {
        if (!filters[i]) return;
        const updated = [...filters];
        updated.splice(i, 1);
        setFilters(updated);
    };

    const { data, error, isError, isLoading, refetch } = useQuery({
        queryKey: [
            'similar-customer',
            customerId,
            paginationModel.page,
            searchText,
            options.filter,
        ],
        queryFn: async ({ queryKey }) => {
            const params = {
                customer: queryKey[1],
                page: Boolean(paginationModel.page) ? paginationModel.page + 1 : 1,
            };

            if (searchText) params.filter = JSON.stringify({ search: searchText });

            if (options.filter)
                params.filter = JSON.stringify({
                    fields: filters,
                    filter_operator: options.filter_operator,
                    // ...(searchText ? { search: searchText } : {}),
                });

            const response = await axios.get('/similar-customer/', { params });
            setRowCount(response.data.count);
            return response.data;
        },
    }); // Bugfix page size

    const similarCustomer = useMemo(() => (data ? data.results : []), [data]);

    useEffect(() => {
        setRowCount(prev => (data?.count !== undefined ? data?.count : prev));
    }, [data?.count, setRowCount]);

    if (isError) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}>
                <Typography variant='h5' sx={{ color: 'error.main' }}>
                    {error.message}
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    p: 2,
                    mt: 4,
                }}>
                <Typography variant='h5'>Lista Matching Clienti</Typography>
                <Divider variant='fullWidth' sx={{ my: 2 }} />

                <Box bgcolor='#DBDBF0' p={1} mb={4} borderRadius='50px'>
                    <Grid container columnSpacing={2} alignItems='center'>
                        <Grid item xs={12} md>
                            <Typography variant='subtitle1' fontWeight={500}>
                                Visualizzati <b> {similarCustomer.length} Matching Clienti </b>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md='auto'>
                            <Filter
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                                onClick={openFilterMenu}
                                wrapperProps={{
                                    sx: {
                                        marginLeft: 'auto',
                                        maxWidth: '188px',
                                        width: '100%',
                                        border: '2px solid white',
                                        boxShadow: 'none',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <DataGrid
                    rows={similarCustomer}
                    columns={columns}
                    autoHeight
                    onRowClick={params => {
                        openModal();
                        setSelectedCustomer(params.row);
                    }}
                    rowSelection={false}
                    disableSelectionOnClick
                    loading={isLoading}
                    rowCount={rowCount}
                    checkboxSelection={false}
                    pageSizeOptions={[40]}
                    paginationModel={paginationModel}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                />
                <Menu
                    anchorEl={anchorElFilter}
                    open={Boolean(anchorElFilter)}
                    onClose={closeFilterMenu}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    // type='glassMorphism'

                    sx={{
                        marginTop: '20px',
                        '& ul.MuiList-root.MuiMenu-list': {
                            padding: 0,
                        },
                        '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                            width: 'min(100%, 1000px)',
                            boxShadow:
                                'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
                            border: '1px solid',
                            borderColor: 'white',
                            backgroundColor: 'background.default',
                            borderRadius: '8px',
                            p: 0,
                            overflowY: 'unset',
                        },
                    }}>
                    <Box p={1.6}>
                        {filters.map((filter, i) => (
                            <Stack direction='row' spacing={2} padding={1.4}>
                                <Select
                                    displayEmpty
                                    name='field'
                                    value={filter.field}
                                    onChange={e => onFilterHandler(e, i)}
                                    fullWidth
                                    renderValue={v => {
                                        if (!v) return 'Colonna';
                                        return v;
                                    }}
                                    sx={{ borderRadius: 1.5, border: '2px solid white' }}>
                                    {columns.map(col => (
                                        <MenuItem value={col.field} key={col.field}>
                                            {col.headerName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    displayEmpty
                                    name='condition'
                                    value={filter.condition}
                                    onChange={e => onFilterHandler(e, i)}
                                    fullWidth
                                    size='small'
                                    renderValue={v => {
                                        if (!v) return 'Condizione';
                                        return v;
                                    }}
                                    sx={{ borderRadius: 1.5, border: '2px solid white' }}>
                                    {conditions.map(cond => (
                                        <MenuItem value={cond.value} key={cond.name}>
                                            {cond.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <InputBase
                                    name='Valore'
                                    placeholder='Valore'
                                    value={filter.value}
                                    size='small'
                                    onChange={e => onFilterHandler(e, i)}
                                    fullWidth
                                    sx={{ borderRadius: 1.5, border: '2px solid white', px: 1 }}
                                />
                                <IconButton
                                    disabled={i === 0}
                                    onClick={() => removeFilter(i)}
                                    color='text.tertiary'>
                                    <DeleteTwoToneIcon />
                                </IconButton>
                            </Stack>
                        ))}
                    </Box>
                    <Stack
                        direction='row'
                        p={2}
                        justifyContent='space-between'
                        alignItems='center'
                        sx={{ backgroundColor: 'white' }}>
                        <Stack direction='row' spacing={1.5}>
                            <Button
                                variant={options.filter_operator === 'and' ? 'contained' : 'filter'}
                                onClick={() =>
                                    setOptions(prev => ({ ...prev, filter_operator: 'and' }))
                                }
                                sx={{ borderRadius: 1.5 }}>
                                E
                            </Button>
                            <Button
                                variant={options.filter_operator === 'or' ? 'contained' : 'filter'}
                                onClick={() =>
                                    setOptions(prev => ({ ...prev, filter_operator: 'or' }))
                                }
                                sx={{ borderRadius: 1.5 }}>
                                O
                            </Button>
                            <Divider orientation='vertical' flexItem variant='middle' />
                            <Button
                                variant='text'
                                startIcon={<AddIcon />}
                                color='primary'
                                onClick={addFilter}
                                sx={{
                                    '&, &:hover': {
                                        boxShadow: 'none',
                                        borderRadius: '0',
                                    },
                                }}>
                                Nuovo Filtro
                            </Button>
                        </Stack>
                        <Box>
                            <Button
                                variant='filter'
                                sx={{ minWidth: 120, borderRadius: 1.5, mr: 1 }}
                                onClick={() => {
                                    setOptions(prev => ({ ...prev, filter: false }));
                                    setFilters([{ field: '', condition: '', value: '' }]);
                                    closeFilterMenu();
                                }}>
                                Svuota
                            </Button>
                            <Button
                                variant='contained'
                                sx={{ minWidth: 120, borderRadius: 1.5 }}
                                onClick={() => {
                                    setOptions(prev => ({ ...prev, filter: true }));
                                    refetch();
                                    closeFilterMenu();
                                }}>
                                Crea
                            </Button>
                        </Box>
                    </Stack>
                </Menu>

                <Modal
                    open={modalState}
                    onClose={closeModal}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <CustomerView
                        closeModal={closeModal}
                        customer={selectedCustomer}
                        refetch={refetch}
                    />
                </Modal>
            </Box>
        </>
    );
};

export default Index;
