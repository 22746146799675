import {
    Box,
    Grid,
    Divider,
    Stack,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Modal,
    IconButton,
} from '@mui/material';
import React, { useCallback } from 'react';
import Image from '../../components/Image';
import ShadowCard from '../../components/ShadowCard';
import { useQuery } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import OutsetBox from '../../components/OutsetBox';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StarsIcon from '@mui/icons-material/Stars';
import { useMessage } from '../../layouts/Header';
import Refund from './Refund';
import useModal from '../../hooks/useModal';
import Edit from '@mui/icons-material/Edit';
import OrderTable from '../../components/Table/OrderTable';

const columns = [
    { headerName: 'Date', width: 100 },
    { headerName: 'Payment Type', width: 150 },
    { headerName: 'Refund Type', width: 150 },
    { headerName: 'Print Type', width: 150 },
    { headerName: 'Print Status', width: 150 },
    { headerName: 'Status', width: 150 },
    { headerName: 'Quantità', width: 100 },
    { headerName: 'Subtotal', width: 150 },
    { headerName: 'Total', width: 150 },
    { headerName: 'Package dimension', width: 200 },
    { headerName: 'Shipping label', width: 200 },
    { headerName: 'Shipping retire status', width: 200 },
    { headerName: 'Shipping tracking link', width: 200 },
    { headerName: 'Graphic request', width: 150 },
    { headerName: 'Graphic date', width: 150 },
    { headerName: 'Graphic status', width: 150 },
    { headerName: 'Graphic design1', width: 300 },
    { headerName: 'Graphic mockup1', width: 300 },
    { headerName: 'Graphic design2', width: 300 },
    { headerName: 'Graphic mockup2', width: 300 },
    { headerName: 'Graphic customer approval', width: 250 },
    { headerName: 'Graphic edit request', width: 350 },
    { headerName: 'Graphic edit date', width: 150 },
    { headerName: 'Graphic edit approval', width: 200 },
    { headerName: 'Graphic file', width: 300 },
];

const OrderPreview = () => {
    const { orderId } = useParams();
    const { showSuccess, showError, showResponse } = useMessage();

    const {
        modalState: refundState,
        openModal: openRefundModal,
        closeModal: closeRefundModal,
    } = useModal();

    const { data: order, isLoading, refetch } = useQuery({
        queryKey: ['orders', orderId],
        queryFn: async () => {
            const response = await axios.get(`/orders/${orderId}`);
            return response.data;
        },
    });

    // const download = useCallback(
    //     async url => {
    //         showResponse('Downloading...');

    //         try {
    //             const response = await axios.get(url, {
    //                 responseType: 'blob',
    //             });

    //             const type = response.data?.type.split('/')[1];

    //             const blob = new Blob([response.data]);
    //             const URL = window.URL.createObjectURL(blob);
    //             const link = document.createElement('a');
    //             link.href = URL;
    //             link.download = `${Date.now()}.${type}`;
    //             document.body.appendChild(link);
    //             link.click();
    //             showResponse(`Downloaded successfully`);

    //             window.URL.revokeObjectURL(URL);
    //             document.body.removeChild(link);
    //         } catch (e) {
    //             showError('Errore durante il download')
    //             console.log(e);
    //         }
    //     },
    //     [showResponse]
    // );

    return order ? (
        <>
            <Box
                sx={{
                    p: 2,
                    mt: 8,
                }}>
                <ShadowCard>
                    <OrderTable orders={[order]} refetch={refetch} isLoading={isLoading} rowCount={1}/>
                </ShadowCard>

                <ShadowCard
                    sx={{
                        p: 2.5,
                        mt: 4,
                    }}>
                    {order.groups.map(group => (
                        <Box>
                            <Stack
                                direction='row'
                                alignItems='center'
                                justifyContent='space-between'
                                mb={1.2}>
                                <Typography variant='h6' fontWeight={500} color='primary'>
                                    Gruppo Prodotto
                                </Typography>
                                <IconButton
                                    LinkComponent={Link}
                                    to={`/dashboard/order/edit/${order.id}`}
                                    color='primary'>
                                    <Edit />
                                </IconButton>
                            </Stack>
                            <Grid container spacing={4}>
                                {group.items.map(item => {
                                    const attributes = item.attributes
                                        ? Object.keys(item.attributes)
                                        : [];
                                    return (
                                        <Grid item xs={12} lg={6}>
                                            <Stack
                                                direction='row'
                                                justifyContent='space-between'
                                                spacing={3}>
                                                <Typography
                                                    variant='subtitle1'
                                                    fontWeight={500}
                                                    color='text.secondary'>
                                                    {item.name}
                                                </Typography>
                                            </Stack>
                                            <Grid container spacing={2} mt={2}>
                                                <Grid item xs={12} md={6} lg={3}>
                                                    <OutsetBox
                                                        label='Quantità'
                                                        value={item.quantity}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={3}>
                                                    <OutsetBox label='Prezzo' value={(item.price/item.quantity).toFixed(2)+' €'} />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    <OutsetBox label='Prezzo totale' value={item.price+' €'} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                {attributes.map(attribute => (
                                                    <Grid item>
                                                        <OutsetBox
                                                            label={attribute}
                                                            value={item.attributes[attribute]}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                                <Grid item xs={12} lg={6} display='flex' flexDirection='column'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={group.files.length ? 8 : 12}>
                                            <Typography
                                                variant='subtitle1'
                                                fontWeight={500}
                                                color='text.secondary'
                                                mb={2}>
                                                Descrizione
                                            </Typography>
                                            <Box
                                                sx={{
                                                    borderRadius: '8px',
                                                    p: 2,
                                                    boxShadow:
                                                        '8px 8px 8px #d2d4d685, -8px -8px 8px #ffffff82',
                                                    backgroundColor: 'background.default',
                                                    flexGrow: 1,
                                                }}>
                                                {group.description}
                                            </Box>
                                        </Grid>
                                        {group.files.length ? (
                                            <Grid item xs={12} lg={4}>
                                                <Typography
                                                    variant='subtitle1'
                                                    fontWeight={500}
                                                    color='text.secondary'
                                                    mb={2}>
                                                    Files
                                                </Typography>
                                                {group.files.map(file => (
                                                    <Box
                                                        display='flex'
                                                        alignItems='center'
                                                        height='inherit'>
                                                        <a target='_blank' href={file?.file || file?.link}>
                                                            <Image // onClick={() => download(file?.link)}
                                                                src={file?.file || file?.link}
                                                                sx={{ maxHeight: 160 }}
                                                            />
                                                        </a>
                                                    </Box>
                                                ))}
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                </ShadowCard>

                <ShadowCard mt={6}>
                    <Stack direction={{ xs: 'column', lg: 'row' }} spacing={5}>
                        <Box flexGrow={1}>
                            <Stack direction='row' spacing={1}>
                                <AccessTimeFilledIcon />
                                <Typography variant='subtitle1' fontWeight={500}>
                                Metodo Lavorazione
                                </Typography>
                            </Stack>
                            <Divider
                                sx={{ width: 386, borderWidth: 1, color: 'text.primary', mb: 3 }}
                            />
                            <Stack direction='row' spacing={2} mb={3}>
                                <OutsetBox
                                    label='Titolo'
                                    sx={{ flexGrow: 1 }}
                                    value={
                                        order.work_time_method ? order.work_time_method.title : order.work_time_type
                                    }
                                />

                                <OutsetBox
                                    label='Prezzo'
                                    sx={{ flexGrow: 1 }}
                                    value={order.work_time_price+'€'}
                                />
                            </Stack>
                        </Box>

                        <Box flexGrow={1}>
                            <Stack direction='row' spacing={1}>
                                <LocalShippingIcon />
                                <Typography variant='subtitle1' fontWeight={500}>
                                    Metodo Spedizione
                                </Typography>
                            </Stack>
                            <Divider
                                sx={{ width: 386, borderWidth: 1, color: 'text.primary', mb: 3 }}
                            />
                            <Stack direction='row' spacing={2} mb={3}>
                                <OutsetBox
                                    label='Titolo'
                                    sx={{ flexGrow: 1 }}
                                    value={order.shipping_method ? order.shipping_method.title : order.shipping_type}
                                />
                                <OutsetBox
                                    label='Prezzo'
                                    sx={{ flexGrow: 1 }}
                                    value={order.shipping_price+'€'}
                                />
                            </Stack>
                        </Box>
                    </Stack>

                    {order.extra_services.length ? (
                        <>
                            <Stack direction='row' spacing={1} mt={3}>
                                <StarsIcon />
                                <Typography variant='subtitle1' fontWeight={500}>
                                Servizi Extra
                                </Typography>
                            </Stack>

                            <Divider
                                sx={{ width: 386, borderWidth: 1, color: 'text.primary', mb: 3 }}
                            />
                            {order.extra_services.map((service, i) => (
                                <Box mb={5} key={i}>
                                    <Stack
                                        direction='row'
                                        spacing={2}
                                        mb={2}
                                        alignItems='center'
                                        justifyContent='space-between'>
                                        <Stack direction='row' spacing={2}>
                                            <OutsetBox
                                                label='Titolo'
                                                value={service.title}
                                                sx={{ minWidth: '200px' }}
                                            />
                                            <OutsetBox
                                                label='Prezzo'
                                                value={service.price}
                                                sx={{ minWidth: '200px' }}
                                            />
                                        </Stack>
                                    </Stack>

                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                        justifyContent='space-between'>
                                        <Typography
                                            variant='subtitle1'
                                            fontWeight={500}
                                            color='text.tertiary'
                                            mb={2}>
                                            Descrizione
                                        </Typography>
                                    </Stack>
                                    <OutsetBox
                                        label='Description'
                                        value={service.description}
                                        sx={{ borderRadius: '8px', px: 2 }}
                                    />
                                </Box>
                            ))}
                        </>
                    ) : null}
                </ShadowCard>

                <Grid container spacing={5} mt={2}>
                    {order.billing ? (
                        <Grid item xs={12} lg={6}>
                            <ShadowCard>
                                <Typography variant='subtitle1' fontWeight={500}>
                                    Indirizzo di Fatturazione
                                </Typography>

                                <Divider
                                    sx={{
                                        width: 386,
                                        borderWidth: 1,
                                        color: 'text.primary',
                                        mb: 4,
                                    }}
                                />

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox
                                            label='Nome'
                                            value={order.billing.first_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox
                                            label='Cognome'
                                            value={order.billing.last_name}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={9}>
                                        <OutsetBox
                                            label='Ragione Sociale'
                                            value={order.billing.company}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <OutsetBox label='Partita IVA' value={order.billing.vat} />
                                    </Grid>
                                </Grid>
                                <OutsetBox label='Indirizzo1' value={order.billing.address_1} />
                                <OutsetBox label='Indirizzo2' value={order.billing.address_2} />

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox label='Stato' value={order.billing.country} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox label='Provincia' value={order.billing.state} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox label='Città' value={order.billing.city} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox
                                            label='Codice Postale'
                                            value={order.billing.postcode}
                                        />
                                    </Grid>
                                </Grid>
                            </ShadowCard>
                        </Grid>
                    ) : null}
                    {order.shipping ? (
                        <Grid item xs={12} lg={6}>
                            <ShadowCard>
                                <Typography variant='subtitle1' fontWeight={500}>
                                    Indirizzo di Spedizione
                                </Typography>

                                <Divider
                                    sx={{
                                        width: 386,
                                        borderWidth: 1,
                                        color: 'text.primary',
                                        mb: 4,
                                    }}
                                />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox
                                            label='Nome'
                                            value={order.shipping.first_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox
                                            label='Cognome'
                                            value={order.shipping.last_name}
                                        />
                                    </Grid>
                                </Grid>

                                <OutsetBox label='Ragione Sociale' value={order.shipping.company} />

                                <OutsetBox label='Indirizzo1' value={order.shipping.address_1} />
                                <OutsetBox label='Indirizzo2' value={order.shipping.address_2} />

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox label='Stato' value={order.shipping.country} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox label='Provincia' value={order.shipping.state} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox label='Città' value={order.shipping.city} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox
                                            label='Codice Postale'
                                            value={order.shipping.postcode}
                                        />
                                    </Grid>
                                </Grid>
                            </ShadowCard>
                        </Grid>
                    ) : null}
                </Grid>

                <ShadowCard mt={6} sx={{ p: 4 }}>
                    <Table>
                        <TableHead sx={{ bgcolor: 'white' }}>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>Valore</TableCell>
                                <TableCell>Note</TableCell>
                                <TableCell>Motivo</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order.refunds
                                ? order.refunds.refunds.map(refund => (
                                      <TableRow
                                          sx={{
                                              '&:last-child td, &:last-child th': {
                                                  border: 0,
                                              },
                                          }}>
                                          <TableCell>{refund.amount}</TableCell>
                                          <TableCell>{refund.note}</TableCell>
                                          <TableCell>{refund.reason}</TableCell>
                                      </TableRow>
                                  ))
                                : null}
                        </TableBody>
                    </Table>
                    <Button variant='contained' onClick={openRefundModal} sx={{ mt: 1 }}>
                       Crea Rimborso
                    </Button>
                </ShadowCard>
            </Box>

            <Modal
                open={refundState}
                onClose={closeRefundModal}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <>
                    <Refund closeModal={closeRefundModal} orderId={orderId} refetch={refetch} />
                </>
            </Modal>
        </>
    ) : null;
};

export default OrderPreview;
