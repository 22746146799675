import { Card, Popover, Stack, Typography } from '@mui/material';

const DetailPanel = ({ details, closeDetail, detailAnchorEl }) => {
    const { first_name, last_name, email, phone, username } = details;

    return (
        <Popover
            open={Boolean(detailAnchorEl)}
            anchorEl={detailAnchorEl}
            onClose={closeDetail}
            sx={{
                pointerEvents: 'none',
                '.MuiPaper-root.MuiPopover-paper': {
                    boxShadow: 'none',
                },
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            disableRestoreFocus>
            <Card
                sx={{
                    p: 1.5,
                    width: '300px',
                    boxShadow:
                        'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
                    border: '1px solid',
                    borderColor: 'white',
                    backdropFilter: 'blur(6px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    borderRadius: '8px',
                }}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' mb={1}>
                    <Typography variant='caption' fontWeight={600}>
                        Name
                    </Typography>

                    <Typography variant='caption'>
                        {first_name ? first_name + ' ' + last_name : 'N/A'}
                    </Typography>
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center' mb={1}>
                    <Typography variant='caption' fontWeight={600}>
                        Email
                    </Typography>

                    <Typography variant='caption'>{email ? email : 'N/A'}</Typography>
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center' mb={1}>
                    <Typography variant='caption' fontWeight={600}>
                        Telefono
                    </Typography>

                    <Typography variant='caption'>{phone ? phone : 'N/A'}</Typography>
                </Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center' mb={1}>
                    <Typography variant='caption' fontWeight={600}>
                        Username
                    </Typography>

                    <Typography variant='caption'>{username ? username : 'N/A'}</Typography>
                </Stack>
            </Card>
        </Popover>
    );
};

export default DetailPanel;
