import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Modal,
    Select,
    Stack,
    Typography,
} from '@mui/material';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StarsIcon from '@mui/icons-material/Stars';
import { LeftArrowIcon } from '../../components/ArrowIcon';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import axios from 'axios';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useMessage } from '../../layouts/Header';
import useErrorHandler from '../../hooks/useErrorHandler';
import { Form, Submit, useForm } from '../../hooks/useForm';
import { Input } from '../../hooks/useForm/inputs/index';
import GroupCard from '../../components/GroupCard';
import { useMenu } from '../../hooks/useMenu';
import { useSelector } from 'react-redux';
import CustomInput from '../../components/Input/input';
import InsetBox from '../../components/InsetBox';
import Image from '../../components/Image';
import { getBase64, getSessionData, isDefined, setSessionData } from '../../utils/function';
import useModal from '../../hooks/useModal';
import Import from './Import';
import { useParams } from 'react-router-dom';
import AddProduct from '../../components/AddProduct';

const CreateQuote = () => {
    const { quoteId } = useParams();
    const {
        anchorEl: anchorElBillingAddress,
        openMenu: openBillingAddressMenu,
        closeMenu: closeBillingAddressMenu,
    } = useMenu();
    const {
        anchorEl: anchorElShippingAddress,
        openMenu: openShippingAddressMenu,
        closeMenu: closeShippingAddressMenu,
    } = useMenu();
    const { anchorEl: anchorElSite, openMenu: openSiteMenu, closeMenu: closeSiteMenu } = useMenu();
    const { modalState: importState, openModal: openImport, closeModal: closeImport } = useModal();
    const {
        modalState: addProductState,
        openModal: openAddProduct,
        closeModal: closeAddProduct,
    } = useModal();
    const customer = useSelector(state => state.customer.customer);
    const [billing, setBilling] = useState({});
    const [shipping, setShipping] = useState({});
    const [site, setSite] = useState(customer.site ? customer.site[0] : {});
    const [search, setSearch] = useState();
    const [groups, setGroups] = useState([
        {
            description: '',
            files: [],
            items: [
                {
                    product: 0,
                    quantity: 1,
                    attributes: {},
                    base_price: 0,
                    price: 0,
                },
            ],
        },
    ]);
    const [fetchProductIds, setFetchProductIds] = useState([]);
    const [extraServices, setExtraServices] = useState([]);
    const [extraServicesPrice, setExtraServicesPrice] = useState([]);
    const [extraServicesDescription, setExtraServicesDescription] = useState([]);
    const { showSuccess, showError } = useMessage();
    const errorHandler = useErrorHandler();
    const fileRef = useRef(null);
    const [quoteInfo, setQuoteInfo] = useState({
        work_time_method_date: '',
        shipping_method_date: '',
        total: 0,
        total_discount: 0,
        total_price: 0,
    });

    const productHandler = (value, gIndex, pIndex) => {
        groups[gIndex].items[pIndex] = {
            ...groups[gIndex].items[pIndex],
            product: parseInt(value),
        };
        setGroups([...groups]);
    };

    const quantityHandler = (value, gIndex, pIndex) => {
        groups[gIndex].items[pIndex] = {
            ...groups[gIndex].items[pIndex],
            quantity: value ? parseInt(value) : 1,
        };
        setGroups([...groups]);
        getPrices({ groups });
    };

    const basePriceHandler = (value, gIndex, pIndex) => {
        groups[gIndex].items[pIndex] = {
            ...groups[gIndex].items[pIndex],
            base_price: value ? parseFloat(value) : 0,
            field_modified: 'base_price',
        };
        setGroups([...groups]);
        getPrices({ groups });
    };

    const priceHandler = (value, gIndex, pIndex) => {
        groups[gIndex].items[pIndex] = {
            ...groups[gIndex].items[pIndex],
            price: value ? parseFloat(value) : 0,
            field_modified: 'price',
        };
        setGroups([...groups]);
        getPrices({ groups });
    };

    const attributesHandler = (value, gIndex, pIndex) => {
        groups[gIndex].items[pIndex] = {
            ...groups[gIndex].items[pIndex],
            attributes: value,
        };
        setGroups([...groups]);
    };

    const productVariantHandler = (value, gIndex, pIndex) => {
        groups[gIndex].items[pIndex] = {
            ...groups[gIndex].items[pIndex],
            product_variant: value,
        };
        setGroups([...groups]);
        console.log(groups);
        getPrices({ groups });
    };

    const groupHandler = (e, gIndex) => {
        const { value, name } = e.target;
        groups[gIndex] = { ...groups[gIndex], [name]: value };
        setGroups([...groups]);
        getPrices({ groups });
    };

    const addCard = () => {
        const newArr = [...groups];
        newArr.push({
            description: '',
            files: [],
            items: [{ product: 0, quantity: 1, base_price: 0, price: 0, attributes: {} }],
        });
        setGroups(newArr);
    };

    const deleteCard = i => {
        groups.splice(i, 1);
        setGroups([...groups]);
    };

    const addService = () => {
        const newArrServ = [...extraServices];
        const newArrDesc = [...extraServicesDescription];
        newArrServ.push({ extra_service: '', price: '' });
        newArrDesc.push({ description: '', files: [] });
        setExtraServices(newArrServ);
        setExtraServicesDescription(newArrDesc);
    };

    const deleteService = i => {
        extraServices.splice(i, 1);
        extraServicesDescription.splice(i, 1);
        extraServicesPrice.splice(i, 1);
        setExtraServices([...extraServices]);
        setExtraServicesDescription([...extraServicesDescription]);
        setExtraServicesPrice([...extraServicesPrice]);
        getPrices({ extraServices });
    };

    const extraServicesHandler = (e, i) => {
        const { value } = e.target;
        extraServices[i] = { ...extraServices[i], extra_service: value };
        setExtraServices([...extraServices]);
        getPrices({ extraServices });
    };

    const fileHandler = async (e, i) => {
        e.stopPropagation();

        const file = e.target.files[0];
        if (!file) return showError('No file selected');
        const base64 = await getBase64(file);
        extraServicesDescription[i].files[0] = { file: base64 };
        setExtraServicesDescription([...extraServicesDescription]);
    };

    const handlers = useForm(
        useMemo(
            () => ({
                work_time_method: '',
                work_time_price: '',
                shipping_methods: '',
                shipping_price: '',
                total: '',

                b_first_name: '',
                b_last_name: '',
                b_request_invoice: { value: false },
                b_company: '',
                b_vat: '',
                b_address_1: '',
                b_address_2: '',
                b_country: '',
                b_state: '',
                b_city: '',
                b_postcode: '',

                s_first_name: '',
                s_last_name: '',
                s_company: '',
                s_address_1: '',
                s_address_2: '',
                s_country: '',
                s_state: '',
                s_city: '',
                s_postcode: '',
            }),
            []
        ),
        { Input: CustomInput }
    );

    const values = handlers.values;
    const setValues = handlers.setValues;
    const errors = handlers.errors;

    const customChangeHandler = e => {
        const { name, value } = e.target;
        setValues({ [name]: value });
    };

    const onSubmit = response => {
        if (response.data) {
            showSuccess('Preventivo creato con successo');
            setQuoteInfo({
                work_time_method_date: '',
                shipping_method_date: '',
                total: 0,
                total_discount: 0,
                total_price: 0,
            });
            setGroups([
                {
                    description: '',
                    files: [],
                    items: [
                        {
                            product: 0,
                            quantity: 1,
                            attributes: {},
                            base_price: 0,
                            price: 0,
                        },
                    ],
                },
            ]);
            sessionStorage.clear('quote');
            sessionStorage.clear('quote-group');
        }
    };

    const final = values => {
        const {
            b_first_name,
            b_last_name,
            b_company,
            b_vat,
            b_address_1,
            b_address_2,
            b_country,
            b_state,
            b_city,
            b_postcode,
            b_request_invoice,
            s_first_name,
            s_last_name,
            s_company,
            s_vat,
            s_address_1,
            s_address_2,
            s_country,
            s_state,
            s_city,
            s_postcode,
        } = values;

        const billing = {
            first_name: b_first_name,
            last_name: b_last_name,
            company: b_company,
            vat: b_vat,
            address_1: b_address_1,
            address_2: b_address_2,
            city: b_city,
            postcode: b_postcode,
            state: b_state,
            country: b_country,
            request_invoice: Boolean(b_request_invoice),
        };

        const shipping = {
            first_name: s_first_name,
            last_name: s_last_name,
            company: s_company,
            vat: s_vat,
            address_1: s_address_1,
            address_2: s_address_2,
            city: s_city,
            postcode: s_postcode,
            state: s_state,
            country: s_country,
        };

        for (let key in values) {
            if (key.startsWith('b_') || key.startsWith('s_')) delete values[key];
        }

        const extra_services = extraServices.map((service, i) => ({
            extra_service: service.extra_service,
            price: extraServicesPrice[i],
            files: extraServicesDescription[i]?.files,
            description: extraServicesDescription[i]?.description,
        }));

        return {
            ...values,
            shipping,
            billing,
            groups,
            extra_services,
            customer: customer.id,
            site: site.id,
        };
    };

    const isGroupsFilled = useMemo(
        () => groups.every(el => el.items.every(item => item.product && parseInt(item.quantity))),
        [groups]
    );

    const getPrices = useCallback(
        async options => {
            const postData = {
                site: site.id,
                groups,
                ...(values.work_time_method ? { work_time_method: values.work_time_method } : {}),
                ...(values.shipping_methods ? { shipping_methods: values.shipping_methods } : {}),
            };

            if (options.groups) postData.groups = options.groups;

            if (options.shipping_methods) postData.shipping_methods = options.shipping_methods;

            if (options.work_time_method) postData.work_time_method = options.work_time_method;

            if (options.extraServices?.every(service => service.extra_service))
                postData.extra_services = options.extraServices;

            try {
                const { data } = await axios.post('/quotes/get_quote/', { ...postData });

                if (isDefined(data.shipping_method_price))
                    setValues({ shipping_price: data.shipping_method_price });

                if (isDefined(data.work_time_method_price))
                    setValues({ work_time_price: data.work_time_method_price });

                setValues({
                    total: data.total_price,
                });
                setQuoteInfo({
                    shipping_method_date: data.shipping_method_date,
                    work_time_method_date: data.work_time_method_date,
                    total: data.total,
                    total_discount: data.total_discounted,
                    total_price: data.total_price,
                });

                if (data.groups) {
                    const groupsWithPrice = data.groups.map((group, gIndex) => {
                        return {
                            description: group.description,
                            files: group.files,
                            items: group.items.map((item, pIndex) => {
                                return {
                                    ...item,
                                    attributes: { ...groups[gIndex].items[pIndex].attributes },
                                };
                            }),
                        };
                    });

                    setGroups(groupsWithPrice);
                }

                if (data.extra_services.length)
                    setExtraServicesPrice(data.extra_services.map(service => service.price));
            } catch (err) {
                errorHandler(err);
            }
        },
        [errorHandler, site, groups, values.work_time_method, values.shipping_methods, setValues]
    );

    const getQuote = useCallback(
        async id => {
            try {
                const response = await axios.get(`/quotes/${id}`);
                const {
                    site,
                    groups,
                    shipping,
                    billing,
                    shipping_method,
                    shipping_price,
                    work_time_method,
                    work_time_price,
                    total,
                } = response.data;
                setSite(site);
                setQuoteInfo(prev => ({ ...prev, total }));
                setValues({
                    shipping_methods: shipping_method?.id,
                    shipping_price,
                    work_time_method: work_time_method?.id,
                    work_time_price: work_time_price,
                });
                const products = [];
                const importGroups = [];

                groups.forEach(group => {
                    importGroups.push({
                        description: group.description,
                        files: group.files,
                        items: group.items.length
                            ? group.items.map(item => {
                                  products.push(item.product);
                                  return {
                                      product: item.product || 0,
                                      quantity: item.quantity || 1,
                                  };
                              })
                            : [{ product: 0, quantity: 1 }],
                    });
                });

                const billingAddress = {};
                const shippingAddress = {};
                setGroups(importGroups);

                const exceptionKeys = ['id', 'favorite', 'customer'];

                if (billing)
                    Object.keys(billing).forEach(key => {
                        if (!exceptionKeys.includes(key)) billingAddress['b_' + key] = billing[key];
                    });
                if (shipping)
                    Object.keys(shipping).forEach(key => {
                        if (!exceptionKeys.includes(key))
                            shippingAddress['s_' + key] = shipping[key];
                    });

                setFetchProductIds(products);
                setValues({ ...billingAddress, ...shippingAddress });
            } catch (err) {
                errorHandler(err);
            }
        },
        [errorHandler, setValues]
    );

    // Async request
    const { data, error, isError } = useQuery({
        queryKey: ['products', site.id, search],
        queryFn: async ({ queryKey }) => {
            const params = {};
            if (queryKey[1]) params.site__id = queryKey[1];

            if (queryKey[2]) params.name = queryKey[2];

            const { data } = await axios.get('/products/', { params });
            return data;
        },
        enabled: Boolean(site.id),
    });

    const productFetch = useQueries({
        queries: fetchProductIds.map(id => ({
            queryKey: ['products', site.id, id],
            queryFn: async ({ queryKey }) => {
                const params = {};
                if (queryKey[1]) params.site__id = queryKey[1];

                const { data } = await axios.get(`/products/${id}/`, { params });
                return data;
            },
        })),
        combine: results => {
            const hasLoadedAll = results.every(result => result.data);

            if (hasLoadedAll) {
                const obj = {};
                results.forEach(result => (obj[result.data.id] = result.data));
                return obj;
            }
        },
    });

    const { data: siteData } = useQuery({
        queryKey: ['sites', site.id],
        queryFn: async () => {
            const { data } = await axios.get(`/sites/${site.id}/`);
            return data;
        },
        enabled: Boolean(site.id),
    });

    const { data: sites } = useQuery({
        queryKey: ['sites'],
        queryFn: async () => {
            const { data } = await axios.get(`/sites/`);
            return data;
        },
        enabled: Boolean(!site.id),
    });

    const [productListAll, setProductListAll] = useState({});
    const productList = useMemo(() => {
        if (data) {
            const obj = {};
            data.results.forEach(prod => (obj[prod.id] = prod));
            setProductListAll({...productListAll, ...obj});
            return obj;
        }
        return {};
    }, [data]);

    useEffect(() => {
        if (quoteId) getQuote(quoteId);
    }, [quoteId, getQuote]);

    useEffect(() => {
        if (!quoteId)
            setGroups([
                {
                    description: '',
                    files: [],
                    items: [
                        {
                            product: 0,
                            quantity: 1,
                            attributes: {},
                            base_price: 0,
                            price: 0,
                        },
                    ],
                },
            ]);
    }, [quoteId]);

    useEffect(() => {
        const storage = getSessionData('quote');
        if (storage) {
            const { groups, site, values, quoteInfo} = JSON.parse(storage);

            setGroups(groups);
            setSite(site);
            if (values)
                setValues(values);
            setQuoteInfo(quoteInfo);

        }
    }, []);

    useEffect(() => {
        return () => {
            setSessionData('quote', JSON.stringify({ groups, site, values, quoteInfo }));
        };
    }, [groups, site, values, quoteInfo]);

    if (isError) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}>
                <Typography variant='h5' sx={{ color: 'error.main' }}>
                    {error.message}
                </Typography>
            </Box>
        );
    }

    return (
        <Box my={6}>
            <Typography variant='h6' fontWeight={500} color='primary.main' textAlign='center'>
                Creazione Preventivi
            </Typography>

            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Stack
                    direction='row'
                    spacing={1}
                    justifyContent='space-between'
                    alignItems={'center'}
                    flexBasis={386}>
                    <Stack direction='row' spacing={1}>
                        <CheckroomIcon />
                        <Typography variant='subtitle1' fontWeight={500}>
                            Prodotti
                        </Typography>
                    </Stack>
                    <Button
                        startIcon={<AddCircleIcon />}
                        variant='outlined'
                        color='primary'
                        onClick={openAddProduct}
                        sx={{ py: 0.2 }}>
                        Aggiungi Nuovo Prodotto
                    </Button>
                </Stack>
                <Stack direction='row' spacing={2}>
                    <Button
                        variant='text'
                        onClick={openSiteMenu}
                        endIcon={
                            <LeftArrowIcon
                                sx={{ transform: 'rotate(270deg)' }}
                                fontSize='16px'
                                borderWidth='1px'
                            />
                        }>
                        {site.id ? site.name : 'Customer Sites'}
                    </Button>
                    <Button variant='contained' onClick={openImport}>
                        Importa Preventivo
                    </Button>
                    {/* <Button variant='outlined'>Importa preventivo</Button> */}
                </Stack>
            </Stack>
            <Divider sx={{ width: 386, borderWidth: 1, color: 'text.primary' }} />

            <Form
                handlers={handlers}
                onError={errorHandler}
                onSubmit={onSubmit}
                final={final}
                action={quoteId ? `/quotes/${quoteId}/` : '/quotes/'}
                method={quoteId ? 'patch' : 'post'}>
                {groups.map((group, i) => {
                    const obj = {};
                    group.items.forEach((item, index) => {
                        obj[index] = {};
                        if (item.attributes) {
                            const attributes = Object.keys(item.attributes);
                            attributes.forEach(attribute => {
                                obj[index][attribute] = item.attributes[attribute];
                            });
                        }
                    });

                    return (
                        <GroupCard
                            variant='quote-group'
                            group={group}
                            key={i}
                            productList={{ ...productFetch, ...productList }}
                            productListAll={productListAll}
                            groupHandler={groupHandler}
                            productHandler={productHandler}
                            groupIndex={i}
                            addCard={addCard}
                            deleteCard={deleteCard}
                            setSearch={setSearch}
                            setGroups={setGroups}
                            productVariantHandler={productVariantHandler}
                            quantityHandler={quantityHandler}
                            attributesHandler={attributesHandler}
                            basePriceHandler={basePriceHandler}
                            priceHandler={priceHandler}
                            groupAttributes={obj}
                        />
                    );
                })}

                <Divider
                    variant='fullWidth'
                    sx={{ my: 5, borderWidth: 1, color: 'text.primary' }}
                />

                {Array.isArray(siteData) ? null : (
                    <>
                        <Stack direction='row' spacing={1}>
                            <AccessTimeFilledIcon />
                            <Typography variant='subtitle1' fontWeight={500}>
                                Metodo Lavorazione
                            </Typography>
                        </Stack>
                        <Divider
                            sx={{ width: 386, borderWidth: 1, color: 'text.primary', mb: 3 }}
                        />
                        <Stack direction='row' spacing={2} mb={3} alignItems='center'>
                            <Select
                                fullWidth
                                sx={{ maxWidth: '370px' }}
                                displayEmpty
                                value={values.work_time_method}
                                name='work_time_method'
                                onChange={e => {
                                    getPrices({ work_time_method: e.target.value });
                                    customChangeHandler(e);
                                }}
                                disabled={!isGroupsFilled}
                                isError={Boolean(errors.work_time_method)}
                                error={errors.work_time_method}>
                                <MenuItem disabled={true} value=''>
                                    Metodo Lavorazione
                                </MenuItem>
                                {siteData &&
                                    siteData.work_time_methods.map(method => (
                                        <MenuItem
                                            key={method.id}
                                            value={method.id}
                                            onClick={() =>
                                                setValues({ work_time_price: method.price })
                                            }>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    fontSize: 14,
                                                    color: 'text.secondary',
                                                }}>
                                                {method.title}
                                            </ListItemText>
                                        </MenuItem>
                                    ))}
                            </Select>

                            <InsetBox
                                label='Prezzo'
                                sx={{ flexBasis: '200px' }}
                                value={values.work_time_price}
                            />

                            {quoteInfo.work_time_method_date ? (
                                <Typography variant='body2' color='primary.main' fontWeight={500}>
                                    Stampato entro il{' '}
                                    {new Date(quoteInfo.work_time_method_date).toLocaleDateString()}
                                </Typography>
                            ) : null}
                            <FormHelperText error={errors.work_time_method} sx={{ ml: 2 }}>
                                {errors.work_time_method}
                            </FormHelperText>
                        </Stack>
                        <Stack direction='row' spacing={1}>
                            <LocalShippingIcon />
                            <Typography variant='subtitle1' fontWeight={500}>
                                Metodo Spedizione
                            </Typography>
                        </Stack>
                        <Divider
                            sx={{ width: 386, borderWidth: 1, color: 'text.primary', mb: 3 }}
                        />
                        <Stack direction='row' spacing={2} mb={3} alignItems='center'>
                            <Select
                                fullWidth
                                sx={{ maxWidth: '370px' }}
                                displayEmpty
                                name='shipping_methods'
                                value={values.shipping_methods}
                                onChange={e => {
                                    getPrices({ shipping_methods: e.target.value });
                                    customChangeHandler(e);
                                }}
                                disabled={!isGroupsFilled}
                                isError={Boolean(errors.shipping_methods)}
                                error={errors.shipping_methods}>
                                <MenuItem disabled={true} value=''>
                                    Metodo Spedizione
                                </MenuItem>
                                {siteData &&
                                    siteData.shipping_methods.map(method => (
                                        <MenuItem
                                            key={method.id}
                                            value={method.id}
                                            onClick={() =>
                                                setValues({ shipping_price: method.price })
                                            }>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    fontSize: 14,
                                                    color: 'text.secondary',
                                                }}>
                                                {method.title}
                                            </ListItemText>
                                        </MenuItem>
                                    ))}
                            </Select>

                            <InsetBox
                                label='Prezzo'
                                sx={{ flexBasis: '200px' }}
                                value={values.shipping_price}
                            />

                            {quoteInfo.shipping_method_date ? (
                                <Typography variant='body2' color='primary.main' fontWeight={500}>
                                    Consegnato il{' '}
                                    {new Date(quoteInfo.shipping_method_date).toLocaleDateString()}
                                </Typography>
                            ) : null}
                            <FormHelperText error={errors.shipping_methods} sx={{ ml: 2 }}>
                                {errors.shipping_methods}
                            </FormHelperText>
                        </Stack>
                        <Stack direction='row' alignItems='center' justifyContent='space-between'>
                            <Stack direction='row' spacing={1}>
                                <StarsIcon />
                                <Typography variant='subtitle1' fontWeight={500}>
                                    Servizi Extra
                                </Typography>
                            </Stack>
                            <Button
                                variant='text'
                                disabled={!isGroupsFilled}
                                onClick={addService}
                                endIcon={<AddCircleIcon />}>
                                Aggiungi Servizio
                            </Button>
                        </Stack>
                        <Divider
                            sx={{ width: 386, borderWidth: 1, color: 'text.primary', mb: 3 }}
                        />
                    </>
                )}
                <Box mb={5}>
                    {extraServices.map((service, i) => (
                        <React.Fragment key={i}>
                            <Stack
                                direction='row'
                                spacing={2}
                                mb={2}
                                alignItems='center'
                                justifyContent='space-between'>
                                <Stack direction='row' spacing={2}>
                                    <Select
                                        fullWidth
                                        sx={{ minWidth: '370px' }}
                                        displayEmpty
                                        value={service.extra_service}
                                        name='extra_service'
                                        disabled={!isGroupsFilled}
                                        onChange={e => extraServicesHandler(e, i)}>
                                        <MenuItem disabled={true} value=''>
                                            Servizi Extra
                                        </MenuItem>
                                        {siteData &&
                                            siteData.extra_services.map(service => (
                                                <MenuItem key={service.id} value={service.id}>
                                                    <ListItemText
                                                        primaryTypographyProps={{
                                                            fontSize: 14,
                                                            color: 'text.secondary',
                                                        }}>
                                                        {service.title}
                                                    </ListItemText>
                                                </MenuItem>
                                            ))}
                                    </Select>

                                    <InsetBox
                                        label='Prezzo'
                                        value={service.price || extraServicesPrice[i]}
                                        sx={{ minWidth: '200px' }}
                                    />
                                </Stack>
                                <Stack direction='row' spacing={2}>
                                    <Button
                                        variant='text'
                                        sx={{ px: 2, mb: 2 }}
                                        onClick={() => fileRef.current?.click()}>
                                        Importa File
                                    </Button>
                                    <IconButton onClick={() => deleteService(i)}>
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Stack>
                            </Stack>
                            <Grid container spacing={2} alignItems='center'>
                                <Grid item xs={12} md={8} lg={10}>
                                    <Typography
                                        variant='subtitle1'
                                        fontWeight={500}
                                        color='text.tertiary'
                                        mb={2}>
                                        Descrizione
                                    </Typography>

                                    <Input
                                        name='description'
                                        value={extraServicesDescription[i]?.description}
                                        sx={{ borderRadius: '8px', px: 2 }}
                                        onChange={e => {
                                            extraServicesDescription[i].description =
                                                e.target.value;
                                            setExtraServicesDescription([
                                                ...extraServicesDescription,
                                            ]);
                                        }}
                                        inset
                                        multiline
                                        minRows={5}
                                        maxRows={5}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={2}>
                                    {extraServicesDescription[i]?.files[0] ? (
                                        <>
                                            <Image
                                                src={extraServicesDescription[i]?.files[0]?.file}
                                                sx={{ maxHeight: 160 }}
                                            />
                                        </>
                                    ) : null}

                                    <input
                                        type='file'
                                        ref={fileRef}
                                        style={{ display: 'none' }}
                                        onChange={e => fileHandler(e, i)}
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Box>

                <Stack direction='column' alignItems='flex-end' my={4}>
                    <Typography variant='body1' fontWeight={500}>
                        Prezzo:{' '}
                        <Typography
                            variant='body1'
                            display='inline-flex'
                            fontWeight={500}
                            color='primary.main'>
                            {quoteInfo.total}€
                        </Typography>
                    </Typography>
                    <Typography variant='body1' fontWeight={500}>
                        Sconto:{' '}
                        <Typography
                            variant='body1'
                            display='inline-flex'
                            fontWeight={500}
                            color='primary.main'>
                            {quoteInfo.total_discount}€
                        </Typography>
                    </Typography>
                    <Typography variant='body1' fontWeight={500}>
                        Totale:{' '}
                        <Typography
                            variant='body1'
                            display='inline-flex'
                            fontWeight={500}
                            color='primary.main'>
                            {quoteInfo.total_price}€
                        </Typography>
                    </Typography>
                </Stack>

                <Grid container spacing={5}>
                    <Grid item xs={12} lg={6}>
                        <Stack direction='row' alignItems='center' justifyContent='space-between'>
                            <Typography variant='subtitle1' fontWeight={500}>
                                Indirizzo di Fatturazione
                            </Typography>

                            <Stack direction='row' spacing={2}>
                                <Button
                                    variant='text'
                                    onClick={openBillingAddressMenu}
                                    endIcon={
                                        <LeftArrowIcon
                                            sx={{ transform: 'rotate(270deg)' }}
                                            fontSize='16px'
                                            borderWidth='1px'
                                        />
                                    }>
                                    {billing.id
                                        ? billing.first_name + ' ' + billing.last_name
                                        : 'Indirizzi Fatturazione Cliente'}
                                </Button>
                            </Stack>
                        </Stack>
                        <Divider
                            sx={{ width: 386, borderWidth: 1, color: 'text.primary', mb: 4 }}
                        />

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Input label='Nome' name='b_first_name' />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input label='Cognome' name='b_last_name' />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={9}>
                                <Input label='Ragione Sociale' name='b_company' />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Input label='Partita IVA' name='b_vat' />
                            </Grid>
                        </Grid>
                        <Input label='Indirizzo1' name='b_address_1' />
                        <Input label='Indirizzo2' name='b_address_2' />

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Input label='Stato' name='b_country' />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input label='Provincia' name='b_state' />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Input label='Città' name='b_city' />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input label='Codice Postale' name='b_postcode' type='number' />
                            </Grid>
                        </Grid>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.b_request_invoice}
                                    onChange={(e, checked) =>
                                        setValues({ b_request_invoice: checked })
                                    }
                                    sx={{
                                        boxShadow:
                                            '2px 2px 2px #d2d4d685, -2px -2px -2px #ffffff82',
                                        backgroundColor: 'background.default',
                                        overflow: 'hidden',
                                        p: 0,
                                        m: 1,
                                        ml: 2,
                                        '& .MuiSvgIcon-root': { fontSize: 28, overflow: 'hidden' },
                                    }}
                                />
                            }
                            label='Richiesta Fattura'
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Stack direction='row' alignItems='center' justifyContent='space-between'>
                            <Typography variant='subtitle1' fontWeight={500}>
                                Indirizzo di Spedizione
                            </Typography>

                            <Stack direction='row' spacing={2}>
                                <Button
                                    variant='text'
                                    onClick={openShippingAddressMenu}
                                    endIcon={
                                        <LeftArrowIcon
                                            sx={{ transform: 'rotate(270deg)' }}
                                            fontSize='16px'
                                            borderWidth='1px'
                                        />
                                    }>
                                    {shipping.id
                                        ? shipping.first_name + ' ' + shipping.last_name
                                        : 'Indirizzi Spedizione Cliente'}
                                </Button>
                            </Stack>
                        </Stack>
                        <Divider
                            sx={{ width: 386, borderWidth: 1, color: 'text.primary', mb: 4 }}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Input label='Nome' name='s_first_name' />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input label='Cognome' name='s_last_name' />
                            </Grid>
                        </Grid>

                        <Input label='Ragione Sociale' name='s_company' />

                        <Input label='Indirizzo1' name='s_address_1' />
                        <Input label='Indirizzo2' name='s_address_2' />

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Input label='Stato' name='s_country' />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input label='Provincia' name='s_state' />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Input label='Città' name='s_city' />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input label='Codice Postale' name='s_postcode' type='number' />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} textAlign='center'>
                        <Submit>
                            {loader => (
                                <Button
                                    variant='outlined'
                                    type='submit'
                                    disabled={Boolean(loader) || !isGroupsFilled}
                                    sx={{ fontSize: 18, px: 4 }}
                                    endIcon={loader}>
                                    Crea Preventivo
                                </Button>
                            )}
                        </Submit>
                    </Grid>
                </Grid>
            </Form>

            {/* Site Menu */}
            <Menu
                anchorEl={anchorElSite}
                open={Boolean(anchorElSite)}
                onClose={closeSiteMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                type='glassMorphism'
                sx={{
                    '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                        width: 'min(100%, 340px)',
                    },
                    '& .MuiButtonBase-root:hover': {
                        backgroundColor: 'primary.main',
                        '& .MuiTypography-root, .MuiSvgIcon-root': {
                            color: 'white',
                        },
                    },
                }}>
                {Boolean(customer.site)
                    ? customer.site.map(site => (
                          <MenuItem
                              key={site.id}
                              onClick={() => {
                                  setSite(site);
                                  closeSiteMenu();
                              }}>
                              <ListItemText
                                  primaryTypographyProps={{
                                      fontSize: 14,
                                      color: 'text.secondary',
                                  }}>
                                  {site.name}
                              </ListItemText>
                          </MenuItem>
                      ))
                    : sites
                    ? sites.results.map(site => (
                          <MenuItem
                              key={site.id}
                              onClick={() => {
                                  setSite(site);
                                  closeSiteMenu();
                              }}>
                              <ListItemText
                                  primaryTypographyProps={{
                                      fontSize: 14,
                                      color: 'text.secondary',
                                  }}>
                                  {site.name}
                              </ListItemText>
                          </MenuItem>
                      ))
                    : null}
            </Menu>

            {/* Biiling Address Menu */}
            <Menu
                anchorEl={anchorElBillingAddress}
                open={Boolean(anchorElBillingAddress)}
                onClose={closeBillingAddressMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                type='glassMorphism'
                sx={{
                    '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                        width: 'min(100%, 340px)',
                    },
                    '& .MuiButtonBase-root:hover': {
                        backgroundColor: 'primary.main',
                        '& .MuiTypography-root, .MuiSvgIcon-root': {
                            color: 'white',
                        },
                    },
                }}>
                {customer.billing ? (
                    customer.billing.map(address => (
                        <MenuItem
                            key={address.id}
                            onClick={() => {
                                setBilling(address);
                                setValues({
                                    b_first_name: address.first_name,
                                    b_last_name: address.last_name,
                                    b_company: address.company,
                                    b_vat: address.vat,
                                    b_address_1: address.address_1,
                                    b_address_2: address.address_2,
                                    b_country: address.country,
                                    b_state: address.state,
                                    b_city: address.city,
                                    b_postcode: address.postcode,
                                });
                                closeBillingAddressMenu();
                            }}>
                            <ListItemText
                                primaryTypographyProps={{
                                    fontSize: 14,
                                    color: 'text.secondary',
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: '1',
                                }}>
                                {address.first_name + ' ' + address.last_name}
                            </ListItemText>
                        </MenuItem>
                    ))
                ) : (
                    <Typography variant='body2'>
                        Indirizzi Fatturazione Cliente non disponibile. Seleziona un cliente
                    </Typography>
                )}
            </Menu>

            {/* Indirizzo di Spedizione Menu */}
            <Menu
                anchorEl={anchorElShippingAddress}
                open={Boolean(anchorElShippingAddress)}
                onClose={closeShippingAddressMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                type='glassMorphism'
                sx={{
                    '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                        width: 'min(100%, 340px)',
                    },
                    '& .MuiButtonBase-root:hover': {
                        backgroundColor: 'primary.main',
                        '& .MuiTypography-root, .MuiSvgIcon-root': {
                            color: 'white',
                        },
                    },
                }}>
                {customer.shipping ? (
                    customer.shipping.map(address => (
                        <MenuItem
                            key={address.id}
                            onClick={() => {
                                setShipping(address);
                                setValues({
                                    s_first_name: address.first_name,
                                    s_last_name: address.last_name,
                                    s_company: address.company,
                                    s_address_1: address.address_1,
                                    s_address_2: address.address_2,
                                    s_country: address.country,
                                    s_state: address.state,
                                    s_city: address.city,
                                    s_postcode: address.postcode,
                                });
                                closeShippingAddressMenu();
                            }}>
                            <ListItemText
                                primaryTypographyProps={{
                                    fontSize: 14,
                                    color: 'text.secondary',
                                }}>
                                {address.first_name + ' ' + address.last_name}
                            </ListItemText>
                        </MenuItem>
                    ))
                ) : (
                    <Typography variant='body2'>
                        Indirizzi Spedizione Cliente non disponibile. Seleziona un cliente
                    </Typography>
                )}
            </Menu>

            <Modal
                open={importState}
                onClose={closeImport}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Import
                    closeModal={closeImport}
                    setValues={setValues}
                    setGroups={setGroups}
                    setSite={setSite}
                    setFetchProductIds={setFetchProductIds}
                />
            </Modal>

            <Modal
                open={addProductState}
                onClose={closeAddProduct}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <AddProduct closeModal={closeAddProduct} />
            </Modal>
        </Box>
    );
};

export default CreateQuote;
