const customer = {
    customer_type: ['private', 'company'],
    priority: ['low', 'medium', 'high'],
};
const customer_translate = {
    customer_type: {
        'private':'Privato',
        'company':'Azienda'
    },
    priority: {
        'low':'Basso',
        'medium':'Medio',
        'high':'Alto'
    }
};

const order = {
    status: [
        'ATTIVO',
        'BLOCCATO',
        'COMPLETATO',
        'ANNULLATO'
        // 'pending',
        // 'processing',
        // 'on-hold',
        // 'completed',
        // 'cancelled',
        // 'refunded',
        // 'failed',
        // 'shipment-paid',
        // 'shipment-arrived',
        // 'shipment-problem',
    ],
    print_type: ['Stampa Diretta', 'DTF'],
    print_status: [
        'FORNITORE PROBLEM',
        'CONTROLLO QUALITÀ',
        'DA STAMPARE',
        'ATTESA GRAFICA',
        'STAMPATO',
    ],
    shipping_retire_status: [
        'ATTESA RITIRO',
        'RITIRATO',
        'IMBALLATO',
        'SPED DA PAGARE',
        'SPEDIZIONE PRONTA',
        'SPEDITO',
        'CONSEGNATO',
    ],
    graphic_request: ['SI', 'NO'],
    graphic_status: ['ATTESA CLIENTE', 'GRAFICO WORKING', 'CARICATA'],
    graphic_customer_approval: ['RICHIESTA MODIFICHE', 'APPROVATA', 'NON APPROVATA'],
    graphic_edit_approval: ['RICHIESTA MODIFICHE', 'APPROVATA', 'NON APPROVATA'],
    service_type: ['PRODOTTO + STAMPA','SOLO STAMPA', 'PAGAMENTO EXTRA', 'RISTAMPA PRODOTTO'],
    payment_status: ['Pagato','Da Pagare', 'RIMBORSO', 'Pagato Parzialmente'],
    payment_type:['Stripe', 'Paypal', 'Negozio Contanti', 'Negozio Carta'],
    payment_type_2_tranche:['Stripe', 'Paypal', 'Negozio Contanti', 'Negozio Carta'],
    invoice:['Ricevuta', 'Fattura'],
    creation_origin:['Manuale', 'Sito'],
    shipping_type:['SPEDIZIONE', 'RITIRO NEGOZIO'],
    work_time_type:['Stampa 4h', 'Stampa 24h', 'Hurry Up', 'Take It Easy'],
};

const assistance_request = {
    status: ['new', 'pending', 'closed'],
};
const assistance_request_translate = {
    new: 'Nuovo',
    pending: 'In Corso',
    closed: 'Concluso'    
};

export { customer, order, assistance_request, assistance_request_translate, customer_translate };
