import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

const StyledBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0.8),
    borderRadius: '500px',
    backgroundColor: theme.palette.background.default,
    boxShadow: 'inset 5px 5px 5px #d2d4d685, inset -5px -5px 5px #ffffff82',
    marginBottom: theme.spacing(2),
    border: '1px solid white',
    display: 'flex',
    alignItems: 'center',
}));

const InsetBox = ({ label, value, ...rest }) => {
    return (
        <StyledBox {...rest}>
            <Typography variant='body2' fontWeight={400} color='text.primary' pt={0.2} pl={0.5}>
                {label} : {value}
            </Typography>
        </StyledBox>
    );
};

export default InsetBox;
