import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import React, { useEffect, useRef, useMemo, useState, useCallback, memo } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Avatar from '../../components/Avatar';
import useErrorHandler from '../../hooks/useErrorHandler';
import axios from 'axios';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useIntersection } from '@mantine/hooks';
import { setCustomer } from '../../features/customer/customerSlice';
import { useDispatch } from 'react-redux';
import { useMenu } from '../../hooks/useMenu';
import Search from '../../components/Search';
import { isUndefined } from '../../hooks/useForm/utilities/functions';
import { DateTime } from 'luxon';
import { useAuthorize } from '../../hooks/Authorize';
import { generateDate } from '../../utils/function';
import {customer_visual_name} from '../../utils/function';


const filterOptions = {
    sortBy: [
        { name: 'Tutte le chat', value: '' },
        { name: 'Non letti', value: true },
        { name: 'I più recenti', value: 'last_message_time' },
        { name: 'I più vecchi', value: '-last_message_time' },
    ],
    priority: [
        { name: 'Alto', value: 'high' },
        { name: 'Medio', value: 'medium' },
        { name: 'Basso', value: 'low' },
    ],
    channels: [
        { name: 'Whatsapp', value: 'whatsapp' },
        { name: 'Messenger', value: 'messenger' },
        { name: 'Telegram', value: 'telegram' },
        { name: 'Discord', value: 'discord' },
        { name: 'Skype', value: 'skype' },
        { name: 'Zoom', value: 'zoom' },
        { name: 'Meet', value: 'meet' },
        { name: 'Gmail', value: 'gmail' },
    ],
};

const Sidebar = props => {
    const { isSidePanelOpen } = props;
    const customerId = useParams().id;
    const errorHandler = useErrorHandler();
    const lastCustomerRef = useRef(null);
    const dispatch = useDispatch();
    const [sortBy, setSortBy] = useState(filterOptions.sortBy[2]);
    const [priority, setPriorità] = useState({});
    const [channels, setChannels] = useState({});
    const authorize = useAuthorize();
    const [search, setSearch] = useState('');
    const {
        anchorEl: anchorElFilter,
        openMenu: openFilterMenu,
        closeMenu: closeFilterMenu,
    } = useMenu();

    const { data, isPending, isError, error, isSuccess, hasNextPage, fetchNextPage } =
        useInfiniteQuery({
            queryKey: ['customers', search, sortBy, priority, channels],
            queryFn: async ({ pageParam }) => {
                const params = { page: pageParam };

                if (search) params.search = search;

                if (priority.value) params.priority = priority.value;

                if (channels.value) params.messaging_app = channels.value;

                if (sortBy.name === 'Non letti') params.has_unread_message = sortBy.value;

                if (sortBy.name === 'I più recenti') params.order = sortBy.value;

                if (sortBy.name === 'I più vecchi') params.order = sortBy.value;

                const response = await axios.get('/customers/', {
                    params,
                });
                return response.data;
            },
            refetchInterval: 5000,
            initialPageParam: 1,
            getNextPageParam: (prevData, pages) => (prevData.next ? pages.length + 1 : null),
            refetchOnWindowFocus: false,
        });

    const { entry, ref } = useIntersection({ root: lastCustomerRef.current, threshold: 1 });

    const customers = useMemo(
        () => (isSuccess ? data.pages.flatMap(page => page.results) : []),
        [data, isSuccess]
    );

    const getCustomer = useCallback(
        async customerId => {
            try {
                const response = await axios.get(`/customers/${customerId}/`);
                dispatch(setCustomer(response.data));
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, dispatch]
    );

    const filterName = useMemo(() => {
        const s = isUndefined(sortBy.name) ? '' : sortBy.name;
        const p = isUndefined(priority.name) ? '' : priority.name;
        const c = isUndefined(channels.name) ? '' : channels.name;
        return s + ' ' + p + ' ' + c;
    }, [priority, sortBy, channels]);

    const getDate = time => {
        const today = generateDate(time);
        return today === 'Today' ? DateTime.fromISO(time).toFormat('HH:mm') : today;
    };

    useEffect(() => {
        if (hasNextPage && entry?.isIntersecting) fetchNextPage();
    }, [hasNextPage, entry, fetchNextPage]);

    useEffect(() => {
        if (customerId) getCustomer(customerId);
    }, [customerId, getCustomer]);

    if (isError) {
        const code = parseInt(
            error.message.substring(error.message.length - 3, error.message.length)
        );

        if (code === 403) authorize(false);

        return <h3>{error.message}</h3>;
    }

    return (
        <Box
            height='100%'
            color='text.secondary'
            display='flex'
            flexDirection='column'
            sx={{ transition: 'all 225ms' }}>
            <Box
                p={1}
                display='flex'
                justifyContent='center'
                flexDirection='column'
                visibility={isSidePanelOpen ? 'hidden' : 'visible'}
                height={'100px'}>
                <Search
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    wrapperProps={{ sx: { mb: 2 } }}
                />
                <div>
                    <List component='nav'>
                        <ListItemButton variant='text' onClick={openFilterMenu}>
                            {filterName}
                        </ListItemButton>
                    </List>
                    <Menu
                        anchorEl={anchorElFilter}
                        open={Boolean(anchorElFilter)}
                        onClose={closeFilterMenu}
                        sx={{
                            '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                                marginTop: 2,
                                width: 'min(100%, 320px)',
                                boxShadow:
                                    'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
                                border: '1px solid',
                                borderColor: 'common.white',
                                backgroundColor: 'background.default',
                                borderRadius: '8px',
                                paddingInline: 0.2,
                                paddingBlock: 1.5,
                            },
                            '& .MuiButtonBase-root:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            },
                        }}>
                        <Typography variant='subtitle2' ml={2}>
                            Sort by
                        </Typography>
                        <Divider variant='middle' sx={{ my: 1 }} />
                        {filterOptions.sortBy.map((option, i) => (
                            <MenuItem
                                key={option.value}
                                selected={option.value === sortBy.value}
                                onClick={e => {
                                    closeFilterMenu();
                                    setSortBy(option);
                                }}>
                                {option.name}
                            </MenuItem>
                        ))}
                        <Typography variant='subtitle2' ml={2} mt={2}>
                            Priorità
                        </Typography>
                        <Divider variant='middle' sx={{ my: 1 }} />
                        {filterOptions.priority.map((option, i) => (
                            <MenuItem
                                key={option.value}
                                selected={option.value === priority.value}
                                onClick={e => {
                                    closeFilterMenu();
                                    option.value === priority.value
                                        ? setPriorità({})
                                        : setPriorità(option);
                                }}>
                                {option.name}
                            </MenuItem>
                        ))}
                        <Typography variant='subtitle2' ml={2} mt={2}>
                            Channels
                        </Typography>
                        <Divider variant='middle' sx={{ my: 1 }} />
                        {filterOptions.channels.map((option, i) => (
                            <MenuItem
                                key={option.value}
                                selected={option.value === channels.value}
                                onClick={e => {
                                    closeFilterMenu();
                                    option.value === channels.value
                                        ? setChannels({})
                                        : setChannels(option);
                                }}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            </Box>

            <Box
                sx={{
                    overflowY: 'auto',
                    height: 'calc(100% - 152px)',
                    flexGrow: 1,
                    pb: 2,
                }}>
                <List disablePadding>
                    {!isPending &&
                        customers.map((customer, i) => (
                            <NavLink
                                to={`/${customer.id}`}
                                key={i}
                                style={{ textDecoration: 'none', color: 'inherit' }}
                                ref={i === customers.length - 1 ? ref : null}>
                                {({ isActive }) => (
                                    <>
                                        <ListItem sx={{ p: 0.5 }}>
                                            <ListItemButton
                                                selected={isActive}
                                                variant={
                                                    isSidePanelOpen
                                                        ? 'sidebarIconButton'
                                                        : 'sidebarButton'
                                                }
                                                alignItems='flex-start'
                                                onClick={() => dispatch(setCustomer(customer))}>
                                                {!isSidePanelOpen && (
                                                    <Typography
                                                        variant='caption'
                                                        color='currentcolor'
                                                        fontSize={11}
                                                        position='absolute'
                                                        top='8px'
                                                        right='8px'>
                                                        {customer.last_message_time
                                                            ? getDate(customer.last_message_time)
                                                            : null}
                                                    </Typography>
                                                )}
                                                {customer?.conversation?.some(
                                                    value => value.has_unread_message
                                                ) && (
                                                    <Box
                                                        color='white'
                                                        position='absolute'
                                                        borderRadius='16px'
                                                        width='48px'
                                                        bottom='8px'
                                                        right='8px'
                                                        textAlign='center'
                                                        bgcolor='primary.main'
                                                        fontSize={14}
                                                        py={0.1}
                                                        boxShadow='rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px'
                                                        display={
                                                            isSidePanelOpen ? 'none' : 'block'
                                                        }>
                                                        {customer.conversation.reduce(
                                                            (prev, value) =>
                                                                prev + value.has_unread_message,
                                                            0
                                                        )}
                                                    </Box>
                                                )}

                                                <ListItemAvatar>
                                                    <Avatar
                                                        alt={
                                                            customer.first_name +
                                                            ' ' +
                                                            customer.last_name
                                                        }
                                                        src={customer.profile_pic}
                                                        name='person.jpeg'
                                                        sx={{ width: '45px', height: '45px' }}
                                                    />
                                                </ListItemAvatar>
                                                {!isSidePanelOpen && (
                                                    <ListItemText>
                                                        <Typography variant='caption'>
                                                            {customer.site[0]?.name}
                                                        </Typography>
                                                        <Divider
                                                            sx={{
                                                                mb: 0.8,
                                                            }}
                                                        />
                                                        <Typography
                                                            variant='body1'
                                                            fontWeight='500'
                                                            sx={{
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                            }}>
                                                            {customer_visual_name(customer)}
                                                        </Typography>

                                                        <Typography variant='body2'>
                                                            {customer.email}
                                                        </Typography>
                                                        <Typography
                                                            variant='caption'
                                                            sx={{
                                                                overflow: 'hidden',
                                                                display: '-webkit-box',
                                                                WebkitBoxOrient: 'vertical',
                                                                WebkitLineClamp: '1',
                                                                textOverflow: 'ellipsis',
                                                            }}>
                                                            {customer.last_message || 'No message'}
                                                        </Typography>
                                                    </ListItemText>
                                                )}
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider variant='fullWidth' />
                                    </>
                                )}
                            </NavLink>
                        ))}
                </List>
            </Box>
        </Box>
    );
};

export default memo(Sidebar);
