const colors = {
    Bianco: '#ffffff',
    Nero: '#000000',
    'Grigio Chiaro': '#d8d8d8',
    Giallo: '#eeee22',
    Arancio: '#ff8000',
    Rosso: '#dd3333',
    Fucsia: '#f400a1',
    Granata: '#800000',
    Verde: '#4cbb17',
    'Blu Navy': '#1d41b7',
    'Verde Chiaro': '#9edb3d',
    Azzurro: '#00e9f9',
    Blu: '#4285bf',
};

export default colors;
