import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    width: 450,
    open: false,
    active: 0,
};

export const sidePanelSlice = createSlice({
    name: 'sidepanel',
    initialState,
    reducers: {
        setSidePanelWidth: (state, action) => {
            state.width = action.payload;
        },
        toggleSidePanel: state => {
            state.open = !state.open;
        },
        setSidePanel: (state, action) => {
            state.open = action.payload;
        },
        setActivePanel: (state, action) => {
            state.active = action.payload;
        },
    },
});

export const { setSidePanelWidth, toggleSidePanel, setActivePanel, setSidePanel } =
    sidePanelSlice.actions;

export default sidePanelSlice.reducer;
