import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    caret: 0,
    ref: null,
};

export const inputSlice = createSlice({
    name: 'input',
    initialState,
    reducers: {
        setCaret: (state, action) => {
            state.caret = action.payload;
        },
        setRef: (state, action) => {
            state.ref = action.payload;
        },
    },
});

export const { setCaret, setRef } = inputSlice.actions;

export default inputSlice.reducer;
