import { FormControl, IconButton, InputBase, styled } from '@mui/material';
import React from 'react';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';

const SearchWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    padding: '0px 32px 0px 0px',
    borderRadius: '500px',
    boxShadow: theme.palette.boxShadow,
}));

const IconRight = styled('div')(({ theme }) => ({
    height: '100%',
    position: 'absolute',
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    color: theme.palette.primary.main,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        color: theme.palette.text.primary,
        fontSize: '0.875rem',
        fontWeight: '400',
        lineHeight: '1.43',
        letterSpacing: '0.01071em',
        marginInline: theme.spacing(1),
        transition: theme.transitions.create('width'),
        width: '100%',
        // '&:focus': {
        //     backgroundColor: theme.palette.custom.search.focus,
        //     boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 3px',
        //     borderRadius: '20px',
        // },
    },
}));

const Filter = props => {
    const { wrapperProps, placeholder = 'Filtra', onClick, ...rest } = props;
    return (
        <React.Fragment>
            <SearchWrapper {...wrapperProps}>
                <FormControl fullWidth>
                    <StyledInputBase sx={{ flex: 1 }} placeholder={placeholder} {...rest} />
                </FormControl>
                <IconRight>
                    <IconButton onClick={onClick} color='primary'>
                        <ExpandCircleDownOutlinedIcon fontSize='small' />
                    </IconButton>
                </IconRight>
            </SearchWrapper>
        </React.Fragment>
    );
};

export default Filter;
