import React, { useMemo } from 'react';
import MuiAvatar from '@mui/material/Avatar';

function Avatar(props) {
    const { name, cdn, src, ...rest } = props;

    const SRC = useMemo(() => {
        if (src) return src;

        if (name) return `${process.env.PUBLIC_URL}/images/${name}`;

        if (cdn) return process.env.REACT_APP_CDN_SERVER + '/images/' + cdn;

        return src;
    }, [src, cdn, name]);

    return <MuiAvatar src={SRC} {...rest} />;
}

export default Avatar;
