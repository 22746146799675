import { Button, Grid, IconButton, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import ShadowCard from '../../components/ShadowCard';
import Edit from '@mui/icons-material/Edit';
import { Form, useForm } from '../../hooks/useForm';
import { Input } from '../../hooks/useForm/inputs/index';
import CustomInput from '../../components/Input/input';
import { useMessage } from '../../layouts/Header';
import useErrorHandler from '../../hooks/useErrorHandler';
import useModal from '../../hooks/useModal';
import useToggle from '../../hooks/useToggle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSetUser, useUser } from '../../hooks/Authorize';
import ChangePassword from './ChangePassword';

const Index = () => {
    const user = useUser();
    const setUser = useSetUser();
    const [toggleState, userEditToggle] = useToggle(true);
    const { showSuccess, showError } = useMessage();
    const errorHandler = useErrorHandler();

    const { modalState, closeModal, openModal } = useModal();

    const handlers = useForm(
        useMemo(
            () => ({
                first_name: { required: true, value: user.first_name },
                last_name: { required: true, value: user.last_name },
                email: { required: true, value: user.email },
            }),
            [user]
        ),
        { Input: CustomInput }
    );

    const setValues = handlers.setValues;

    const onSubmit = res => {
        if (res.data) {
            const { first_name, last_name, email } = res.data;
            userEditToggle();
            setValues({
                first_name,
                last_name,
                email,
            });
            setUser(res.data);
            return showSuccess('User saved successfully');
        }

        showError('Unable to update the customer at the moment');
    };

    useEffect(() => {
        if (user) {
            setValues({
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
            });
        }
    }, [user, setValues]);

    return (
        <ShadowCard mt={6} mx={2}>
            <Form
                handlers={handlers}
                onSubmit={onSubmit}
                action={`/user/update_info/`}
                method='put'
                onError={errorHandler}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' mb={2}>
                    <Typography variant='subtitle1' fontWeight={500}>
                        USER ACCOUNT INFO
                    </Typography>
                    {toggleState ? (
                        <IconButton color='primary' onClick={userEditToggle}>
                            <Edit fontSize='small' />
                        </IconButton>
                    ) : null}
                    {toggleState ? null : (
                        <Stack direction='row' alignItems='center'>
                            <IconButton onClick={userEditToggle}>
                                <HighlightOffIcon />
                            </IconButton>
                            <IconButton color='primary' type='submit'>
                                <CheckCircleIcon />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Input
                            label='Nome'
                            name='first_name'
                            disabled={toggleState}
                            inset={!toggleState}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            label='Cognome'
                            name='last_name'
                            disabled={toggleState}
                            inset={!toggleState}
                        />
                    </Grid>
                </Grid>

                <Input label='Email' name='email' disabled={toggleState} inset={!toggleState} />
            </Form>
            <Button variant='contained' sx={{ mt: 1 }} onClick={openModal}>
                Change password
            </Button>

            <Modal
                open={modalState}
                onClose={closeModal}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <>
                    <ChangePassword closeModal={closeModal} />
                </>
            </Modal>
        </ShadowCard>
    );
};

export default Index;
