import {
    Box,
    Button,
    Divider,
    Grid,
    InputBase,
    Menu,
    MenuItem,
    Select,
    Stack,
    Typography,
    IconButton,
    Tooltip 
} from '@mui/material';
import { Input } from '@mui/base/Input';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid from '../../components/DataGrid';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { useMenu } from '../../hooks/useMenu';
import { conditions } from '../../data/filters';
import { useDebouncedValue } from '@mantine/hooks';
import AddIcon from '@mui/icons-material/Add';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Filter from '../../components/Filter';
import { assistance_request, assistance_request_translate } from '../../data/choices';
import { setCustomer } from '../../features/customer/customerSlice';
import useErrorHandler from '../../hooks/useErrorHandler';
import { useMessage } from '../../layouts/Header';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {customer_visual_name} from '../../utils/function';


const AssistanceList = () => {
    const customerId = useParams().id;
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });
    const [rowCount, setRowCount] = useState(0);
    const errorHandler = useErrorHandler();
    const { showSuccess } = useMessage();
    const {
        anchorEl: anchorElFilter,
        openMenu: openFilterMenu,
        closeMenu: closeFilterMenu,
    } = useMenu();
    const [filters, setFilters] = useState([{ field: '', condition: '', value: '' }]);
    const [options, setOptions] = useState({ filter_operator: 'and', filter: false });
    const [search, setSearch] = useState('');
    const searchText = useDebouncedValue(search, 400)[0];
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onFilterHandler = (e, i) => {
        const { name, value } = e.target;
        setFilters(prev => {
            const newFilters = [...prev];
            if (name === 'field') {
                newFilters[i].condition = '';
                newFilters[i].value = '';
            }
            newFilters[i][name] = value;
            return newFilters;
        });
    };

    const addFilter = () => {
        setFilters(prev => [...prev, { field: '', condition: '', value: '' }]);
    };

    const removeFilter = i => {
        if (!filters[i]) return;
        const updated = [...filters];
        updated.splice(i, 1);
        setFilters(updated);
    };

    const { data, error, isError, isLoading, refetch } = useQuery({
        queryKey: [
            'assistance-request',
            customerId,
            paginationModel.page,
            searchText,
            options.filter,
        ],
        queryFn: async (...args) => {
            console.log(args);
            const params = {
                customer: customerId,
                page: Boolean(paginationModel.page) ? paginationModel.page + 1 : 1,
            };

            if (searchText) params.filter = JSON.stringify({ search: searchText });

            if (options.filter)
                params.filter = JSON.stringify({
                    fields: filters,
                    filter_operator: options.filter_operator,
                    // ...(searchText ? { search: searchText } : {}),
                });

            const response = await axios.get('/assistance-request/', { params });
            setRowCount(response.data.count);
            return response.data;
        },
    }); // Bugfix page size

    const assistance = useMemo(() => (data ? data.results : []), [data]);

    useEffect(() => {
        setRowCount(prev => (data?.count !== undefined ? data?.count : prev));
    }, [data?.count, setRowCount]);

    const changeStatus = useCallback(
        async (id, status) => {
            try {
                await axios.patch(`/assistance-request/${id}/`, { status });

                showSuccess(`Status cambiato in  ${status}`);
                refetch();
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, refetch, showSuccess]
    );
    // TODO salvare local e fare la chiamata durante onBlur
    const changeNote = useCallback(
        async (id, note) => {
            try {
                await axios.patch(`/assistance-request/${id}/`, { note });

                // showSuccess(`Note aggiornate`);
                refetch();
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, refetch, showSuccess]
    );

    const getCustomer = useCallback(
        async customerId => {
            try {
                const response = await axios.get(`/customers/${customerId}/`);
                dispatch(setCustomer(response.data));
                navigate(`/dashboard/${customerId}`);
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, dispatch, navigate]
    );

    const onClickInput = (event) => {
        event.stopPropagation()
    }

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: 'Id',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 100,
            },
            {
                field: 'site',
                headerName: 'Site',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 350,
                renderCell: params => params.row.site.name,
            },
            {
                field: 'customer',
                headerName: 'Customer',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 250,
                renderCell: params =>
                    customer_visual_name(params.row.customer)
            },
            {
                field: 'status',
                headerName: 'Status',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <Select
                        value={params.row.status}
                        size='small'
                        fullWidth
                        onChange={e => changeStatus(params.id, e.target.value)}>
                        {assistance_request.status.map(value => (
                            <MenuItem value={value} sx={{ textTransform: 'capitalize' }}>
                                {assistance_request_translate[value]}
                            </MenuItem>
                        ))}
                    </Select>
                ),
            },
            {
                field: 'creation_time',
                headerName: 'Data Creazione',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 250,
                renderCell: params =>
                    DateTime.fromISO(params.row.creation_time).toFormat('yyyy-MM-dd'),
            },
            {
                field: 'note',
                headerName: 'Note',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 600,
                renderCell: params => (
                    <Tooltip title={params.row.note} placement="top">
                        <InputBase 
                            sx={{".MuiInputBase-input": {textAlign: 'center', width:'500px', textOverflow: 'ellipsis'}}} 
                            onClick={onClickInput} 
                            type='text' 
                            label='Note' 
                            name='note' 
                            value={params.row.note} 
                            onChange={e => changeNote(params.id, e.target.value)} />
                    </Tooltip>
                )
            },
        ],
        [changeStatus, changeNote]
    );

    if (isError) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}>
                <Typography variant='h5' sx={{ color: 'error.main' }}>
                    {error.message}
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <Box p={2} my={4}>
                <Typography variant='h5'>Lista Richieste di Assistenza</Typography>
                <Divider variant='fullWidth' sx={{ my: 2 }} />

                <Box bgcolor='#DBDBF0' p={1} mb={4} borderRadius='50px'>
                    <Grid container columnSpacing={2} alignItems='center'>
                        <Grid item xs={12} md>
                            <Typography variant='subtitle1' fontWeight={500}>
                                Visualizzati <b> {assistance.length} Richieste di Assistenza </b>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md='auto'>
                            <Filter
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                                onClick={openFilterMenu}
                                wrapperProps={{
                                    sx: {
                                        marginLeft: 'auto',
                                        maxWidth: '188px',
                                        width: '100%',
                                        border: '2px solid white',
                                        boxShadow: 'none',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <DataGrid
                    rows={assistance}
                    columns={columns}
                    autoHeight
                    onRowClick={async params => {
                        await getCustomer(params.row.customer.id);
                    }}
                    rowSelection={false}
                    disableSelectionOnClick
                    loading={isLoading}
                    rowCount={rowCount}
                    checkboxSelection={false}
                    pageSizeOptions={[40]}
                    paginationModel={paginationModel}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                />
            </Box>
            <Menu
                anchorEl={anchorElFilter}
                open={Boolean(anchorElFilter)}
                onClose={closeFilterMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                // type='glassMorphism'

                sx={{
                    marginTop: '20px',
                    '& ul.MuiList-root.MuiMenu-list': {
                        padding: 0,
                    },
                    '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                        width: 'min(100%, 1000px)',
                        boxShadow:
                            'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
                        border: '1px solid',
                        borderColor: 'white',
                        backgroundColor: 'background.default',
                        borderRadius: '8px',
                        p: 0,
                        overflowY: 'unset',
                    },
                }}>
                <Box p={1.6}>
                    {filters.map((filter, i) => (
                        <Stack direction='row' spacing={2} padding={1.4}>
                            <Select
                                displayEmpty
                                name='field'
                                value={filter.field}
                                onChange={e => onFilterHandler(e, i)}
                                fullWidth
                                renderValue={v => {
                                    if (!v) return 'Colonna';
                                    return v;
                                }}
                                sx={{ borderRadius: 1.5, border: '2px solid white' }}>
                                {columns.map(col => (
                                    <MenuItem value={col.field} key={col.field}>
                                        {col.headerName}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Select
                                displayEmpty
                                name='condition'
                                value={filter.condition}
                                onChange={e => onFilterHandler(e, i)}
                                fullWidth
                                size='small'
                                renderValue={v => {
                                    if (!v) return 'Condizione';
                                    return v;
                                }}
                                sx={{ borderRadius: 1.5, border: '2px solid white' }}>
                                {conditions.map(cond => (
                                    <MenuItem value={cond.value} key={cond.name}>
                                        {cond.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {['status'].includes(filter.field) ? (
                                <Select
                                    displayEmpty
                                    name='Valore'
                                    value={filter.value}
                                    onChange={e => onFilterHandler(e, i)}
                                    fullWidth
                                    renderValue={v => {
                                        if (!v) return 'Value';
                                        return v;
                                    }}
                                    sx={{ borderRadius: 1.5, border: '2px solid white' }}>
                                    {assistance_request[filter.field].map(value => (
                                        <MenuItem
                                            value={value}
                                            key={value}
                                            sx={{ textTransform: 'capitalize' }}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            ) : (
                                <InputBase
                                    name='Valore'
                                    placeholder='Valore'
                                    value={filter.value}
                                    size='small'
                                    onChange={e => onFilterHandler(e, i)}
                                    fullWidth
                                    sx={{ borderRadius: 1.5, border: '2px solid white', px: 1 }}
                                />
                            )}
                            <IconButton
                                disabled={i === 0}
                                onClick={() => removeFilter(i)}
                                color='text.tertiary'>
                                <DeleteTwoToneIcon />
                            </IconButton>
                        </Stack>
                    ))}
                </Box>
                <Stack
                    direction='row'
                    p={2}
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ backgroundColor: 'white' }}>
                    <Stack direction='row' spacing={1.5}>
                        <Button
                            variant={options.filter_operator === 'and' ? 'contained' : 'filter'}
                            onClick={() =>
                                setOptions(prev => ({ ...prev, filter_operator: 'and' }))
                            }
                            sx={{ borderRadius: 1.5 }}>
                            E
                        </Button>
                        <Button
                            variant={options.filter_operator === 'or' ? 'contained' : 'filter'}
                            onClick={() => setOptions(prev => ({ ...prev, filter_operator: 'or' }))}
                            sx={{ borderRadius: 1.5 }}>
                            O
                        </Button>
                        <Divider orientation='vertical' flexItem variant='middle' />
                        <Button
                            variant='text'
                            startIcon={<AddIcon />}
                            color='primary'
                            onClick={addFilter}
                            sx={{
                                '&, &:hover': {
                                    boxShadow: 'none',
                                    borderRadius: '0',
                                },
                            }}>
                            Nuovo Filtro
                        </Button>
                    </Stack>
                    <Box>
                        <Button
                            variant='filter'
                            sx={{ minWidth: 120, borderRadius: 1.5, mr: 1 }}
                            onClick={() => {
                                setOptions(prev => ({ ...prev, filter: false }));
                                setFilters([{ field: '', condition: '', value: '' }]);
                                closeFilterMenu();
                            }}>
                            Svuota
                        </Button>
                        <Button
                            variant='contained'
                            sx={{ minWidth: 120, borderRadius: 1.5 }}
                            onClick={() => {
                                const hasValues = filters.every(
                                    filter => filter.field && filter.condition
                                );
                                if (!hasValues) return;
                                setOptions(prev => ({ ...prev, filter: true }));
                                refetch();
                                closeFilterMenu();
                            }}>
                            Crea
                        </Button>
                    </Box>
                </Stack>
            </Menu>
        </>
    );
};

export default AssistanceList;
