import { Button, Card, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Form, Submit, useForm } from '../../hooks/useForm';
import { Input } from '../../hooks/useForm/inputs/index';
import CustomInput from '../../components/Input/input';
import { useMessage } from '../../layouts/Header';
import useErrorHandler from '../../hooks/useErrorHandler';

const ChangePassword = props => {
    const { closeModal } = props;
    const { showSuccess, showError } = useMessage();
    const errorHandler = useErrorHandler();

    const handlers = useForm(
        useMemo(
            () => ({
                old_password: { required: true },
                password: {
                    required: true,
                    validator: v =>
                        v.length >= 8
                            ? ''
                            : 'This password is too short. It must contain at least 8 characters.',
                },
                password2: { required: true },
            }),
            []
        ),
        { Input: CustomInput }
    );

    const onSubmit = res => {
        if (res.data) {
            closeModal();
            return showSuccess('Password changed successfully');
        }

        closeModal();
        showError('Unable to update the password at the moment');
    };

    return (
        <Card
            sx={{
                p: 2.5,
                bgcolor: 'background.default',
                borderRadius: 2,
                maxWidth: '895px',
                width: '100%',
            }}>
            <Form
                handlers={handlers}
                onSubmit={onSubmit}
                action={'/user/change_password/'}
                method={'put'}
                onError={errorHandler}>
                <Typography variant='subtitle1' fontWeight={500} mb={2}>
                    CAMBIA PASSWORD
                </Typography>

                <Input label='Vecchia password' name='old_password' type='password' />
                <Input label='Nuova Password' name='password' type='password' />
                <Input label='Conferma password' name='password2' type='password' />
                <Typography variant='body1' fontSize={12} ml={2}>
                    The password must contain at least 8 characters, 1 special character: @#$%!^&*
                    and 1 uppercase letter, A-Z.
                </Typography>

                <Stack direction='row' gap={2} justifyContent='flex-end' mt={2}>
                    <Button variant='text' onClick={closeModal}>
                        Cancella
                    </Button>
                    <Submit>
                        {loader => (
                            <Button
                                variant='contained'
                                type='submit'
                                disabled={Boolean(loader)}
                                endIcon={loader}>
                                Cambia
                            </Button>
                        )}
                    </Submit>
                </Stack>
            </Form>
        </Card>
    );
};

export default ChangePassword;
