import {
    Autocomplete,
    Button,
    Card,
    Chip,
    Divider,
    FormHelperText,
    Grid,
    IconButton,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    styled,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Form, Submit, useForm } from '../hooks/useForm';
import { Input } from '../hooks/useForm/inputs/index';
import CustomInput from './Input/input';
import { useMessage } from '../layouts/Header';
import useErrorHandler from '../hooks/useErrorHandler';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import DeleteOutline from '@mui/icons-material/DeleteOutline';

const StyledTextField = styled(TextField)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
        color: theme.palette.text.primary,
        fontSize: '0.875rem',
        fontWeight: '400',
        letterSpacing: '0.01071em',
        marginInline: theme.spacing(1),
        transition: theme.transitions.create('width'),
        marginBottom: 0,
        padding: 0,
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input.MuiOutlinedInput-input': {
        paddingBlock: 7.2,
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        all: 'unset',
    },
}));

const AddProduct = ({ closeModal }) => {
    const { showSuccess, showError } = useMessage();
    const errorHandler = useErrorHandler();
    const [attributes, setAttributes] = useState([]);

    const addAttribute = () => {
        setAttributes([...attributes, { name: '', options: [] }]);
    };

    const deleteAttribute = index => {
        const attr = [...attributes];
        attr.splice(index, 1);
        setAttributes(attr);
    };

    const handlers = useForm(
        useMemo(
            () => ({
                name: { required: true },
                site: { required: true },
                temporary: { value: true },
            }),
            []
        ),
        { Input: CustomInput }
    );

    const setValues = handlers.setValues;
    const errors = handlers.errors;
    const values = handlers.values;

    const customChangeHandler = e => {
        const { name, value } = e.target;
        setValues({ [name]: value });
    };

    const attributeNameHandler = (e, i) => {
        const { name, value } = e.target;

        setAttributes(prev => {
            const newAttributes = [...prev];
            newAttributes[i][name] = value;
            return newAttributes;
        });
    };

    const onSubmit = res => {
        closeModal();

        if (res.data) {
            return showSuccess('New product created');
        }

        showError('Unable to create the Product at the moment');
    };

    const { data } = useQuery({
        queryKey: ['sites'],
        queryFn: async () => {
            const { data } = await axios.get(`/sites/`);
            return data;
        },
    });

    const sites = useMemo(() => (data ? data.results : []), [data]);

    return (
        <Card
            sx={{
                p: 2.5,
                bgcolor: 'background.default',
                borderRadius: 2,
                maxWidth: '1095px',
                width: '100%',
            }}>
            <Form
                handlers={handlers}
                onSubmit={onSubmit}
                action={`/products/`}
                method='post'
                final={v => ({ ...v, attributes })}
                onError={errorHandler}>
                <Typography variant='subtitle1' fontWeight={500} mb={2}>
                    AGGIUNGI PRODOTTO
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={10}>
                        <Input label='Nome Prodotto' name='name' sx={{ mb: 0 }} />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button
                            variant='contained'
                            fullWidth
                            sx={{ minHeight: 36 }}
                            onClick={addAttribute}>
                            Aggiungi Attributo
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            fullWidth
                            displayEmpty
                            value={values.site}
                            name='site'
                            onChange={customChangeHandler}
                            isError={Boolean(errors.site)}
                            error={errors.site}
                            sx={{ py: 1 }}>
                            <MenuItem disabled={true} value=''>
                                Sito
                            </MenuItem>
                            {sites?.map(site => (
                                <MenuItem key={site} value={site.id}>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 14,
                                            color: 'text.secondary',
                                        }}>
                                        {site.name}
                                    </ListItemText>
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error={errors.site} sx={{ ml: 2 }}>
                            {errors.site}
                        </FormHelperText>
                    </Grid>
                </Grid>
                {attributes.length ? <Divider sx={{ my: 1 }}>ATTRIBUTI</Divider> : null}
                {attributes.map((attribute, i) => (
                    <Grid container spacing={2} key={i}>
                        <Grid item xs={12} sm={3}>
                            <CustomInput
                                label='Name'
                                name='name'
                                value={attribute.name}
                                onChange={e => attributeNameHandler(e, i)}
                            />
                        </Grid>
                        <Grid item xs={12} sm>
                            <Autocomplete
                                clearIcon={false}
                                options={[]}
                                freeSolo
                                multiple
                                onChange={(e, newValue) => {
                                    if (e.key === 'Enter')
                                        setAttributes(prev => {
                                            const newOptions = [...prev];
                                            newOptions[i].options = newValue;
                                            return newOptions;
                                        });

                                    if (e.key === 'Backspace')
                                        setAttributes(prev => {
                                            const newOptions = [...prev];
                                            newOptions[i].options = newValue;
                                            return newOptions;
                                        });
                                }}
                                renderTags={value => {
                                    return value.map((option, index) => (
                                        <Chip
                                            label={option}
                                            key={option}
                                            size='small'
                                            variant='outlined'
                                            color='primary'
                                            sx={{
                                                mx: 0.5,
                                                height: 25,
                                                fontSize: '0.875rem',
                                                lineHeight: '1.43',
                                                letterSpacing: '0.01071em',
                                            }}
                                        />
                                    ));
                                }}
                                renderInput={params => (
                                    <Stack
                                        direction='row'
                                        alignContent='center'
                                        sx={{
                                            width: '100%',
                                            maxWidth: '100%',
                                            px: 0.8,
                                            alignItems: 'center',
                                            borderRadius: '500px',
                                            backgroundColor: 'background.default',
                                            boxShadow:
                                                '5px 5px 5px #d2d4d685, -5px -5px 5px #ffffff82',
                                            border: '1px solid white',
                                        }}>
                                        <Typography
                                            variant='body2'
                                            fontWeight={400}
                                            color='text.primary'
                                            pt={0.2}
                                            pl={0.5}>
                                            Options:
                                        </Typography>

                                        <StyledTextField name='options' {...params} />
                                    </Stack>
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => deleteAttribute(i)}>
                                <DeleteOutline />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}

                <Stack direction='row' gap={2} justifyContent='flex-end' mt={2}>
                    <Button variant='text' onClick={closeModal}>
                            Annulla
                    </Button>
                    <Submit>
                        {loader => (
                            <Button
                                variant='contained'
                                type='submit'
                                disabled={
                                    Boolean(loader) ||
                                    !attributes.every(
                                        attribute => attribute.name && attribute.options.length > 0
                                    )
                                }
                                endIcon={loader}>
                                Crea
                            </Button>
                        )}
                    </Submit>
                </Stack>
            </Form>
        </Card>
    );
};

export default AddProduct;
