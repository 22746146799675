import { Button, FormHelperText, InputBase, Stack, Typography, styled } from '@mui/material';
import { Form, useForm } from '../hooks/useForm';
import { Input } from '../hooks/useForm/inputs/index';
import React, { useMemo, useRef, useState } from 'react';
import useErrorHandler from '../hooks/useErrorHandler';
import { useMessage } from './Header';
import { useUser } from '../hooks/Authorize';
import { getBase64 } from '../utils/function';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    borderRadius: '10px',
    maxWidth: '100%',
    width: '100%',
    fontSize: 12,
    padding: theme.spacing(0.2, 1),
    transition: theme.transitions.create('width'),
    backgroundColor: '#e3e0eb',
    outline: 'none',
    border: 'none',
    'input::placeholder': {
        fontSize: 12,
        color: 'black',
        fontWeight: 600,
        lineHeight: '1.43',
        letterSpacing: '0.01071em',
    },
}));

const BugReport = ({ closeMenu }) => {
    const errorHandler = useErrorHandler();
    const { showError, showSuccess } = useMessage();
    const user = useUser();
    const fileRef = useRef(null);
    const [file, setFile] = useState(null);
    const [base64File, setBase64File] = useState('');

    const handlers = useForm(
        useMemo(
            () => ({
                site: { required: true },
                description: { required: true },
                user: { value: user.id },
            }),
            [user.id]
        ),
        { Input: StyledInputBase }
    );

    const errors = handlers.errors;

    const onSubmit = res => {
        if (res.data) {
            closeMenu();
            return showSuccess('Bug report submitted successfully');
        }

        showError('Unable to report the bug at the moment');
    };

    const fileHandler = async e => {
        e.stopPropagation();

        const f = e.target.files[0];
        if (!f) return showError('No file selected');
        setFile(f);
        const base64 = await getBase64(f);
        setBase64File(base64);
    };

    return (
        <Form
            onSubmit={onSubmit}
            handlers={handlers}
            action='/error-report/'
            method='post'
            final={values => ({ ...values, files: [{ file: base64File }] })}
            onError={errorHandler}>
            <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
                <Typography
                    variant='subtitle1'
                    fontWeight={500}
                    color='text.secondary'
                    gutterBottom>
                    REPORT BUG
                </Typography>
                {file ? (
                    <Typography
                        variant='caption'
                        color='text.secondary'
                        gutterBottom
                        sx={{ textOverflow: 'ellipsis', overflow: 'hidden', textWrap: 'nowrap' }}>
                        {file.name}
                    </Typography>
                ) : null}
            </Stack>
            <Input placeholder='Sito' name='site' sx={{ mb: 1 }} />
            {errors.site ? (
                <FormHelperText error={Boolean(errors.site)} sx={{ mr: 2 }}>
                    {errors.site}
                </FormHelperText>
            ) : null}

            <Input placeholder='Descrizione' name='description' multiline maxRows={5} minRows={5} />
            {errors.description ? (
                <FormHelperText error={Boolean(errors.description)} sx={{ mr: 2 }}>
                    {errors.description}
                </FormHelperText>
            ) : null}

            <Stack direction='row' mt={1} spacing={2}>
                <Button variant='outlined' fullWidth onClick={e => fileRef.current.click()}>
                    Carica Immagine
                </Button>
                <Button variant='outlined' type='submit' fullWidth>
                    Invia
                </Button>
            </Stack>

            <input type='file' ref={fileRef} style={{ display: 'none' }} onChange={fileHandler} />
        </Form>
    );
};

export default BugReport;
