import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    customer: {},
    conversation: {},
    fetchNextPage: () => {},
};

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setCustomer: (state, action) => {
            state.customer = action.payload;
        },
        setConversation: (state, action) => {
            state.conversation = action.payload;
        },
        setFetchNextPage: (state, action) => {
            state.fetchNextPage = action.payload;
        },
    },
});

export const { setCustomer, setConversation, setFetchNextPage } = customerSlice.actions;

export default customerSlice.reducer;
