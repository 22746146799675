import React, { memo, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

//mui component
import { AppBar, Box, Stack, IconButton, Grid, Toolbar, Typography, Badge } from '@mui/material';

//mui icons

import { LeftArrowIcon } from '../../components/ArrowIcon';
//react component

//services
import Sidebar from './Sidebar';
import { Platforms } from '../../data/sidebar';
import Image from '../../components/Image';
import { setConversation } from '../../features/customer/customerSlice';
import { toggleSidePanel } from '../../features/sidepanel/sidepanelSlice';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, setSessionData } from '../../utils/function';

function Navbar() {
    const [drawerWidth, setDrawerWidth] = useState(320);
    const [socialIcons, setSocialIcons] = useState([]);
    const dispatch = useDispatch();
    const sidePanelWidth = useSelector(state => state.sidepanel.width);
    const customer = useSelector(state => state.customer.customer);
    const conversation = useSelector(state => state.customer.conversation);
    const sidePanel = useSelector(state => state.sidepanel.open);

    useEffect(() => {
        if (sidePanelWidth > 450) {
            setDrawerWidth(80);
        } else {
            setDrawerWidth(320);
        }
    }, [sidePanelWidth]);

    useEffect(() => {
        if (!customer?.conversation || !customer.conversation.length) {
            dispatch(setConversation({}));
            return;
        }
        const enableIcons = [];
        const disableIcons = [];

        const lastConv = customer.conversation.reduce((prev, current) => (!current.last_message || (prev && prev.last_message?.creation_time > current.last_message?.creation_time)) ? prev : current)

        Platforms.forEach(platform => {
            const conversationIcons = customer.conversation.find(
                c => c.messaging_app === platform.name //&& Boolean(c.id)
            );

            conversationIcons
                ? enableIcons.push({ ...conversationIcons, ...platform, enable: true })
                : disableIcons.push({ ...platform, enable: false });

            console.log(customer.conversation)
            if (lastConv.messaging_app === platform.name)
                dispatch(
                    setConversation({ ...lastConv, ...platform, enable: true })
                );
        });

        setSocialIcons([...enableIcons, ...disableIcons]);
    }, [customer, dispatch]);

    return (
        <Box height='100%' display='flex' flexDirection='column'>
            <AppBar
                elevation={0}
                component={Box}
                position='sticky'
                open={sidePanel}
                sx={{
                    ml: sidePanelWidth > 450 ? 0 : `${drawerWidth}px`,
                    width: sidePanelWidth > 450 ? '100%' : `calc(100% - ${drawerWidth}px)`,
                    backgroundColor: 'background.default',
                    borderBottom: '1px solid custom.border',
                    color: 'text.primary',
                    transition: 'width 225ms, background-color 0s',
                }}>
                <Toolbar
                    sx={{
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        position: 'relative',
                        '&': {
                            minHeight: '100px',
                            px: 1,
                        },
                    }}>
                    <Grid container alignItems='center'>
                        <Grid item xs>
                            <Typography variant='subtitle1' fontWeight={500} pl={1}>
                                Piattaforme
                            </Typography>
                            <Stack
                                direction='row'
                                alignItems='center'
                                gap={0.5}
                                mb={1}
                                overflow='auto'>
                                {socialIcons.length
                                    ? socialIcons.map((platform, i) => (
                                          <IconButton
                                              key={i}
                                              disabled={!platform.enable}
                                              onClick={() => {
                                                  setSessionData('convId', conversation.id);
                                                  dispatch(setConversation(platform));
                                              }}>
                                              <Badge
                                                  color='primary'
                                                  overlap='circular'
                                                  anchorOrigin={{
                                                      vertical: 'bottom',
                                                      horizontal: 'right',
                                                  }}
                                                  {...(platform.has_unread_message
                                                      ? {
                                                            badgeContent:
                                                                platform.has_unread_message,
                                                        }
                                                      : {})}>
                                                  <Image
                                                      name={'social/' + platform.icon}
                                                      sx={{
                                                          width: '35px',
                                                          height: '35px',
                                                          ...{
                                                              filter: platform.enable
                                                                  ? conversation?.name ===
                                                                    platform.name
                                                                      ? 'none'
                                                                      : 'saturate(0.5) hue-rotate(24deg)'
                                                                  : 'grayscale(1)',
                                                          },
                                                      }}
                                                  />
                                              </Badge>
                                          </IconButton>
                                      ))
                                    : Platforms.map(platform => (
                                          <IconButton key={platform.name}>
                                              <Image
                                                  name={'social/' + platform.icon}
                                                  sx={{
                                                      width: '35px',
                                                      height: '35px',

                                                      filter: 'grayscale(1)',
                                                  }}
                                              />
                                          </IconButton>
                                      ))}
                            </Stack>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <Box
                sx={{
                    position: 'fixed',
                    top: 64,
                    left: 85,
                    bottom: 0,
                    width: drawerWidth,
                    backgroundColor: 'background.default',
                }}>
                <Sidebar isSidePanelOpen={sidePanelWidth > 450} />
            </Box>

            {isEmpty(customer) ? null : (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '30%',
                        right: '-25px',
                        width: '85px',
                        height: '140px',
                        padding: 1,
                        backgroundColor: 'background.default',
                        borderTopLeftRadius: '68px',
                        borderBottomLeftRadius: '68px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderLeft: '1px solid',
                        borderColor: 'white',
                        zIndex: 100,
                        display: { xs: 'none', xm: 'inline-flex' },
                    }}>
                    <IconButton onClick={() => dispatch(toggleSidePanel())}>
                        <LeftArrowIcon />
                    </IconButton>
                </Box>
            )}
            <Box
                component='main'
                sx={{
                    ml: `${drawerWidth}px`,
                    width: `calc(100% - ${drawerWidth}px)`,
                    height: 'calc(100% - 100px)',
                    background: isEmpty(conversation)
                        ? 'background.default'
                        : `url(${process.env.PUBLIC_URL}/images/social/${conversation.background})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    overflow: 'auto',
                    transition: 'width 225ms, margin 225ms',
                }}>
                <Outlet />
            </Box>
        </Box>
    );
}

export default memo(Navbar);
