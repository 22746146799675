import { createTheme } from '@mui/material';
import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';

const ThemeContextProvider = props => {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#FF553E',
            },
            background: {
                paper: '#FFFFFF',
                default: '#F0F0F8',
            },
            text: {
                primary: '#2F2E3E',
                secondary: '#7B7AA2',
                tertiary: '#808282',
                quaternary: '#949697',
            },
            divider: '#7b7aa269',
            boxShadow: '5px 5px 5px #d2d4d685,-5px -5px 5px #ffffff82',
        },

        breakpoints: {
            keys: ['xs', 'sm', 'md', 'xm', 'lg', 'xl', 'xxl'],
            values: {
                xs: 0,
                sm: 576,
                md: 768,
                xm: 1024,
                lg: 1280,
                xl: 1516,
                xxl: 1756,
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: theme => ({
                    body: {
                        'input::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button': {
                            WebkitAppearance: 'none',
                            margin: '0',
                        },

                        '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                            backgroundColor: 'transparent',
                            width: '6px',
                            height: '6px',
                        },
                        '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                            borderRadius: 8,
                            backgroundColor: theme.palette.divider,
                            // backgroundColor: 'red',
                        },
                        '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
                            backgroundColor: 'inherit',
                        },
                        '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
                            backgroundColor: '#747775',
                        },

                        '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
                            backgroundColor: '#747775',
                        },
                        '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#747775',
                        },
                    },
                }),
            },

            MuiDivider: {
                styleOverrides: {
                    light: {
                        borderColor: '#424242',
                        width: '100%',
                    },
                },
            },

            MuiAccordion: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        backgroundImage: 'none',
                        boxShadow: '8px 8px 8px #d2d4d685, -8px -8px 8px #ffffff82',
                        backgroundColor: theme.palette.background.default,
                        padding: 16,

                        '&.Mui-expanded': {
                            margin: 0,
                        },
                    }),
                },
            },
            MuiAccordionDetails: {
                styleOverrides: {
                    root: {
                        paddingInline: 0,
                    },
                },
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        minHeight: 0,
                        padding: 0,
                        fontSize: '13px',
                        fontWeight: 600,
                        color: theme.palette.text.secondary,
                        '& .MuiAccordionSummary-content': {
                            margin: 0,
                        },

                        '&.Mui-expanded.MuiButtonBase-root': {
                            minHeight: 0,
                        },
                        '&.Mui-focusVisible.MuiButtonBase-root': {
                            outline: 'none',
                            backgroundColor: 'transparent',
                        },
                        '&.MuiAccordion-root:before': {
                            backgroundColor: 'transparent',
                        },
                    }),
                    content: {
                        margin: '8px 0px',
                        '&.Mui-expanded': {
                            margin: '8px 0px',
                        },
                    },
                },
            },

            MuiListItemButton: {
                variants: [
                    {
                        props: { variant: 'sidebarButton' },
                        style: ({ theme }) => ({
                            padding: '1px 8px',
                            paddingRight: '68px',
                            cursor: 'pointer',
                            color: theme.palette.text.secondary,
                            position: 'relative',

                            '&:hover': {
                                backgroundColor: 'transparent',
                            },

                            '&.Mui-selected': {
                                backgroundColor: theme.palette.primary.main,
                                color: 'white',

                                '&:hover': {
                                    backgroundColor: theme.palette.primary.main,
                                },

                                '.MuiListItemIcon-root': {
                                    color: theme.palette.primary.main,
                                },
                                '.MuiListItemText-root': {
                                    color: 'white',
                                },
                                '.MuiDivider-root': {
                                    borderColor: 'white',
                                },
                            },
                            '.MuiListItemText-root': {
                                marginTop: '1px',
                            },
                        }),
                    },
                    {
                        props: { variant: 'sidebarIconButton' },
                        style: ({ theme }) => ({
                            justifyContent: 'center',
                            minHeight: '95px',
                            cursor: 'pointer',
                            color: theme.palette.text.secondary,
                            margin: 0,
                            padding: 0,

                            '&:hover': {
                                backgroundColor: 'transparent',
                            },

                            '& .MuiListItemAvatar-root': { minWidth: 0 },

                            '&.Mui-selected': {
                                backgroundColor: theme.palette.primary.main,
                                color: 'white',

                                '&:hover': {
                                    backgroundColor: theme.palette.primary.main,
                                },

                                '.MuiListItemIcon-root': {
                                    color: theme.palette.primary.main,
                                },
                            },
                            '.MuiListItemText-root': {
                                marginTop: '1px',
                            },
                        }),
                    },
                    {
                        props: { variant: 'text' },
                        style: ({ theme }) => ({
                            color: theme.palette.text.primary,
                            fontSize: '0.875rem',
                            fontWeight: '400',
                            lineHeight: '1.43',
                            letterSpacing: '0.01071em',
                            padding: '4px 32px',
                            justifyContent: 'center',
                            borderRadius: '500px',
                            boxShadow: theme.palette.boxShadow,
                            '&:hover': {
                                boxShadow: theme.palette.boxShadow,
                                backgroundColor: 'transparent',
                            },
                            '& .MuiButton-startIcon>*:nth-of-type(1)': {
                                fontSize: '16px',
                                color: theme.palette.primary.main,
                            },
                        }),
                    },
                ],
            },

            MuiListItemText: {
                styleOverrides: {
                    primary: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    },
                },
            },

            MuiButton: {
                variants: [
                    {
                        props: { variant: 'contained', color: 'primary' },
                        style: ({ theme }) => ({
                            fontSize: '0.875rem',
                            fontWeight: '400',
                            lineHeight: '1.43',
                            letterSpacing: '0.01071em',
                            padding: '4px 16px',
                            textAlign: 'center',
                            borderRadius: '500px',
                            boxShadow: theme.palette.boxShadow,
                            color: theme.palette.common.white,
                            '&:hover': {
                                boxShadow: theme.palette.boxShadow,
                                backgroundColor: theme.palette.primary.main,
                            },
                            '& .MuiButton-startIcon>*:nth-of-type(1)': {
                                fontSize: '16px',
                                color: theme.palette.common.white,
                            },

                            '& .MuiButton-endIcon>*:nth-of-type(1)': {
                                fontSize: '16px',
                                color: theme.palette.common.white,
                            },
                        }),
                    },
                    {
                        props: { variant: 'outlined' },
                        style: ({ theme }) => ({
                            fontSize: '0.875rem',
                            fontWeight: '400',
                            lineHeight: '1.43',
                            letterSpacing: '0.01071em',
                            padding: '4px 16px',
                            borderRadius: '500px',
                            boxShadow: theme.palette.boxShadow,
                            // '& .MuiButton-startIcon>*:nth-of-type(1)': {
                            //     fontSize: '16px',
                            //     color: theme.palette.common.white,
                            // },

                            // '& .MuiButton-endIcon>*:nth-of-type(1)': {
                            //     fontSize: '16px',
                            //     color: theme.palette.common.white,
                            // },
                        }),
                    },
                    {
                        props: { variant: 'text' },
                        style: ({ theme }) => ({
                            color: theme.palette.text.primary,
                            fontSize: '0.875rem',
                            fontWeight: '400',
                            lineHeight: '1.43',
                            letterSpacing: '0.01071em',
                            padding: '4px 16px',
                            textAlign: 'center',
                            borderRadius: '500px',
                            boxShadow: theme.palette.boxShadow,
                            '&:hover': {
                                boxShadow: theme.palette.boxShadow,
                                backgroundColor: 'transparent',
                            },
                        }),
                    },
                    {
                        props: { variant: 'filter' },
                        style: ({ theme }) => ({
                            color: theme.palette.text.primary,
                            fontSize: '0.875rem',
                            fontWeight: '400',
                            lineHeight: '1.43',
                            letterSpacing: '0.01071em',
                            padding: '4px 16px',
                            textAlign: 'center',
                            borderRadius: theme.spacing(1.5),
                            backgroundColor: theme.palette.background.default,
                            border: '1px solid white',
                            boxShadow: theme.palette.boxShadow,
                            '&:hover': {
                                boxShadow: theme.palette.boxShadow,
                                backgroundColor: theme.palette.background.default,
                            },
                        }),
                    },
                ],
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        marginBottom: '16px',
                        borderRadius: '500px',

                        '& .MuiInputBase-input.MuiOutlinedInput-input': {
                            paddingBlock: '10px',
                        },

                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderRadius: '500px',
                        },
                    },
                },
            },
            MuiMenu: {
                variants: [
                    {
                        props: { type: 'glassMorphism' },
                        style: ({ theme }) => ({
                            '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                                width: 'min(100%, 200px)',
                                boxShadow:
                                    'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
                                border: '1px solid',
                                borderColor: theme.palette.common.white,
                                backdropFilter: 'blur(6px)',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                borderRadius: '8px',
                                paddingInline: theme.spacing(0.2),
                                paddingBlock: theme.spacing(1),
                                overflowY: 'unset',
                            },
                            '& .MuiButtonBase-root:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            },
                        }),
                    },
                ],
                styleOverrides: {
                    root: {
                        // '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                        //     minWidth: '180px',
                        // },
                        '.MuiMenu-list': {
                            padding: '5px',
                        },
                        '.MuiButtonBase-root.MuiMenuItem-root': {
                            fontSize: '14px',
                            '& .MuiListItemIcon-root': {
                                minWidth: '30px',
                            },
                            '&.Mui-selected': {
                                backgroundColor: '  white',
                            },
                        },
                    },
                },
            },

            MuiChip: {
                styleOverrides: {
                    root: {
                        height: 28,
                    },
                },
            },

            MuiTab: {
                styleOverrides: {
                    root: {
                        textTransform: 'capitalize',
                    },
                },
            },

            MuiSelect: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'background.default',
                        borderRadius: '500px',
                        padding: '4px 10px',
                        boxShadow: '5px 5px 5px #d2d4d685,-5px -5px 5px #ffffff82',
                        '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
                            padding: 0,
                        },
                        'fieldset.MuiOutlinedInput-notchedOutline': { all: 'unset' },
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {props.children}
        </ThemeProvider>
    );
};

export default ThemeContextProvider;
