import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { DateTime } from 'luxon';
import React, { forwardRef } from 'react';
import Image from './../../../../components/Image';
import { IconButton, Stack, Typography, Box } from '@mui/material';
import { escapeDanger, isImageUrl } from '../../../../utils/function';
import StarIcon from '@mui/icons-material/Star';
import { useDispatch } from 'react-redux';
import { setRedirect } from '../../../../features/messages/messageSlice';

const Message = forwardRef((props, ref) => {
    const { message, sx, ...rest } = props;
    const { content, creation_time, sender, status, attachment } = message;
    const dispatch = useDispatch();

    return (
        <Box
            ref={ref}
            onClick={() => dispatch(setRedirect({ id: message.id, page: message.original_page }))}
            {...rest}
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '8px',
                maxWidth: { xs: '85%' },
                cursor: 'pointer',
                boxShadow:
                    'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                padding: '8px',
                '&:hover #action': {
                    display: 'block',
                },
                backgroundColor: sender === 'assistance' ? 'primary.main' : 'background.paper',
                color: sender === 'assistance' ? 'white' : 'text.primary',
                alignSelf: sender === 'assistance' ? 'flex-end' : 'flex-start',
                mb: message.reaction ? 2 : 1,
                ...(sender === 'assistance'
                    ? {
                          '&:after': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              right: '-17px',
                              border: '20px solid transparent',
                              borderTopColor: 'primary.main',
                              borderRadius: '8px',
                          },
                      }
                    : {
                          '&:before': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              left: '-17px',
                              border: '20px solid transparent',
                              borderTopColor: 'background.paper',
                              borderRadius: '8px',
                          },
                      }),
            }}
            elevation={0}>
            {attachment.length
                ? attachment.map((file, i) => (
                      <Box
                          key={i}
                          display='flex'
                          flexDirection='column'
                          alignItems='center'
                          justifyContent='center'
                          mb={2}>
                          {isImageUrl(file.file) ? (
                              <Image src={file.file} />
                          ) : (
                              <Stack
                                  direction='row'
                                  alignItems='center'
                                  p={0.5}
                                  minWidth='200px'
                                  width='100%'
                                  spacing={1}>
                                  <Image name='general.png' sx={{ height: '25px' }} />
                                  <Typography
                                      variant='body2'
                                      textOverflow='ellipsis'
                                      overflow='hidden'
                                      flexGrow={1}
                                      sx={{ textWrap: 'nowrap', mt: 1 }}>
                                      File name
                                  </Typography>
                                  <IconButton sx={{ color: 'currentcolor' }}>
                                      <DownloadOutlinedIcon fontSize='small' />
                                  </IconButton>
                              </Stack>
                          )}
                      </Box>
                  ))
                : null}
            {message.reply && (
                <Stack
                    direction='row'
                    spacing={2}
                    onClick={() =>
                        dispatch(setRedirect({ id: message.reply.id, page: message.original_page }))
                    }
                    sx={{
                        minHeight: 50,
                        width: '100%',
                        bgcolor: 'rgba(0, 0, 0, 0.12)',
                        borderLeft: '3px solid',
                        borderColor: 'primary.main',
                        cursor: 'pointer',
                        overflow: 'hidden',
                    }}>
                    <Box flexGrow={1} p={1}>
                        {/* <Typography variant='subtitle2' fontWeight={500} color='primary.main'>
                        {customer.first_name + ' ' + customer.last_name}
                    </Typography> */}
                        <Typography
                            variant='body2'
                            sx={{
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: '3',
                            }}>
                            {message.reply.content}
                        </Typography>
                    </Box>
                </Stack>
            )}

            <Typography
                variant='body1'
                pr={{ xs: 2, sm: 8 }}
                dangerouslySetInnerHTML={escapeDanger(content)}
            />
            <Box position='absolute' display='flex' bottom={0} right='3px'>
                {message.important && (
                    <StarIcon sx={{ fontSize: 14, height: 'auto', mb: 0.4, mr: 0.3 }} />
                )}
                <Typography
                    variant='caption'
                    fontSize={10}
                    lineHeight={2.1}
                    right={sender === 'assistance' ? '20px' : '8px'}>
                    {creation_time ? DateTime.fromISO(creation_time).toFormat('HH:mm') : null}{' '}
                </Typography>
                <Box display={sender === 'assistance' ? 'block' : 'none'}>
                    {status === 'sent' ? (
                        <DoneIcon sx={{ fontSize: 14 }} />
                    ) : status === 'delivered' ? (
                        <DoneAllIcon sx={{ fontSize: 15 }} />
                    ) : (
                        <DoneAllIcon sx={{ fontSize: 15, color: '#5e5eea' }} />
                    )}
                </Box>
            </Box>
            {message.reaction && (
                <Box
                    position='absolute'
                    bottom={-15}
                    sx={{
                        bgcolor: sender === 'assistance' ? '#b82613' : 'white',
                        ...(sender === 'assistance' ? { right: 8 } : { left: 8 }),
                        p: 0.2,
                        borderRadius: '500px',
                        cursor: 'pointer',
                        zIndex: 20,
                    }}>
                    {message.reaction}
                </Box>
            )}
        </Box>
    );
});

export default Message;
