import React, { useEffect, useMemo, useRef } from 'react';
// import StarIcon from '@mui/icons-material/Star';
import { Box, Container, Stack } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useIntersection } from '@mantine/hooks';
import Message from './Message';

const Index = () => {
    const conversation = useSelector(state => state.customer.conversation);
    const containerRef = useRef(null);

    const { entry, ref } = useIntersection({ root: containerRef.current, threshold: 1 });

    const { data, isError, error, hasNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: ['chat-important', conversation.id],
        queryFn: async ({ queryKey, pageParam }) => {
            const response = await axios.get('/messages/', {
                params: { conversation__id: queryKey[1], page: pageParam, important: true },
            });
            return response.data;
        },
        initialPageParam: 1,
        getNextPageParam: (prevData, pages) => {
            return prevData.next ? pages.length + 1 : null;
        },
        refetchOnWindowFocus: false,
    });

    const messages = useMemo(
        () => (data ? data.pages.flatMap(page => page.results).toReversed() : []),
        [data]
    );

    useEffect(() => {
        if (hasNextPage && entry?.isIntersecting) fetchNextPage();
    }, [hasNextPage, entry, fetchNextPage]);

    if (isError) {
        return <h3>{error.message}</h3>;
    }

    return (
        <Box
            p={2}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '16px 16px 16px #d2d4d685, -16px -16px 16px #ffffff82',
                overflowY: 'auto',
                overflowX: 'hidden',
                borderRadius: '0px 8px 8px 8px',
            }}>
            {/* <Stack direction='row' alignItems='flex-end' spacing={1} my={1}>
                <StarIcon fontSize='small' color='primary' />
                <Typography variant='body2' color='primary.main' lineHeight={1.15}>
                    Important messages
                </Typography>
            </Stack> */}
            <Stack direction='row' flexDirection='column' flexGrow={1}>
                <Container maxWidth='lg' sx={{ pt: 2, height: '100%' }} ref={containerRef}>
                    <Box display='flex' flexDirection='column'>
                        {messages.map((message, i) => (
                            <Message
                                ref={i === messages.length - 1 ? ref : null}
                                message={message}
                                key={i}
                            />
                        ))}
                    </Box>
                </Container>
            </Stack>
        </Box>
    );
};

export default Index;
