import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBeforeRounded';
import { Box } from '@mui/material';

const RightArrowIcon = props => {
    const { fontSize, innerPadding, borderWidth, color, sx } = props;
    return (
        <NavigateNextIcon
            sx={{
                fontSize: fontSize || '32px',
                borderRadius: '50%',
                padding: innerPadding || '2px',
                borderWidth: borderWidth || '2px',
                borderStyle: 'solid',
                borderColor: color || 'inherit' || 'primary.main',
                color: color || 'primary.main',
                ...sx,
            }}
        />
    );
};

const LeftArrowIcon = props => {
    const { fontSize, innerPadding, borderWidth, color, sx } = props;
    return (
        <Box>
            <NavigateBeforeIcon
                sx={{
                    fontSize: fontSize || '32px',
                    borderRadius: '50%',
                    padding: innerPadding || '2px',
                    borderWidth: borderWidth || '2px',
                    borderStyle: 'solid',
                    borderColor: color || 'primary.main',
                    color: color || 'primary.main',
                    ...sx,
                }}
            />
        </Box>
    );
};

export { RightArrowIcon, LeftArrowIcon };
