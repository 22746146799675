import React, { useEffect, useMemo, useState } from 'react';
import ShadowCard from '../../components/ShadowCard';
import { Typography } from '@mui/material';
import DataGrid from '../../components/DataGrid';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const columns = [
    {
        field: 'id',
        headerName: 'Id',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 100,
    },
    {
        field: 'site',
        headerName: 'Site',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 250,
        renderCell: params => params.row.site?.name,
    },
    {
        field: 'customer',
        headerName: 'Customer',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 200,
        renderCell: params =>
            params.row.customer?.first_name + ' ' + params.row.customer?.last_name,
    },
    {
        field: 'status',
        headerName: 'Status',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 250,
    },
    {
        field: 'date',
        headerName: 'Date',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 200,
        renderCell: params =>
            params.row.date ? DateTime.fromISO(params.row.date).toFormat('yyyy-MM-dd') : 'null',
    },
    {
        field: 'payment_type',
        headerName: 'Payment Type',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 200,
    },
    {
        field: 'shipping_type',
        headerName: 'SHIPPING TYPE',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 200,
    },
    {
        field: 'quantity',
        headerName: 'Quantità',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
    },

    {
        field: 'subtotal',
        headerName: 'Subtotal',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
    },
    {
        field: 'work_time_price',
        headerName: 'Work Time Price',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
    },

    {
        field: 'shipping_price',
        headerName: 'Shipping Price',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
    },
    {
        field: 'total',
        headerName: 'TOTAL',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
    },
    {
        field: 'refund_type',
        headerName: 'Refund Type',
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        width: 150,
    },
];

const Orders = () => {
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });
    const [rowCount, setRowCount] = useState(0);
    const customer = useSelector(state => state.customer.customer);
    const navigate = useNavigate();

    const { data, isLoading } = useQuery({
        queryKey: ['orders', customer.id, paginationModel.page],
        queryFn: async () => {
            const params = {
                customer__id: customer.id,
                page: Boolean(paginationModel.page) ? paginationModel.page + 1 : 1,
            };

            const response = await axios.get('/orders/', { params });
            setRowCount(response.data.count);
            return response.data;
        },
    });

    const orders = useMemo(() => (data ? data.results : []), [data]);

    useEffect(() => {
        setRowCount(prev => (data?.count !== undefined ? data?.count : prev));
    }, [data?.count, setRowCount]);

    return (
        <ShadowCard mt={6}>
            <Typography variant='subtitle1' fontWeight={500} mb={2}>
                ORDINI
            </Typography>
            <DataGrid
                rows={orders}
                columns={columns}
                autoHeight
                onRowClick={params => navigate(`/dashboard/order/preview/${params.id}`)}
                rowSelection={false}
                disableSelectionOnClick
                loading={isLoading}
                rowCount={rowCount}
                checkboxSelection={false}
                pageSizeOptions={[40]}
                paginationModel={paginationModel}
                paginationMode='server'
                onPaginationModelChange={setPaginationModel}
            />
        </ShadowCard>
    );
};

export default Orders;
