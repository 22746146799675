import {
    Box,
    Grid,
    Divider,
    Stack,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
} from '@mui/material';
import React from 'react';
import Image from '../../components/Image';
import ShadowCard from '../../components/ShadowCard';
import { useQuery } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import OutsetBox from '../../components/OutsetBox';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StarsIcon from '@mui/icons-material/Stars';
import Edit from '@mui/icons-material/Edit';

const columns = [
    {
        field: 'date',
        headerName: 'Data',
        width: 100,
    },
    {
        field: 'payment_type',
        headerName: 'Tipo di Pagamento',
        width: 150,
    },
    {
        field: 'status',
        headerName: 'Stato',
        width: 250,
    },
    {
        field: 'quantity',
        headerName: 'Quantità',
        width: 200,
    },
    {
        field: 'subtotal',
        headerName: 'Subtotal',
        width: 250,
    },
    {
        field: 'total',
        headerName: 'Total',
        width: 250,
    },
];

const QuotePreview = () => {
    const { quoteId } = useParams();

    const { data: quote } = useQuery({
        queryKey: ['quotes', quoteId],
        queryFn: async () => {
            const response = await axios.get(`/quotes/${quoteId}`);
            return response.data;
        },
    });

    console.log(quote);

    return quote ? (
        <>
            <Box
                sx={{
                    p: 2,
                    mt: 8,
                }}>
                <ShadowCard>
                    <Table>
                        <TableHead sx={{ bgcolor: 'white' }}>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                {columns.map(cell => (
                                    <TableCell key={cell.headerName}>{cell.headerName}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}>
                                <TableCell>
                                    {quote.date ? new Date(quote.date).toLocaleDateString() : ''}
                                </TableCell>

                                <TableCell>{quote.payment_type}</TableCell>
                                <TableCell>{quote.status}</TableCell>
                                <TableCell>{quote.quantity}</TableCell>
                                <TableCell>{quote.subtotal}</TableCell>
                                <TableCell>{quote.total}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </ShadowCard>

                <ShadowCard
                    sx={{
                        p: 2.5,
                        mt: 4,
                    }}>
                    {quote.groups.map(group => (
                        <Box>
                            <Stack
                                direction='row'
                                alignItems='center'
                                justifyContent='space-between'
                                mb={1.2}>
                                <Typography variant='h6' fontWeight={500} color='primary'>
                                    Gruppo Prodotto
                                </Typography>
                                <IconButton
                                    LinkComponent={Link}
                                    to={`/dashboard/quote/edit/${quote.id}`}
                                    color='primary'>
                                    <Edit />
                                </IconButton>
                            </Stack>
                            <Grid container spacing={4}>
                                {group.items.map(item => {
                                    const attributes = item.attributes
                                        ? Object.keys(item.attributes)
                                        : [];
                                    return (
                                        <Grid item xs={12} lg={6}>
                                            <Stack
                                                direction='row'
                                                justifyContent='space-between'
                                                spacing={3}>
                                                <Typography
                                                    variant='subtitle1'
                                                    fontWeight={500}
                                                    color='text.secondary'>
                                                    {item.name}
                                                </Typography>
                                            </Stack>
                                            <Grid container spacing={2} mt={2}>
                                                <Grid item xs={12} md={6} lg={3}>
                                                    <OutsetBox
                                                        label='Quantità'
                                                        value={item.quantity}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={3}>
                                                    <OutsetBox label='Prezzo' value={item.price} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                {attributes.map(attribute => (
                                                    <Grid item>
                                                        <OutsetBox
                                                            label={attribute}
                                                            value={item.attributes[attribute]}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                                <Grid item xs={12} lg={6} display='flex' flexDirection='column'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={group.files.length ? 8 : 12}>
                                            <Typography
                                                variant='subtitle1'
                                                fontWeight={500}
                                                color='text.secondary'
                                                mb={2}>
                                                Descrizione
                                            </Typography>
                                            <Box
                                                sx={{
                                                    borderRadius: '8px',
                                                    p: 2,
                                                    boxShadow:
                                                        '8px 8px 8px #d2d4d685, -8px -8px 8px #ffffff82',
                                                    backgroundColor: 'background.default',
                                                    flexGrow: 1,
                                                }}>
                                                {group.description}
                                            </Box>
                                        </Grid>
                                        {group.files.length ? (
                                            <Grid item xs={12} lg={4}>
                                                <Typography
                                                    variant='subtitle1'
                                                    fontWeight={500}
                                                    color='text.secondary'
                                                    mb={2}>
                                                    Files
                                                </Typography>
                                                {group.files.map(file => (
                                                    <Box
                                                        display='flex'
                                                        alignItems='center'
                                                        height='inherit'>
                                                        <Image
                                                            src={file?.file}
                                                            sx={{ maxHeight: 160 }}
                                                        />
                                                    </Box>
                                                ))}
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                </ShadowCard>

                <ShadowCard mt={6}>
                    <Stack direction={{ xs: 'column', lg: 'row' }} spacing={5}>
                        <Box flexGrow={1}>
                            <Stack direction='row' spacing={1}>
                                <AccessTimeFilledIcon />
                                <Typography variant='subtitle1' fontWeight={500}>
                                    Metodo Lavorazione
                                </Typography>
                            </Stack>
                            <Divider
                                sx={{ width: 386, borderWidth: 1, color: 'text.primary', mb: 3 }}
                            />
                            <Stack direction='row' spacing={2} mb={3}>
                                <OutsetBox
                                    label='Titolo'
                                    sx={{ flexGrow: 1 }}
                                    value={quote.work_time_method?.title}
                                />

                                <OutsetBox
                                    label='Prezzo'
                                    sx={{ flexGrow: 1 }}
                                    value={quote.work_time_price}
                                />
                            </Stack>
                        </Box>

                        <Box flexGrow={1}>
                            <Stack direction='row' spacing={1}>
                                <LocalShippingIcon />
                                <Typography variant='subtitle1' fontWeight={500}>
                                    Metodo Spedizione
                                </Typography>
                            </Stack>
                            <Divider
                                sx={{ width: 386, borderWidth: 1, color: 'text.primary', mb: 3 }}
                            />
                            <Stack direction='row' spacing={2} mb={3}>
                                <OutsetBox
                                    label='Titolo'
                                    sx={{ flexGrow: 1 }}
                                    value={quote.shipping_method ? quote.shipping_method.title : ''}
                                />
                                <OutsetBox
                                    label='Prezzo'
                                    sx={{ flexGrow: 1 }}
                                    value={quote.shipping_price}
                                />
                            </Stack>
                        </Box>
                    </Stack>

                    {quote.extra_services.length ? (
                        <>
                            <Stack direction='row' spacing={1} mt={3}>
                                <StarsIcon />
                                <Typography variant='subtitle1' fontWeight={500}>
                                    Servizi Extra
                                </Typography>
                            </Stack>

                            <Divider
                                sx={{ width: 386, borderWidth: 1, color: 'text.primary', mb: 3 }}
                            />
                            {quote.extra_services.map((service, i) => (
                                <Box mb={5} key={i}>
                                    <Stack
                                        direction='row'
                                        spacing={2}
                                        mb={2}
                                        alignItems='center'
                                        justifyContent='space-between'>
                                        <Stack direction='row' spacing={2}>
                                            <OutsetBox
                                                label='Titolo'
                                                value={service.title}
                                                sx={{ minWidth: '200px' }}
                                            />
                                            <OutsetBox
                                                label='Prezzo'
                                                value={service.price}
                                                sx={{ minWidth: '200px' }}
                                            />
                                        </Stack>
                                    </Stack>

                                    <Stack
                                        direction='row'
                                        alignItems='center'
                                        justifyContent='space-between'>
                                        <Typography
                                            variant='subtitle1'
                                            fontWeight={500}
                                            color='text.tertiary'
                                            mb={2}>
                                            Descrizione
                                        </Typography>
                                    </Stack>
                                    <OutsetBox
                                        label='Description'
                                        value={service.description}
                                        sx={{ borderRadius: '8px', px: 2 }}
                                    />
                                </Box>
                            ))}
                        </>
                    ) : null}
                </ShadowCard>

                <Grid container spacing={5} mt={2}>
                    {quote.billing ? (
                        <Grid item xs={12} lg={6}>
                            <ShadowCard>
                                <Typography variant='subtitle1' fontWeight={500}>
                                    Indirizzo di Fatturazione
                                </Typography>

                                <Divider
                                    sx={{
                                        width: 386,
                                        borderWidth: 1,
                                        color: 'text.primary',
                                        mb: 4,
                                    }}
                                />

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox
                                            label='Nome'
                                            value={quote.billing.first_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox
                                            label='Cognome'
                                            value={quote.billing.last_name}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={9}>
                                        <OutsetBox
                                            label='Ragione Sociale'
                                            value={quote.billing.company}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <OutsetBox label='Partita IVA' value={quote.billing.vat} />
                                    </Grid>
                                </Grid>
                                <OutsetBox label='Indirizzo1' value={quote.billing.address_1} />
                                <OutsetBox label='Indirizzo2' value={quote.billing.address_2} />

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox label='Stato' value={quote.billing.country} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox label='Provincia' value={quote.billing.state} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox label='Città' value={quote.billing.city} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox
                                            label='Codice Postale'
                                            value={quote.billing.postcode}
                                        />
                                    </Grid>
                                </Grid>
                            </ShadowCard>
                        </Grid>
                    ) : null}
                    {quote.shipping ? (
                        <Grid item xs={12} lg={6}>
                            <ShadowCard>
                                <Typography variant='subtitle1' fontWeight={500}>
                                    Indirizzo di Spedizione
                                </Typography>

                                <Divider
                                    sx={{
                                        width: 386,
                                        borderWidth: 1,
                                        color: 'text.primary',
                                        mb: 4,
                                    }}
                                />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox
                                            label='Nome'
                                            value={quote.shipping.first_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox
                                            label='Cognome'
                                            value={quote.shipping.last_name}
                                        />
                                    </Grid>
                                </Grid>

                                <OutsetBox label='Ragione Sociale' value={quote.shipping.company} />

                                <OutsetBox label='Indirizzo1' value={quote.shipping.address_1} />
                                <OutsetBox label='Indirizzo2' value={quote.shipping.address_2} />

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox label='Stato' value={quote.shipping.country} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox label='Provincia' value={quote.shipping.state} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox label='Città' value={quote.shipping.city} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <OutsetBox
                                            label='Codice Postale'
                                            value={quote.shipping.postcode}
                                        />
                                    </Grid>
                                </Grid>
                            </ShadowCard>
                        </Grid>
                    ) : null}
                </Grid>
            </Box>
        </>
    ) : null;
};

export default QuotePreview;
