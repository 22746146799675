import Box from '@mui/material/Box';
import EmojiPicker from 'emoji-picker-react';

const Emoji = props => {
    const { open, expanded, emojiPicker } = props;

    console.log('emoji comp');
    console.dir(emojiPicker);

    return (
        <Box
            sx={{
                transition: 'height 150ms, cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                boxShadow: '16px 16px 16px #d2d4d685, -16px -16px 16px #ffffff82',
                borderRadius: '8px',
            }}>
            {open && (
                <HigherOrderComponent
                    emojiStyle='native'
                    width='100%'
                    height={`calc(100dvh - ${expanded ? '340px' : '260px'})`}
                    onEmojiClick={emojiPicker}
                    emojiVersion='11.0'
                    style={{
                        background: 'transparent',
                        border: 'none',
                        borderRadius: '8px',
                    }}
                />
            )}
        </Box>
    );
};

const HigherOrderComponent = function (props) {
    console.log('High Order Component');
    console.dir(props.onEmojiClick);
    return <EmojiPicker {...props} />;
};

export default Emoji;
