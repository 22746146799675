import axios from 'axios';
import { env } from '../utils/function';
import { getCookie } from '../utils/cookies';

axios.defaults.baseURL = env('SERVER');
axios.defaults.withCredentials = false;

axios.interceptors.request.use(function (config) {
    const accessToken = getCookie('accessToken');
    config.headers.Authorization = `token ${accessToken}`;
    return config;
});
