import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import Login from '../pages/auth/Login';
import { clearCookie, getCookie } from '../utils/cookies';
import axios from 'axios';
// import axios from 'axios';

const authorizeContext = createContext();

const AuthorizationProvider = ({ children }) => {
    const accessToken = getCookie('accessToken');
    const [content, setContent] = useState(accessToken ? children : <Login />);
    const [user, setUser] = useState({});

    const authorize = useCallback(
        async (loggedIn, cb) => {
            if (loggedIn) {
                setContent(children);
            } else {
                setContent(<Login />);
            }
            if (typeof cb === 'function') cb(setUser);
        },
        [children]
    );

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get(`/user/me/`);
                const user = response.data;

                authorize(true, setUser => setUser(user));
            } catch (err) {
                console.log(err);
                clearCookie('accessToken');
                authorize(false);
            }
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <authorizeContext.Provider value={{ authorize, setUser, user, setContent }}>
            {content}
        </authorizeContext.Provider>
    );
};

const useAuthorize = () => useContext(authorizeContext)?.authorize;
const useUser = () => useContext(authorizeContext)?.user;
const useSetUser = () => useContext(authorizeContext).setUser;
const useSetContent = () => useContext(authorizeContext).setContent;

export default AuthorizationProvider;
export { useAuthorize, useUser, useSetUser, useSetContent };
