function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div role='tabpanel' hidden={value !== index} style={{ height: '100%' }}>
            {value === index && children}
        </div>
    );
}

export default TabPanel;
