import { Card, Container, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useIntersection } from '@mantine/hooks';
import { useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';
import { compareDate, generateDate } from '../../../utils/function';
import DescriptionIcon from '@mui/icons-material/Description';

const Doc = () => {
    const conversation = useSelector(state => state.customer.conversation);
    const containerRef = useRef(null);

    const { entry, ref } = useIntersection({ root: containerRef.current, threshold: 1 });

    const { data, isError, error, hasNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: ['chat-file', conversation.id],
        queryFn: async ({ queryKey, pageParam }) => {
            const response = await axios.get('/messages/', {
                params: { conversation__id: queryKey[1], page: pageParam, attachment_type: 'file' },
            });
            return response.data;
        },
        initialPageParam: 1,
        getNextPageParam: (prevData, pages) => {
            return prevData.next ? pages.length + 1 : null;
        },
        refetchOnWindowFocus: false,
    });

    const messages = useMemo(() => (data ? data.pages.flatMap(page => page.results) : []), [data]);

    useEffect(() => {
        if (hasNextPage && entry?.isIntersecting) fetchNextPage();
    }, [hasNextPage, entry, fetchNextPage]);

    if (isError) {
        return <h3>{error.message}</h3>;
    }

    return (
        <Container maxWidth='lg' sx={{ height: '100%' }} ref={containerRef}>
            <Grid container>
                {messages.map((message, i) => (
                    <React.Fragment key={i}>
                        <Grid
                            item
                            xs={12}
                            display={
                                compareDate(message.creation_time, messages[i + 1]?.creation_time)
                                    ? 'none'
                                    : 'block'
                            }>
                            <Typography variant='body2' color='text.secondary' mt={4}>
                                {generateDate(message.creation_time)}
                            </Typography>
                            <Divider sx={{ my: 1 }} />
                        </Grid>
                        <Grid item xs={12} ref={i === messages.length - 1 ? ref : null}>
                            <Card
                                sx={{
                                    p: 1,
                                    mb: 2,
                                    display: 'flex',
                                    borderRadius: '10px',
                                    backgroundColor: 'background.default',
                                    boxShadow: '8px 8px 8px #d2d4d685, -8px -8px 8px #ffffff82',
                                }}
                                elevation={0}>
                                <DescriptionIcon color='primary' sx={{ mr: 1 }} />
                                <Typography
                                    variant='body2'
                                    sx={{
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: '1',
                                    }}>
                                    {message.content}
                                </Typography>
                            </Card>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Container>
    );
};

export default Doc;
