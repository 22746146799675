import {
    Box,
    Button,
    Grid,
    Stack,
    Tab,
    Tabs,
    Breadcrumbs,
    Link,
    Typography,
    MenuItem,
    Menu,
    ListItemText,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LeftArrowIcon, RightArrowIcon } from '../../components/ArrowIcon';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setDraft } from '../../features/messages/messageSlice';
import { isEmpty } from '../../utils/function';
import { setSidePanelWidth } from '../../features/sidepanel/sidepanelSlice';
import Folder from '../../components/Folder';
import TabPanel from '../../components/TabPanel';
import useErrorHandler from '../../hooks/useErrorHandler';
import { useMenu } from '../../hooks/useMenu';

const Risposte = props => {
    const { search, setSearch } = props;
    const customer = useSelector(state => state.customer.customer);
    const [tabSelected, setTabSelected] = useState(0);
    const sidePanelWidth = useSelector(state => state.sidepanel.width);
    const draft = useSelector(state => state.message.draft);
    const dispatch = useDispatch();
    const [selectedBreadcrumb, setSelectedBreadcrumb] = useState({});
    const [answers, setAnswers] = useState([]);

    const handleTabChange = (event, newValue) => setTabSelected(newValue);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const errorHandler = useErrorHandler();
    const { anchorEl: anchorElSite, openMenu: openSiteMenu, closeMenu: closeSiteMenu } = useMenu();
    const [site, setSite] = useState(isEmpty(customer) ? {} : customer?.site[0]);

    const { data, isError, error } = useQuery({
        queryKey: ['predefined_answers_category', site.id],
        queryFn: async () => {
            const params = {
                site__id: site.id,
            };

            const response = await axios.get('/predefined_answers_category/', { params });
            return response.data;
        },
        staleTime: 1000 * 60 * 60 * 24,
        enabled: Boolean(site.id),
    });

    const searchAnswers = useCallback(
        async search => {
            try {
                if (!breadcrumbs[0]) return;
                const response = await axios.get('/predefined_answers/', { params: { search } });
                const answers = response.data.results.filter(
                    result => result.message.parent === breadcrumbs[0]?.name
                );

                setAnswers(answers);
            } catch (err) {
                errorHandler(err);
            }
        },
        [errorHandler, breadcrumbs]
    );

    const categories = useMemo(() => (data ? data : []), [data]);

    const isOpened = useCallback(
        id => (breadcrumbs.length ? breadcrumbs[breadcrumbs.length - 1].id === id : false),
        [breadcrumbs]
    );

    const addBreadcrumb = path => {
        const isExists = breadcrumbs.some(breadcrumb => breadcrumb.id === path.id);
        if (isExists) return;

        return setBreadcrumbs(prev => [...prev, path]);
    };

    const addParentBreadcrumb = path => {
        return setBreadcrumbs([path]);
    };

    const selectBreadcrumb = index => {
        const updatedArray = breadcrumbs.slice(0, index + 1);
        setBreadcrumbs(updatedArray);
        setSelectedBreadcrumb(updatedArray[updatedArray.length - 1]);
    };

    useEffect(() => {
        if (sidePanelWidth <= 450) setSelectedBreadcrumb({});
    }, [sidePanelWidth]);

    useEffect(() => {
        if (search) searchAnswers(search);
    }, [search, searchAnswers]);

    useEffect(() => {
        setSearch('');
        if (selectedBreadcrumb.answers && selectedBreadcrumb.answers.length) {
            return setAnswers(selectedBreadcrumb.answers);
        }
        setAnswers([]);
    }, [selectedBreadcrumb, setSearch]);

    if (isError) {
        return <h3>{error.message}</h3>;
    }

    return (
        <Grid container columnGap={5} height={'100%'}>
            <Grid item xs display='flex' flexDirection='column'>
                <Tabs
                    value={tabSelected}
                    onChange={handleTabChange}
                    sx={{
                        mt: 2,
                        minHeight: '28px',
                        borderTopLeftRadius: '16px',
                        '& .MuiTabs-indicator': {
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: 'transparent',
                        },
                        '.MuiButtonBase-root.MuiTab-root': {
                            borderTopLeftRadius: '16px',
                            borderTopRightRadius: '16px',
                            px: 2,
                            py: 0.3,
                            minHeight: '28px',
                            minWidth: '126px',
                            alignItems: 'flex-start',
                            fontSize: '13px',
                            fontWeight: 400,
                            boxShadow: '8px 8px 8px #d2d4d685, -8px -8px 8px #ffffff82',
                            backgroundColor: 'background.default',
                        },
                    }}>
                    <Tab label='Generiche' />
                </Tabs>

                <Box
                    py={2}
                    sx={{
                        boxShadow: '16px 16px 16px #d2d4d685, -16px -16px 16px #ffffff82',
                        borderRadius: '0px 8px 8px 8px',
                        height: `calc(100% - 95px)`,
                        transition: 'height 160ms, cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        overflowY: 'auto',
                    }}>
                    <TabPanel value={tabSelected} index={0}>
                        <Grid
                            container
                            columnSpacing={2}
                            rowSpacing={3}
                            p={2}
                            sx={{
                                '.MuiButtonBase-root': {
                                    fontSize: 12,
                                    justifyContent: 'space-between',
                                    px: 2,
                                    fontWeight: 500,
                                },
                            }}>
                            <Grid item xs={12}>
                                <Button
                                    variant='text'
                                    fullWidth
                                    onClick={openSiteMenu}
                                    sx={{ py: 0.2 }}
                                    endIcon={
                                        <LeftArrowIcon
                                            sx={{ transform: 'rotate(270deg)' }}
                                            fontSize='16px'
                                            borderWidth='1px'
                                        />
                                    }>
                                    {site.id ? site.name : 'Customer Sites'}
                                </Button>
                            </Grid>

                            {categories.length
                                ? categories.map((category, i) => (
                                      <Grid item xs={6} key={i}>
                                          <Button
                                              fullWidth
                                              variant={
                                                  breadcrumbs[0] &&
                                                  breadcrumbs[0].id === category.id
                                                      ? 'contained'
                                                      : 'text'
                                              }
                                              color='primary'
                                              onClick={() => {
                                                  setSelectedBreadcrumb(category);
                                                  addParentBreadcrumb(category);
                                                  dispatch(setSidePanelWidth(900));
                                              }}
                                              endIcon={
                                                  <RightArrowIcon
                                                      borderWidth='1px'
                                                      innerPadding='1px'
                                                  />
                                              }>
                                              {category.name}
                                          </Button>
                                      </Grid>
                                  ))
                                : null}
                        </Grid>
                    </TabPanel>
                </Box>
            </Grid>
            {!isEmpty(selectedBreadcrumb) && (
                <Grid item xs display='flex' flexDirection='column' flexWrap='nowrap'>
                    <Breadcrumbs
                        sx={{
                            alignSelf: 'flex-start',
                            boxShadow: '8px -2px 8px #d2d4d685, -8px -8px 8px #ffffff82',
                            borderTopLeftRadius: '16px',
                            borderTopRightRadius: '16px',
                            backgroundColor: 'background.default',
                            px: 2,
                            py: 0.3,
                            mt: 2,
                            minHeight: '28px',
                            transform: 'translateY(1px)',
                            '.MuiLink-root': { fontSize: 13 },
                        }}
                        separator={
                            <RightArrowIcon
                                color='text.primary'
                                borderWidth='1px'
                                innerPadding='1px'
                                fontSize='16px'
                            />
                        }>
                        {breadcrumbs.map((path, i) => (
                            <Link
                                key={i}
                                underline='hover'
                                color={isOpened(path.id) ? 'primary.main' : 'inherit'}
                                onClick={() => selectBreadcrumb(i)}
                                sx={{ cursor: 'pointer', '&:hover': { color: 'primary.main' } }}>
                                {path.name}
                            </Link>
                        ))}
                    </Breadcrumbs>
                    <Box
                        // flexGrow={1}
                        p={2}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            boxShadow: '16px 16px 16px #d2d4d685, -16px -16px 16px #ffffff82',
                            borderRadius: '0px 8px 8px 8px',
                            height: `calc(100% - 95px)`,
                            transition: 'height 160ms, cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            overflowY: 'auto',
                        }}>
                        {selectedBreadcrumb.child.length ? (
                            <>
                                <Stack
                                    direction='row'
                                    alignItems='flex-end'
                                    spacing={1}
                                    mt={3}
                                    mb={1}>
                                    <FolderOutlinedIcon fontSize='small' color='primary' />
                                    <Typography
                                        variant='body2'
                                        color='primary.main'
                                        lineHeight={1.15}>
                                        Cartelle
                                    </Typography>
                                </Stack>
                                <Grid container spacing={2}>
                                    {selectedBreadcrumb.child.map(folder => (
                                        <Grid item xs={6} key={folder.id}>
                                            <Folder
                                                name={folder.name}
                                                onClick={() => {
                                                    addBreadcrumb(folder);
                                                    setSelectedBreadcrumb(folder);
                                                }}
                                                isOpened={isOpened(folder.id)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </>
                        ) : null}
                        {selectedBreadcrumb.answers.length || answers.length ? (
                            <>
                                <Stack
                                    direction='row'
                                    alignItems='flex-end'
                                    spacing={1}
                                    mt={3}
                                    mb={1}>
                                    <SendOutlinedIcon
                                        fontSize='small'
                                        color='primary'
                                        sx={{ transform: 'rotate(-45deg)' }}
                                    />
                                    <Typography
                                        variant='body2'
                                        color='primary.main'
                                        lineHeight={1.15}>
                                        Risposte rapide
                                    </Typography>
                                </Stack>
                                <Stack direction='row' flexDirection='column' flexGrow={1}>
                                    <Box py={4} flexGrow={1}>
                                        {answers.map((answer, i) => (
                                            <Button
                                                fullWidth
                                                color='primary'
                                                variant={'text'}
                                                sx={{ mb: 3 }}
                                                key={i}
                                                onClick={() =>
                                                    dispatch(setDraft(draft + ' ' + answer.answer))
                                                }>
                                                {answer.answer}
                                            </Button>
                                        ))}
                                    </Box>
                                </Stack>
                            </>
                        ) : null}
                    </Box>
                </Grid>
            )}

            <Menu
                anchorEl={anchorElSite}
                open={Boolean(anchorElSite)}
                onClose={closeSiteMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                type='glassMorphism'
                sx={{
                    '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                        width: 'min(100%, 340px)',
                    },
                    '& .MuiButtonBase-root:hover': {
                        backgroundColor: 'primary.main',
                        '& .MuiTypography-root, .MuiSvgIcon-root': {
                            color: 'white',
                        },
                    },
                }}>
                {customer.site ? (
                    customer.site.map(site => (
                        <MenuItem
                            key={site.id}
                            onClick={() => {
                                setSite(site);
                                closeSiteMenu();
                            }}>
                            <ListItemText
                                primaryTypographyProps={{
                                    fontSize: 14,
                                    color: 'text.secondary',
                                }}>
                                {site.name}
                            </ListItemText>
                        </MenuItem>
                    ))
                ) : (
                    <Typography variant='body2' px={2}>
                        Select a chat to get the sites
                    </Typography>
                )}
            </Menu>
        </Grid>
    );
};

export default Risposte;
