import { DateTime } from 'luxon';

function env(name) {
    const nodeENV = process.env.NODE_ENV.toUpperCase();

    return process.env[`REACT_APP_${nodeENV}_${name}`] || process.env[`REACT_APP_${name}`];
}

function parseKB(KB) {
    const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB'];
    if (KB === 0) return '0 KB';
    const i = Math.floor(Math.log2(KB) / 10);
    return `${parseFloat((KB / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
}

const getSessionData = name => sessionStorage[name];
const setSessionData = (name, value) => (sessionStorage[name] = value);

const getLocalStorage = key => localStorage.getItem(key);
const setLocalStorage = (key, value) => localStorage.setItem(key, value);
const removeLocalStorage = key => localStorage.removeItem(key);

const isDefined = v => typeof v !== 'undefined' && v !== null;
const isEmpty = obj => Object.keys(obj).length === 0;
const isObject = obj => typeof obj === 'object' && !Array.isArray(obj) && obj !== null;

const isImage = file => file['type'].split('/')[0] === 'image';
const isVideo = file => file['type'].split('/')[0] === 'video';
const getFileType = name => {
    if (!name) return '';
    return name
        .match(/.[a-zA-Z0-9]+$/)[0]
        .toUpperCase()
        .slice(1);
};

const isImageUrl = file => {
    const imageExt = ['gif', 'jpg', 'jpeg', 'png'];
    const extension = file.match(/.[a-zA-Z0-9]+$/)[0].slice(1);
    return imageExt.includes(extension);
};

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

function escapeDanger(content) {
    const regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gim;

    if (regex.test(content)) return null;
    return { __html: content };
}

const hasSame = (a, b) => a === b;

const generateDate = d => {
    const date = DateTime.fromISO(d);
    const today = DateTime.now();

    const dateWeekNumber = date.weekNumber;
    const todayWeekNumber = today.weekNumber;
    const Yesterday = today.minus({ days: 1 });

    if (todayWeekNumber === dateWeekNumber) {
        if (date.hasSame(today, 'day')) return 'Today';
        else if (date.hasSame(Yesterday, 'day')) return 'Yesterday';
        return date.weekdayLong;
    } else {
        return date.toFormat('dd-MM-yyyy');
    }
};

function parseHTMLTags(content) {
    const regex = /(<([^>]+)>)/gim;

    return content ? content.replace(regex, '') : content;
}

const parseLinks = text => {
    const urlRegex =
        /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])/;
    return text.replace(urlRegex, url => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
};

const compareDate = (d1, d2 = '1970-01-01') => {
    const date1 = DateTime.fromISO(d1).toFormat('yyyy-MM-dd');
    const date2 = DateTime.fromISO(d2).toFormat('yyyy-MM-dd');
    return date1 === date2;
};

const isString = value => typeof value === 'string';

const customer_visual_name = (customer) => {
    if (customer.first_name || customer.last_name){
        if (customer.first_name && !customer.last_name){
            return customer.first_name
        } else if (!customer.first_name && customer.last_name){
            return customer.last_name
        }
        return customer?.first_name + ' ' + customer?.last_name
    } else if(customer.email){
        return customer.email
    } else if(customer.phone){
        return customer.phone
    }
    return null
}

export {
    env,
    getSessionData,
    setSessionData,
    setLocalStorage,
    getLocalStorage,
    removeLocalStorage,
    isDefined,
    isEmpty,
    isImage,
    isVideo,
    parseKB,
    getFileType,
    getBase64,
    escapeDanger,
    isImageUrl,
    generateDate,
    compareDate,
    isObject,
    isString,
    hasSame,
    parseHTMLTags,
    parseLinks,
    customer_visual_name
};
