import { Box, Button, OutlinedInput, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Form, Submit, useForm } from '../../hooks/useForm/index';
import { Input } from '../../hooks/useForm/inputs/index';
import { useAuthorize } from '../../hooks/Authorize';
import { setCookie } from '../../utils/cookies';
import { env } from '../../utils/function';
import validators from '../../utils/validators';
import useErrorHandler from '../../hooks/useErrorHandler';

export default function Login() {
    const authorize = useAuthorize();
    const errorHandler = useErrorHandler();
    const handlers = useForm(
        useMemo(
            () => ({
                email: { value: '', validator: validators.email },
                password: { value: '', required: true },
            }),
            []
        ),
        { Input: OutlinedInput }
    );

    const onSubmit = response => {
        const { token } = response.data;

        if (token) {
            setCookie('accessToken', token);
            authorize(true, setUser => setUser({}));
            window.location.reload();
        }
    };

    return (
        <Box
            sx={{
                background: 'background.default',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                flexDirection: 'column',
                py: 2,
            }}>
            <Box
                sx={{
                    p: 2,
                    maxWidth: '465px',
                    width: '100%',
                    mx: 2,
                }}>
                {' '}
                <Box sx={{ p: { xs: 2, sm: 4 } }}>
                    <Typography variant='h4' fontWeight={500} textAlign='center' gutterBottom>
                        Log in
                    </Typography>
                    <Typography
                        variant='body2'
                        sx={{ color: 'rgba(0, 0, 0, 0.5)', mb: 7 }}
                        textAlign='center'>
                        Inserisci le credenziali per effettuare il login
                    </Typography>

                    <Form
                        handlers={handlers}
                        onSubmit={onSubmit}
                        method='post'
                        action={`${env('SERVER')}/user/token/`}
                        onError={errorHandler}>
                        <Typography variant='subtitle2' sx={{ fontWeight: '500' }} gutterBottom>
                            Email
                        </Typography>
                        <Input fullWidth name='email' placeholder='name@domain.com' />
                        <Typography variant='subtitle2' sx={{ fontWeight: ' 500' }} gutterBottom>
                            Password
                            {/* <div
                                variant='body2'
                                style={{
                                    float: 'right',
                                    textDecoration: 'none',
                                    color: '#0472D2',
                                    cursor: 'pointer',
                                }}>
                                Forget password?
                            </div> */}
                        </Typography>
                        <Input
                            type='password'
                            fullWidth
                            name='password'
                            placeholder='contain at least 8 characters'
                        />

                        <Submit>
                            {loader => (
                                <Button
                                    type='submit'
                                    variant='contained'
                                    size='large'
                                    fullWidth
                                    sx={{ p: 1.5, mb: 1, mt: 3 }}
                                    disabled={Boolean(loader)}
                                    endIcon={loader}>
                                    Login
                                </Button>
                            )}
                        </Submit>
                    </Form>
                </Box>
            </Box>
        </Box>
    );
}
