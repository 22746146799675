import {
    Box,
    Button,
    Select,
    IconButton,
    InputBase,
    Divider,
    Grid,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid from '../../components/DataGrid';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useMenu } from '../../hooks/useMenu';
import { DateTime } from 'luxon';
import { setCustomer } from '../../features/customer/customerSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useErrorHandler from '../../hooks/useErrorHandler';
import { conditions } from '../../data/filters';
import { useDebouncedValue } from '@mantine/hooks';
import AddIcon from '@mui/icons-material/Add';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { order } from '../../data/choices';
import { useMessage } from '../../layouts/Header';
import Filter from '../../components/Filter';
import {customer_visual_name} from '../../utils/function';
import SelectTable from '../../components/SelectTable';

const Index = () => {
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });
    const [rowCount, setRowCount] = useState(0);
    const {
        anchorEl: anchorElFilter,
        openMenu: openFilterMenu,
        closeMenu: closeFilterMenu,
    } = useMenu();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const { showSuccess } = useMessage();

    const [filters, setFilters] = useState([{ field: '', condition: '', value: '' }]);
    const [options, setOptions] = useState({ filter_operator: 'and', filter: false });
    const [search, setSearch] = useState('');
    const searchText = useDebouncedValue(search, 400)[0];

    const onFilterHandler = (e, i) => {
        const { name, value } = e.target;
        setFilters(prev => {
            const newFilters = [...prev];
            if (name === 'field') {
                newFilters[i].condition = '';
                newFilters[i].value = '';
            }
            newFilters[i][name] = value;
            return newFilters;
        });
    };

    const addFilter = () => {
        setFilters(prev => [...prev, { field: '', condition: '', value: '' }]);
    };

    const removeFilter = i => {
        if (!filters[i]) return;
        const updated = [...filters];
        updated.splice(i, 1);
        setFilters(updated);
    };

    const getCustomer = useCallback(
        async (customerId, orderId) => {
            try {
                const response = await axios.get(`/customers/${customerId}/`);
                dispatch(setCustomer(response.data));
                navigate(`/dashboard/order/preview/${orderId}`);
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, dispatch, navigate]
    );

    const { data, error, isError, isLoading, refetch } = useQuery({
        queryKey: ['orders', paginationModel.page, searchText, options.filter],
        queryFn: async () => {
            const params = {
                page: Boolean(paginationModel.page) ? paginationModel.page + 1 : 1,
            };

            if (searchText) params.filter = JSON.stringify({ search: searchText });

            if (options.filter)
                params.filter = JSON.stringify({
                    fields: filters,
                    filter_operator: options.filter_operator,
                    // ...(searchText ? { search: searchText } : {}),
                });

            const response = await axios.get(`/orders/`, { params });
            setRowCount(response.data.count);
            return response.data;
        },
    });

    const orders = useMemo(() => (data ? data.results : []), [data]);

    useEffect(() => {
        setRowCount(prev => (data?.count !== undefined ? data?.count : prev));
    }, [data?.count, setRowCount]);

    const alterOrder = useCallback(
        async (id, options) => {
            try {
                await axios.patch(`/orders/${id}/`, { [options.field]: options.value });

                showSuccess(`${options.field} cambiato in  ${options.value}`);
                refetch();
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, refetch, showSuccess]
    );

    const columns = useMemo(
        () => [
            {
                field: 'n_order',
                headerName: 'N° Ordine',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 100,
            },
            {
                field: 'date',
                headerName: 'Data',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 150,
                renderCell: params =>
                    params.row.date
                        ? DateTime.fromISO(params.row.date).toFormat('dd/MM/yyyy hh:mm')
                        : null,
            },
            {
                field: 'customer',
                headerName: 'Cliente',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params =>
                    customer_visual_name(params.row.customer)
            },
            {
                field: 'status',
                headerName: 'Stato Ordine',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='status' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'site',
                headerName: 'Sito',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 250,
                renderCell: params =>
                    params.row.site?.name
            },
            {
                field: 'quantity',
                headerName: 'Quantità',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 150,
            },
            {
                field: 'shipping_type',
                headerName: 'Spedizione - Ritiro',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='shipping_type' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'work_time_type',
                headerName: 'Tipo Lavorazione Richiesta',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 230,
                renderCell: params => (
                    <SelectTable field='work_time_type' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'scadenza',
                headerName: 'Scadenza',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 150,
                renderCell: params =>
                    params.row.expiry
                        ? DateTime.fromISO(params.row.expiry).toFormat('dd/MM/yyyy')
                        : null,
            },
            {
                field: 'print_type',
                headerName: 'Tipo di Stampa',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='print_type' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'print_status',
                headerName: 'Stato Lavorazione',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='print_status' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'shipping_retire_status',
                headerName: 'Stato Ritiro/Spedizione',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='shipping_retire_status' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'payment_status',
                headerName: 'Stato Pagamento',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='payment_status' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'payment_type',
                headerName: 'Metodo Pagamento',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='payment_type' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'invoice',
                headerName: 'Ricevuta/Fattura',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='invoice' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'total',
                headerName: 'TOTALE',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 150,
                renderCell: params =>
                    params.row.total
                        ? params.row.total + ' €'
                        : null,
            },
            {
                field: 'payment_1_tranche',
                headerName: '1^ Trance Pagamento',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params =>
                    params.row.payment_1_tranche > 0
                        ? params.row.payment_1_tranche + ' €'
                        : null,
            },
            {
                field: 'payment_2_tranche',
                headerName: '2^ Trance Pagamento',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params =>
                    params.row.payment_2_tranche > 0
                        ? params.row.payment_2_tranche + ' €'
                        : null,
            },
            {
                field: 'creation_origin',
                headerName: 'Origine Ordine',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='creation_origin' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'graphic_request',
                headerName: 'Richiesta Grafico',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='graphic_request' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            // {
            //     field: 'graphic_customer_approval',
            //     headerName: 'graphic customer approval',
            //     sortable: false,
            //     headerAlign: 'center',
            //     align: 'center',
            //     width: 250,
            //     renderCell: params => (
            //         <Select
            //             value={params.row.graphic_customer_approval}
            //             size='small'
            //             fullWidth
            //             onChange={e =>
            //                 alterOrder(params.id, {
            //                     field: 'graphic_customer_approval',
            //                     value: e.target.value,
            //                 })
            //             }>
            //             {order.graphic_customer_approval.map(value => (
            //                 <MenuItem value={value} sx={{ textTransform: 'capitalize' }}>
            //                     {value}
            //                 </MenuItem>
            //             ))}
            //         </Select>
            //     ),
            // },
            // {
            //     field: 'graphic_edit_approval',
            //     headerName: 'graphic edit approval',
            //     sortable: false,
            //     headerAlign: 'center',
            //     align: 'center',
            //     width: 250,
            //     renderCell: params => (
            //         <Select
            //             value={params.row.graphic_edit_approval}
            //             size='small'
            //             fullWidth
            //             onChange={e =>
            //                 alterOrder(params.id, {
            //                     field: 'graphic_edit_approval',
            //                     value: e.target.value,
            //                 })
            //             }>
            //             {order.graphic_edit_approval.map(value => (
            //                 <MenuItem value={value} sx={{ textTransform: 'capitalize' }}>
            //                     {value}
            //                 </MenuItem>
            //             ))}
            //         </Select>
            //     ),
            // },
        ],
        [alterOrder]
    );

    if (isError) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}>
                <Typography variant='h5' sx={{ color: 'error.main' }}>
                    {error.message}
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    p: 2,
                    mt: 8,
                    mb: 4,
                }}>
                <Typography variant='h5'>Lista Ordini</Typography>
                <Divider variant='fullWidth' sx={{ my: 2 }} />

                <Box bgcolor='#DBDBF0' p={1} mb={4} borderRadius='50px'>
                    <Grid container columnSpacing={2} alignItems='center'>
                        <Grid item xs={12} md>
                            <Typography variant='subtitle1' fontWeight={500}>
                                Visualizzati <b> {orders.length} Ordini </b>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md='auto'>
                            <Filter
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                                onClick={openFilterMenu}
                                wrapperProps={{
                                    sx: {
                                        marginLeft: 'auto',
                                        maxWidth: '188px',
                                        width: '100%',
                                        border: '2px solid white',
                                        boxShadow: 'none',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <DataGrid
                    rows={orders}
                    columns={columns}
                    autoHeight
                    onRowClick={async params => {
                        await getCustomer(params.row.customer.id, params.id);
                    }}
                    rowSelection={false}
                    disableSelectionOnClick
                    loading={isLoading}
                    rowCount={rowCount}
                    checkboxSelection={false}
                    pageSizeOptions={[40]}
                    paginationModel={paginationModel}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                />
            </Box>

            <Menu
                anchorEl={anchorElFilter}
                open={Boolean(anchorElFilter)}
                onClose={closeFilterMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                // type='glassMorphism'

                sx={{
                    marginTop: '20px',
                    '& ul.MuiList-root.MuiMenu-list': {
                        padding: 0,
                    },
                    '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                        width: 'min(100%, 1000px)',
                        boxShadow:
                            'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
                        border: '1px solid',
                        borderColor: 'white',
                        backgroundColor: 'background.default',
                        borderRadius: '8px',
                        p: 0,
                        overflowY: 'unset',
                    },
                }}>
                <Box p={1.6}>
                    {filters.map((filter, i) => (
                        <Stack direction='row' spacing={2} padding={1.4} key={'filter_'+i}>
                            <Select
                                displayEmpty
                                name='field'
                                value={filter.field}
                                onChange={e => onFilterHandler(e, i)}
                                fullWidth
                                renderValue={v => {
                                    if (!v) return 'Colonna';
                                    return v;
                                }}
                                sx={{ borderRadius: 1.5, border: '2px solid white' }}>
                                {columns.map(col => (
                                    <MenuItem value={col.field} key={col.field}>
                                        {col.headerName}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Select
                                displayEmpty
                                name='condition'
                                value={filter.condition}
                                onChange={e => onFilterHandler(e, i)}
                                fullWidth
                                size='small'
                                renderValue={v => {
                                    if (!v) return 'Condizione';
                                    return v;
                                }}
                                sx={{ borderRadius: 1.5, border: '2px solid white' }}>
                                {conditions.map(cond => (
                                    <MenuItem value={cond.value} key={cond.name}>
                                        {cond.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {[
                                'status',
                                'print_type',
                                'print_status',
                                'shipping_retire_status',
                                'graphic_request',
                                'graphic_status',
                                'graphic_customer_approval',
                                'graphic_edit_approval',
                            ].includes(filter.field) ? (
                                <Select
                                    displayEmpty
                                    name='Valore'
                                    value={filter.value}
                                    onChange={e => onFilterHandler(e, i)}
                                    fullWidth
                                    renderValue={v => {
                                        if (!v) return 'Value';
                                        return v;
                                    }}
                                    sx={{ borderRadius: 1.5, border: '2px solid white' }}>
                                    {order[filter.field].map(value => (
                                        <MenuItem
                                            value={value}
                                            key={value}
                                            sx={{ textTransform: 'capitalize' }}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            ) : (
                                <InputBase
                                    name='Valore'
                                    placeholder='Valore'
                                    value={filter.value}
                                    size='small'
                                    onChange={e => onFilterHandler(e, i)}
                                    fullWidth
                                    sx={{ borderRadius: 1.5, border: '2px solid white', px: 1 }}
                                />
                            )}
                            <IconButton
                                disabled={i === 0}
                                onClick={() => removeFilter(i)}
                                color='text.tertiary'>
                                <DeleteTwoToneIcon />
                            </IconButton>
                        </Stack>
                    ))}
                </Box>
                <Stack
                    direction='row'
                    p={2}
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ backgroundColor: 'white' }}>
                    <Stack direction='row' spacing={1.5}>
                        <Button
                            variant={options.filter_operator === 'and' ? 'contained' : 'filter'}
                            onClick={() =>
                                setOptions(prev => ({ ...prev, filter_operator: 'and' }))
                            }
                            sx={{ borderRadius: 1.5 }}>
                            E
                        </Button>
                        <Button
                            variant={options.filter_operator === 'or' ? 'contained' : 'filter'}
                            onClick={() => setOptions(prev => ({ ...prev, filter_operator: 'or' }))}
                            sx={{ borderRadius: 1.5 }}>
                            O
                        </Button>
                        <Divider orientation='vertical' flexItem variant='middle' />
                        <Button
                            variant='text'
                            startIcon={<AddIcon />}
                            color='primary'
                            onClick={addFilter}
                            sx={{
                                '&, &:hover': {
                                    boxShadow: 'none',
                                    borderRadius: '0',
                                },
                            }}>
                            Nuovo Filtro
                        </Button>
                    </Stack>
                    <Box>
                        <Button
                            variant='filter'
                            sx={{ minWidth: 120, borderRadius: 1.5, mr: 1 }}
                            onClick={() => {
                                const hasValues = filters.every(
                                    filter => filter.field && filter.condition
                                );
                                if (!hasValues) return;
                                setOptions(prev => ({ ...prev, filter: false }));
                                setFilters([{ field: '', condition: '', value: '' }]);
                                closeFilterMenu();
                            }}>
                            Svuota
                        </Button>
                        <Button
                            variant='contained'
                            sx={{ minWidth: 120, borderRadius: 1.5 }}
                            onClick={() => {
                                setOptions(prev => ({ ...prev, filter: true }));
                                refetch();
                                closeFilterMenu();
                            }}>
                            Crea
                        </Button>
                    </Box>
                </Stack>
            </Menu>
        </>
    );
};

export default Index;
