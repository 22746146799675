import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid from '../../components/DataGrid';
import axios from 'axios';
import { DateTime } from 'luxon';
import { setCustomer } from '../../features/customer/customerSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useErrorHandler from '../../hooks/useErrorHandler';
import { order } from '../../data/choices';
import {customer_visual_name} from '../../utils/function';
import SelectTable from '../../components/SelectTable';
import { useMessage } from '../../layouts/Header';


function OrderTable(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const { showSuccess } = useMessage();

    const {orders, refetch, isLoading, rowCount} = props;

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });

    const getCustomer = useCallback(
        async (customerId, orderId) => {
            try {
                const response = await axios.get(`/customers/${customerId}/`);
                dispatch(setCustomer(response.data));
                navigate(`/dashboard/order/preview/${orderId}`);
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, dispatch, navigate]
    );

    const alterOrder = useCallback(
        async (id, options) => {
            try {
                await axios.patch(`/orders/${id}/`, { [options.field]: options.value });

                showSuccess(`${options.field} cambiato in  ${options.value}`);
                refetch();
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, refetch, showSuccess]
    );

    const columns = useMemo(
        () => [
            {
                field: 'n_order',
                headerName: 'N° Ordine',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 100,
            },
            {
                field: 'date',
                headerName: 'Data',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 150,
                renderCell: params =>
                    params.row.date
                        ? DateTime.fromISO(params.row.date).toFormat('dd/MM/yyyy hh:mm')
                        : null,
            },
            {
                field: 'customer',
                headerName: 'Cliente',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params =>
                    customer_visual_name(params.row.customer)
            },
            {
                field: 'status',
                headerName: 'Stato Ordine',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='status' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'site',
                headerName: 'Sito',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 250,
                renderCell: params =>
                    params.row.site?.name
            },
            {
                field: 'quantity',
                headerName: 'Quantità',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 150,
            },
            {
                field: 'shipping_type',
                headerName: 'Spedizione - Ritiro',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='shipping_type' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'work_time_type',
                headerName: 'Tipo Lavorazione Richiesta',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 230,
                renderCell: params => (
                    <SelectTable field='work_time_type' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'scadenza',
                headerName: 'Scadenza',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 150,
                renderCell: params =>
                    params.row.expiry
                        ? DateTime.fromISO(params.row.expiry).toFormat('dd/MM/yyyy')
                        : null,
            },
            {
                field: 'print_type',
                headerName: 'Tipo di Stampa',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='print_type' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'print_status',
                headerName: 'Stato Lavorazione',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='print_status' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'shipping_retire_status',
                headerName: 'Stato Ritiro/Spedizione',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='shipping_retire_status' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'payment_status',
                headerName: 'Stato Pagamento',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='payment_status' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'payment_type',
                headerName: 'Metodo Pagamento',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='payment_type' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'invoice',
                headerName: 'Ricevuta/Fattura',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='invoice' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'total',
                headerName: 'TOTALE',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 150,
                renderCell: params =>
                    params.row.total
                        ? params.row.total + ' €'
                        : null,
            },
            {
                field: 'payment_1_tranche',
                headerName: '1^ Trance Pagamento',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params =>
                    params.row.payment_1_tranche > 0
                        ? params.row.payment_1_tranche + ' €'
                        : null,
            },
            {
                field: 'payment_2_tranche',
                headerName: '2^ Trance Pagamento',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params =>
                    params.row.payment_2_tranche > 0
                        ? params.row.payment_2_tranche + ' €'
                        : null,
            },
            {
                field: 'creation_origin',
                headerName: 'Origine Ordine',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='creation_origin' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            {
                field: 'graphic_request',
                headerName: 'Richiesta Grafico',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <SelectTable field='graphic_request' changeFunction={alterOrder} params={params} items={order}/>
                ),
            },
            // {
            //     field: 'graphic_customer_approval',
            //     headerName: 'graphic customer approval',
            //     sortable: false,
            //     headerAlign: 'center',
            //     align: 'center',
            //     width: 250,
            //     renderCell: params => (
            //         <Select
            //             value={params.row.graphic_customer_approval}
            //             size='small'
            //             fullWidth
            //             onChange={e =>
            //                 alterOrder(params.id, {
            //                     field: 'graphic_customer_approval',
            //                     value: e.target.value,
            //                 })
            //             }>
            //             {order.graphic_customer_approval.map(value => (
            //                 <MenuItem value={value} sx={{ textTransform: 'capitalize' }}>
            //                     {value}
            //                 </MenuItem>
            //             ))}
            //         </Select>
            //     ),
            // },
            // {
            //     field: 'graphic_edit_approval',
            //     headerName: 'graphic edit approval',
            //     sortable: false,
            //     headerAlign: 'center',
            //     align: 'center',
            //     width: 250,
            //     renderCell: params => (
            //         <Select
            //             value={params.row.graphic_edit_approval}
            //             size='small'
            //             fullWidth
            //             onChange={e =>
            //                 alterOrder(params.id, {
            //                     field: 'graphic_edit_approval',
            //                     value: e.target.value,
            //                 })
            //             }>
            //             {order.graphic_edit_approval.map(value => (
            //                 <MenuItem value={value} sx={{ textTransform: 'capitalize' }}>
            //                     {value}
            //                 </MenuItem>
            //             ))}
            //         </Select>
            //     ),
            // },
        ],
        [alterOrder]
    );
    return (
        <DataGrid
            rows={orders}
            columns={columns}
            autoHeight
            onRowClick={async params => {
                await getCustomer(params.row.customer.id, params.id);
            }}
            rowSelection={false}
            disableSelectionOnClick
            loading={isLoading}
            rowCount={rowCount}
            checkboxSelection={false}
            pageSizeOptions={[40]}
            paginationModel={paginationModel}
            paginationMode='server'
            onPaginationModelChange={setPaginationModel}
        />
    );
}

export default OrderTable;
