import { Button, Card, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Form, Submit, useForm } from '../../hooks/useForm';
import { Input } from '../../hooks/useForm/inputs/index';
import CustomInput from '../../components/Input/input';
import useErrorHandler from '../../hooks/useErrorHandler';
import { useMessage } from '../../layouts/Header';

const Refund = props => {
    const { closeModal, orderId, refetch } = props;
    const errorHandler = useErrorHandler();
    const { showError, showSuccess } = useMessage();

    const handlers = useForm(
        useMemo(
            () => ({
                reason: { required: true },
                note: { required: true },
                amount: { required: true },
            }),
            []
        ),
        { Input: CustomInput }
    );

    const onSubmit = res => {
        closeModal();
        refetch();
        if (res.data) return showSuccess('Refund request generated successfully');
        showError('Unable to update the generate refund request at the moment');
    };

    return (
        <Card
            sx={{
                p: 2.5,
                bgcolor: 'background.default',
                borderRadius: 2,
                maxWidth: '895px',
                width: '100%',
            }}>
            <Form
                handlers={handlers}
                onSubmit={onSubmit}
                action={`/orders/${orderId}/refund/`}
                method={'post'}
                onError={errorHandler}>
                <Typography variant='subtitle1' fontWeight={500} mb={2}>
                   Crea Rimborso
                </Typography>

                <Input label='Valore' name='amount' type='number' />

                <Input label='Note' name='note' />
                <Input label='Motivo' name='reason' />

                <Stack direction='row' gap={2} justifyContent='flex-end' mt={2}>
                    <Button variant='text' onClick={closeModal}>
                        Cancel
                    </Button>
                    <Submit>
                        {loader => (
                            <Button
                                variant='contained'
                                type='submit'
                                disabled={Boolean(loader)}
                                endIcon={loader}>
                                Submit
                            </Button>
                        )}
                    </Submit>
                </Stack>
            </Form>
        </Card>
    );
};

export default Refund;
