import { Box, Button, Grid, IconButton, Link, Modal, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ShadowCard from '../../components/ShadowCard';
import { useDispatch, useSelector } from 'react-redux';
import Contact from './Contact';
import { isEmpty } from '../../utils/function';
import OutsetBox from '../../components/OutsetBox';
import useModal from '../../hooks/useModal';
import Address from './Address';
import Orders from './Orders';
import Quotes from './Quotes';
import Edit from '@mui/icons-material/Edit';
import useErrorHandler from '../../hooks/useErrorHandler';
import axios from 'axios';
import { useMessage } from '../../layouts/Header';
import SecondaryContact from './SecondaryContact';
import { setCustomer } from '../../features/customer/customerSlice';
import { customer_translate} from '../../data/choices';


const Index = () => {
    const customer = useSelector(state => state.customer.customer);
    const { modalState, openModal, closeModal } = useModal();
    const { showSuccess } = useMessage();
    const errorHandler = useErrorHandler();
    const {
        modalState: contactState,
        openModal: openContactEdit,
        closeModal: closeContactEdit,
    } = useModal();
    const {
        modalState: secondaryContactState,
        openModal: openSecondaryContactModal,
        closeModal: closeSecondaryContactModal,
    } = useModal();
    const {
        modalState: deleteSecondaryState,
        openModal: openDeleteSecondaryModal,
        closeModal: closeDeleteSecondaryModal,
    } = useModal();
    const dispatch = useDispatch();
    const [address, setAddress] = useState({});
    const [secondaryContact, setSecondaryContact] = useState({});

    console.log({ customer });

    const sendNewAccountEmail = async () => {
        try {
            await axios.post(`/customers/${customer.id}/create_account_site/`, {
                email: customer.email,
            });
            showSuccess('New account request sent successfully');
        } catch (e) {
            errorHandler(e);
        }
    };

    const sendPasswordResetEmail = async email => {
        try {
            await axios.post(`/customers/${customer.id}/password_reset_site/`, { email });
            showSuccess('Password reset requset sent successfully');
        } catch (e) {
            errorHandler(e);
        }
    };

    const deleteSecondaryContact = async () => {
        closeDeleteSecondaryModal();
        try {
            await axios.delete(`/customers/${secondaryContact.id}/`);

            const secondary = [...customer.secondary];
            secondary.splice(secondaryContact.index, 1);
            dispatch(setCustomer({ ...customer, secondary }));

            showSuccess('Contact deleted!');
        } catch (e) {
            errorHandler(e);
        }
    };

    return (
        <>
            <ShadowCard mt={6}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' mb={2}>
                    <Typography variant='subtitle1' fontWeight={500}>
                        CLIENTE (CONTATTI)
                    </Typography>

                    <IconButton color='primary' onClick={openContactEdit}>
                        <Edit fontSize='small' />
                    </IconButton>
                </Stack>

                <Grid container columnSpacing={4}>
                    <Grid item xs={12} sm={3}>
                        <OutsetBox mt={1} label='Nome' value={customer.first_name} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <OutsetBox mt={1} label='Cognome' value={customer.last_name} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <OutsetBox mt={1} label='Username' value={customer.username} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <OutsetBox mt={1} label='Email' value={customer.email} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <OutsetBox mt={1} label='Ragione Sociale' value={customer.company} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <OutsetBox mt={1} label='Partita IVA' value='vat' />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <OutsetBox mt={1} label='Telefono' value={customer.phone} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <OutsetBox mt={1} label='Tipo Cliente' value={customer_translate.customer_type[customer.customer_type]} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <OutsetBox mt={1} label='Priorità' value={customer_translate.priority[customer.priority]} />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <OutsetBox mt={1} label='Note' value={customer.note} />
                    </Grid>
                </Grid>
            </ShadowCard>

            <ShadowCard mt={6}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' mb={2}>
                    <Typography variant='subtitle1' fontWeight={500}>
                        ACCOUNT SITO
                    </Typography>
                </Stack>

                {customer.site.map(site => (
                    <Grid container columnSpacing={4} key={site.name} mb={2}>
                        <Grid item xs={12} sm={4}>
                            <OutsetBox mt={1} label='Site' value={site.name} sx={{ mb: 2 }} />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            sx={{ display: site.username_wp ? 'block' : 'none' }}>
                            <OutsetBox
                                mt={1}
                                label='Username'
                                value={site.username_wp}
                                sx={{ mb: 2 }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            sx={{ display: site.email_wp ? 'block' : 'none' }}>
                            <OutsetBox mt={1} label='Email' value={site.email_wp} sx={{ mb: 2 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                onClick={sendNewAccountEmail}
                                sx={{ mr: 1, display: !site.email_wp ? 'inline-flex' : 'none' }}>
                                Invia email creazione account
                            </Button>
                            <Button
                                variant='contained'
                                sx={{ display: site.email_wp ? 'inline-flex' : 'none', mt: 3 }}
                                onClick={() => sendPasswordResetEmail(site.email_wp)}>
                                Invia email di reset password
                            </Button>
                        </Grid>
                    </Grid>
                ))}
            </ShadowCard>

            <ShadowCard mt={6}>
                <Typography variant='subtitle1' fontWeight={500} mb={2}>
                    INDIRIZZI DI SPEDIZIONE
                </Typography>

                {isEmpty(customer)
                    ? null
                    : customer.shipping?.map((address, i) => (
                          <React.Fragment key={i}>
                              <Typography variant='body1' fontWeight={500} my={2}>
                                  Address {i + 1}{' '}
                                  <Link
                                      onClick={() => {
                                          setAddress({
                                              ...address,
                                              index: i,
                                              type: 'shipping',
                                              action: 'edit',
                                          });
                                          openModal();
                                      }}
                                      sx={{ cursor: 'pointer', fontWeight: 400, fontSize: 12 }}>
                                      Edit
                                  </Link>
                              </Typography>
                              <Grid container columnSpacing={4}>
                                  <Grid item xs={12} sm={4}>
                                      <OutsetBox
                                          mt={1}
                                          label='Nome'
                                          value={address.first_name}
                                      />
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                      <OutsetBox
                                          mt={1}
                                          label='Cognome'
                                          value={address.last_name}
                                      />
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                        <OutsetBox mt={1} label='Ragione Sociale' value={address.company} />
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                        <OutsetBox mt={1} label='Indirizzo1' value={address.address_1} />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                        <OutsetBox mt={1} label='Indirizzo2' value={address.address_2} />
                                  </Grid>

                                  <Grid item xs={12} sm={3}>
                                      <OutsetBox mt={1} label='Stato' value={address.country} />
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                      <OutsetBox mt={1} label='Provincia' value={address.state} />
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                      <OutsetBox mt={1} label='Città' value={address.city} />
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                      <OutsetBox
                                          mt={1}
                                          label='Codice Postale'
                                          value={address.postcode}
                                      />
                                  </Grid>
                              </Grid>
                          </React.Fragment>
                      ))}

                <IconButton
                    onClick={() => {
                        setAddress({ action: 'new', type: 'shipping' });
                        openModal();
                    }}>
                    <AddCircleIcon />
                </IconButton>
            </ShadowCard>
            <ShadowCard mt={6}>
                <Typography variant='subtitle1' fontWeight={500} mb={2}>
                    INDIRIZZI DI FATTURAZIONE
                </Typography>

                {isEmpty(customer)
                    ? null
                    : customer.billing?.map((address, i) => (
                          <React.Fragment key={i}>
                              <Typography variant='body1' fontWeight={500} my={2}>
                                  Address {i + 1}{' '}
                                  <Link
                                      onClick={() => {
                                          setAddress({
                                              ...address,
                                              index: i,
                                              type: 'billing',
                                              action: 'edit',
                                          });
                                          openModal();
                                      }}
                                      sx={{ cursor: 'pointer', fontWeight: 400, fontSize: 12 }}>
                                      Edit
                                  </Link>
                              </Typography>
                              <Grid container columnSpacing={4}>
                                  <Grid item xs={12} sm={3}>
                                      <OutsetBox
                                          mt={1}
                                          label='Nome'
                                          value={address.first_name}
                                      />
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                      <OutsetBox
                                          mt={1}
                                          label='Cognome'
                                          value={address.last_name}
                                      />
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                      <OutsetBox
                                          mt={1}
                                          label='Ragione Sociale'
                                          value={address.company}
                                      />
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                      <OutsetBox mt={1} label='Partita IVA' value={address.vat} />
                                  </Grid>

                                    <Grid item xs={12} sm={6}>
                                            <OutsetBox mt={1} label='Indirizzo1' value={address.address_1} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                            <OutsetBox mt={1} label='Indirizzo2' value={address.address_2} />
                                    </Grid>

                                  <Grid item xs={12} sm={3}>
                                      <OutsetBox mt={1} label='Stato' value={address.country} />
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                      <OutsetBox mt={1} label='Provincia' value={address.state} />
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                      <OutsetBox mt={1} label='Città' value={address.city} />
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                      <OutsetBox
                                          mt={1}
                                          label='Codice Postale'
                                          value={address.postcode}
                                      />
                                  </Grid>
                              </Grid>
                          </React.Fragment>
                      ))}

                <IconButton
                    onClick={() => {
                        setAddress({ action: 'new', type: 'billing' });
                        openModal();
                    }}>
                    <AddCircleIcon />
                </IconButton>
            </ShadowCard>

            <ShadowCard mt={6}>
                <Typography variant='subtitle1' fontWeight={500} mb={3}>
                    CONTATTI SECONDARI
                </Typography>

                {customer.secondary.map((contact, i) => (
                    <React.Fragment key={i}>
                        <Typography variant='body1' fontWeight={500} my={1.7}>
                            Contact {i + 1}{' '}
                            <Link
                                onClick={() => {
                                    setSecondaryContact({
                                        ...contact,
                                        index: i,
                                        action: 'edit',
                                    });
                                    openSecondaryContactModal();
                                }}
                                sx={{ cursor: 'pointer', fontWeight: 400, fontSize: 12, mx: 1 }}>
                                Edit
                            </Link>
                            <Link
                                onClick={() => {
                                    setSecondaryContact({
                                        ...contact,
                                        index: i,
                                    });
                                    openDeleteSecondaryModal();
                                }}
                                sx={{ cursor: 'pointer', fontWeight: 400, fontSize: 12 }}>
                                Delete
                            </Link>
                        </Typography>

                        <Grid container columnSpacing={4} key={contact.id}>
                            <Grid item xs={12} sm={3}>
                                <OutsetBox mt={1} label='Nome' value={contact.first_name} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <OutsetBox mt={1} label='Cognome' value={contact.last_name} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <OutsetBox
                                    mt={1}
                                    label={contact.email ? 'Email' : 'Telefono'}
                                    value={contact.email || contact.phone}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <OutsetBox mt={1} label='Username' value={contact.username} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <OutsetBox mt={1} label='Note' value={contact.note} />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ))}
                <IconButton
                    onClick={() => {
                        setSecondaryContact({ action: 'new' });
                        openSecondaryContactModal();
                    }}>
                    <AddCircleIcon />
                </IconButton>
            </ShadowCard>

            <Quotes />
            <Orders />

            <Modal
                open={modalState}
                onClose={closeModal}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <>
                    <Address address={address} closeModal={closeModal} />
                </>
            </Modal>

            <Modal
                open={contactState}
                onClose={closeContactEdit}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <>
                    <Contact closeModal={closeContactEdit} />
                </>
            </Modal>
            <Modal
                open={secondaryContactState}
                onClose={closeSecondaryContactModal}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <>
                    <SecondaryContact
                        closeModal={closeSecondaryContactModal}
                        contact={secondaryContact}
                    />
                </>
            </Modal>

            <Modal
                open={deleteSecondaryState}
                onClose={closeDeleteSecondaryModal}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box
                    sx={{
                        width: 'min(100%, 510px)',
                        boxShadow:
                            'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
                        border: '1px solid',
                        borderColor: 'white',
                        backdropFilter: 'blur(6px)',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        borderRadius: '8px',
                        p: 2,
                    }}>
                    <Typography variant='subtitle1'>
                        Delete Contact {secondaryContact.index + 1} ?
                    </Typography>

                    <Box mt={3} sx={{ float: 'right' }}>
                        <Button variant='text' sx={{ mr: 1.5 }} onClick={closeDeleteSecondaryModal}>
                            Cancel
                        </Button>
                        <Button variant='contained' onClick={deleteSecondaryContact}>
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default Index;
