import React, { memo } from 'react';
import { Link, NavLink } from 'react-router-dom';

//mui component
import {
    AppBar,
    Box,
    Drawer,
    Grid,
    Toolbar,
    ListItemButton,
    Menu,
    IconButton,
    useMediaQuery,
} from '@mui/material';

//services
import Sidebar from './Sidebar';
import SidePanel from './SidePanel';
import { useSelector } from 'react-redux';
import ListIcon from '@mui/icons-material/List';
import { useMenu } from '../hooks/useMenu';
import { Lists } from '../data/sidebar';

const sidebarWidth = 85;

function Navbar(props) {
    const { children } = props;
    const sidePanelWidth = useSelector(state => state.sidepanel.width);
    const sidePanel = useSelector(state => state.sidepanel.open);
    const customer = useSelector(state => state.customer.customer);
    const xm = useMediaQuery('(max-width:1024px)');

    const {
        anchorEl: anchorElAction,
        openMenu: openActionMenu,
        closeMenu: closeActionMenu,
    } = useMenu();

    return (
        <Box
            sx={{
                bgcolor: 'background.default',
                height: '100dvh',
                position: 'relative',
                overflowX: 'hidden',
            }}>
            <AppBar
                elevation={0}
                component={Box}
                position='sticky'
                open={sidePanel}
                sx={{
                    ...(sidePanel
                        ? {
                              width: `calc(100% - ${sidePanelWidth}px - ${sidebarWidth}px)`,
                              marginRight: `${sidePanelWidth}px`,
                          }
                        : { width: `calc(100% - ${sidebarWidth}px)` }),

                    ml: `${sidebarWidth}px`,
                    backgroundColor: 'background.default',
                    borderBottom: '1px solid custom.border',
                    color: 'text.primary',
                    transition: 'width 225ms, background-color 0s',
                }}>
                <Toolbar
                    sx={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        position: 'relative',
                        '&': {
                            minHeight: '64px',
                            py: 2,
                            px: 3,
                        },
                    }}>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            '& .MuiButtonBase-root,MuiListItemButton-root': {
                                borderRadius: '10px',
                                py: 1.2,
                                px: 0,
                                fontWeight: 500,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                textWrap: 'nowrap',
                                textAlign: 'center',
                                '&:hover': {
                                    bgcolor: 'primary.main',
                                    color: 'white',
                                },
                            },
                        }}>
                        {xm || sidePanelWidth > 450 ? (
                            <Grid item xs display='flex' justifyContent='flex-end'>
                                <IconButton
                                    onClick={openActionMenu}
                                    disabled={!Boolean(customer.id)}>
                                    <ListIcon fontSize='large' />
                                </IconButton>
                            </Grid>
                        ) : (
                            Lists.map(list => (
                                <Grid item xs key={list.to}>
                                    <NavLink
                                        to={
                                            list.to === '/dashboard/similar-customer'
                                                ? `${list.to}/${customer.id}`
                                                : list.to
                                        }
                                        end
                                        style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                            pointerEvents:
                                                list.to === '/dashboard/similar-customer' &&
                                                !customer.id
                                                    ? 'none'
                                                    : 'auto',
                                        }}>
                                        {({ isActive }) => (
                                            <ListItemButton
                                                variant='text'
                                                sx={{
                                                    bgcolor: isActive ? 'primary.main' : 'inherit',
                                                    color: isActive ? 'white' : 'inherit',
                                                }}>
                                                {list.name}
                                            </ListItemButton>
                                        )}
                                    </NavLink>
                                </Grid>
                            ))
                        )}
                    </Grid>
                </Toolbar>
            </AppBar>

            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

            <Drawer
                variant='permanent'
                sx={{
                    p: 0,
                    '& .MuiDrawer-paper': {
                        bgcolor: 'background.default',
                        boxShadow: 'none',
                        borderRight: '1px solid',
                        borderColor: 'background.paper',
                        width: sidebarWidth,
                        transition: 'width 225ms',
                    },
                }}>
                <Sidebar />
            </Drawer>

            <Drawer
                variant='persistent'
                open={sidePanel}
                anchor='right'
                sx={{
                    display: { xs: 'none', xm: 'block' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: sidePanelWidth,
                        bgcolor: 'background.default',
                        overflow: 'unset',
                        borderLeft: '1px solid',
                        borderColor: 'white',
                    },
                }}>
                <SidePanel />
            </Drawer>

            <Box
                component='main'
                sx={{
                    ...(sidePanel
                        ? {
                              width: `calc(100% - ${sidePanelWidth}px - ${sidebarWidth}px)`,
                              marginRight: `${sidePanelWidth}px`,
                          }
                        : {
                              width: `calc(100% - ${sidebarWidth}px)`,
                          }),
                    ml: `${sidebarWidth}px`,
                    height: 'calc(100% - 80px)',
                    overflow: 'auto',
                    transition: 'width 225ms, margin 225ms',
                }}>
                {children}
            </Box>

            <Menu
                anchorEl={anchorElAction}
                open={Boolean(anchorElAction)}
                onClose={closeActionMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                type='glassMorphism'
                sx={{
                    '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                        width: 'min(100%, 340px)',
                        fontSize: 14,
                    },
                    '& .MuiButtonBase-root:hover': {
                        backgroundColor: 'primary.main',
                        color: 'white',
                        '& .MuiTypography-root, .MuiSvgIcon-root': {
                            color: 'white',
                        },
                    },
                }}>
                {Lists.map(list => (
                    <ListItemButton
                        LinkComponent={Link}
                        to={
                            list.to === '/dashboard/similar-customer'
                                ? `${list.to}/${customer.id}`
                                : list.to
                        }
                        end
                        sx={{
                            pointerEvents:
                                list.to === '/dashboard/similar-customer' && !customer.id
                                    ? 'none'
                                    : 'auto',
                        }}
                        key={list.to}>
                        {list.name}
                    </ListItemButton>
                ))}
            </Menu>
        </Box>
    );
}

export default memo(Navbar);
