import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: '',
    files: [],
    redirect: { id: 0, page: 0 },
    draft: '',
};

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        setMessage: (state, action) => {
            state.value = action.payload;
        },
        setFiles: (state, action) => {
            state.files = action.payload;
        },
        setRedirect: (state, action) => {
            state.redirect = action.payload;
        },
        setDraft: (state, action) => {
            state.draft = action.payload;
        },
    },
});

export const { setMessage, setFiles, setRedirect, setDraft } = messageSlice.actions;

export default messageSlice.reducer;
