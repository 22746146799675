import {
    Button,
    Card,
    FormHelperText,
    Grid,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { Form, Submit, useForm } from '../../hooks/useForm';
import { Input } from '../../hooks/useForm/inputs/index';
import CustomInput from '../../components/Input/input';
import { useMessage } from '../../layouts/Header';
import useErrorHandler from '../../hooks/useErrorHandler';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomer } from '../../features/customer/customerSlice';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const SecondaryContact = props => {
    const { contact, closeModal } = props;
    const { showSuccess, showError } = useMessage();
    const errorHandler = useErrorHandler();
    const dispatch = useDispatch();
    const customer = useSelector(state => state.customer.customer);

    const handlers = useForm(
        useMemo(
            () => ({
                first_name: { value: contact.first_name },
                last_name: { value: contact.last_name },
                username: { value: contact.username },
                email: { value: contact.email },
                phone: { value: contact.phone },
                note: { value: contact.note },
                ...(contact.action === 'new' ? { site: { required: true } } : {}),
            }),
            [contact]
        ),
        { Input: CustomInput }
    );

    const values = handlers.values;
    const setValues = handlers.setValues;
    const errors = handlers.errors;

    const customChangeHandler = e => {
        const { name, value } = e.target;
        setValues({ [name]: value });
    };

    const onSubmit = res => {
        if (res.data) {
            const secondary = [...customer.secondary];
            closeModal();
            const updatedData = {
                first_name: res.data.first_name,
                last_name: res.data.last_name,
                username: res.data.username,
                email: res.data.email,
                phone: res.data.phone,
                note: res.data.note,
            };

            if (contact.action === 'edit') {
                secondary.splice(contact.index, 1, updatedData);
                dispatch(setCustomer({ ...customer, secondary }));
            }
            // else {
            //     dispatch(setCustomer({ ...customer, secondary: [...secondary, updatedData] }));
            // }

            return showSuccess('Secondary Contact saved successfully');
        }

        showError('Unable to update the customer at the moment');
    };

    const { data } = useQuery({
        queryKey: ['sites'],
        queryFn: async () => {
            const { data } = await axios.get(`/sites/`);
            return data;
        },
        enabled: contact.action === 'new',
    });

    const sites = useMemo(() => (data ? data.results : []), [data]);

    return (
        <Card
            sx={{
                p: 2.5,
                bgcolor: 'background.default',
                borderRadius: 2,
                maxWidth: '895px',
                width: '100%',
            }}>
            <Form
                handlers={handlers}
                onSubmit={onSubmit}
                action={contact.action === 'edit' ? `/customers/${contact.id}/` : '/customers/'}
                method={contact.action === 'edit' ? `PATCH` : 'POST'}
                onError={errorHandler}
                final={v => ({ ...v, site: [v.site] })}>
                <Typography variant='subtitle1' fontWeight={500} mb={2}>
                    {contact.action === 'new' ? 'ADD NEW ' : null} SECONDARY CONTACT{' '}
                    {contact.action === 'edit' ? contact.index + 1 : null}
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Input label='Nome' name='first_name' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input label='Cognome' name='last_name' />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                        <Input label='Email' name='email' />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Input label='Username' name='username' />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Input label='Telefono' name='phone' />
                </Grid>
                <Grid item xs={12}>
                    <Input label='Note' name='note' />
                </Grid>

                {contact.action === 'new' ? (
                    <Grid item xs={12}>
                        <Select
                            fullWidth
                            displayEmpty
                            value={values.site}
                            name='site'
                            onChange={customChangeHandler}
                            isError={Boolean(errors.site)}
                            error={errors.site}
                            sx={{ py: 1 }}>
                            <MenuItem disabled={true} value=''>
                                Sites
                            </MenuItem>
                            {sites.map(site => (
                                <MenuItem key={site} value={site.id}>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 14,
                                            color: 'text.secondary',
                                        }}>
                                        {site.name}
                                    </ListItemText>
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error={errors.site} sx={{ ml: 2 }}>
                            {errors.site}
                        </FormHelperText>
                    </Grid>
                ) : null}

                <Stack direction='row' gap={2} justifyContent='flex-end' mt={2}>
                    <Button variant='text' onClick={closeModal}>
                        Cancel
                    </Button>
                    <Submit>
                        {loader => (
                            <Button
                                variant='contained'
                                type='submit'
                                disabled={Boolean(loader)}
                                endIcon={loader}>
                                {contact.action === 'new' ? 'Add' : 'Update'}
                            </Button>
                        )}
                    </Submit>
                </Stack>
            </Form>
        </Card>
    );
};

export default SecondaryContact;
