import { FormHelperText, InputBase, Stack, Typography, styled } from '@mui/material';
import React from 'react';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& input': {
        color: theme.palette.text.primary,
        padding: theme.spacing(0),
        fontSize: '0.875rem',
        fontWeight: '400',
        letterSpacing: '0.01071em',
        marginInline: theme.spacing(1),
        transition: theme.transitions.create('width'),
        width: '100%',
        '&.Mui-disabled': {
            opacity: '1',
            WebkitTextFillColor: 'unset',
        },
    },
}));

const Input = props => {
    const { sx, label, value = '', onChange, inset, helperText, ...rest } = props;

    return (
        <Stack
            direction='row'
            alignContent='center'
            sx={{
                width: '100%',
                maxWidth: '100%',
                p: 0.8,
                alignItems: 'center',
                borderRadius: '500px',
                backgroundColor: 'background.default',
                boxShadow: inset
                    ? 'inset 5px 5px 5px #d2d4d685, inset -5px -5px 5px #ffffff82'
                    : '5px 5px 5px #d2d4d685, -5px -5px 5px #ffffff82',
                mb: 2,
                border: '1px solid white',
                ...sx,
            }}>
            {label && (
                <Typography variant='body2' fontWeight={400} color='text.primary' pt={0.2} pl={0.5}>
                    {label}:
                </Typography>
            )}

            <StyledInputBase sx={{ flex: 1 }} value={value} onChange={onChange} {...rest} />

            {helperText && (
                <FormHelperText
                    error={Boolean(helperText)}
                    sx={{
                        mr: 2,
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        textWrap: 'nowrap',
                    }}>
                    {helperText}
                </FormHelperText>
            )}
        </Stack>
    );
};

export default Input;
