import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import DataGrid from '../../components/DataGrid';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../features/messages/messageSlice';
import { useMessage } from '../../layouts/Header';

const TrackingOrder = ({ closeModal }) => {
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });
    const [rowCount, setRowCount] = useState(0);
    const dispatch = useDispatch();
    const message = useSelector(state => state.message.value);
    const customer = useSelector(state => state.customer.customer);
    const { showError } = useMessage();

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ['orders', customer.id, paginationModel.page],
        queryFn: async ({ queryKey }) => {
            const params = {
                page: Boolean(paginationModel.page) ? paginationModel.page + 1 : 1,
                customer__id: customer.id,
            };

            const response = await axios.get('/orders/', { params });
            setRowCount(response.data.count);
            console.log(response);
            return response.data;
        },
    }); // Bugfix page size

    const orders = useMemo(() => (data ? data.results : []), [data]);

    useEffect(() => {
        setRowCount(prev => (data?.count !== undefined ? data?.count : prev));
    }, [data?.count, setRowCount]);

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: 'Id',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 100,
            },
            {
                field: 'site',
                headerName: 'Site',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 150,
            },
            {
                field: 'customer',
                headerName: 'Customer',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
            },
            {
                field: 'status',
                headerName: 'Status',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 250,
            },

            {
                field: 'subtotal',
                headerName: 'Subtotal',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 150,
            },

            {
                field: 'total',
                headerName: 'TOTAL',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 150,
            },
            {
                headerName: 'ACTION',

                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 150,
                renderCell: params => {
                    return (
                        <Button
                            onClick={() => {
                                closeModal();
                                params.row.shipping_tracking_link
                                    ? dispatch(
                                          setMessage(
                                              message
                                                  ? message +
                                                        ' ' +
                                                        params.row.shipping_tracking_link
                                                  : params.row.shipping_tracking_link
                                          )
                                      )
                                    : showError(
                                          'The shipping tracking link is null or not defined in a row'
                                      );
                            }}
                            variant='outlined'
                            startIcon={
                                <SendOutlinedIcon
                                    fontSize='small'
                                    sx={{ color: 'primary.main', transform: 'rotate(-40deg)' }}
                                />
                            }>
                            Send
                        </Button>
                    );
                },
            },
        ],
        [closeModal, dispatch, message, showError]
    );

    if (isError) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}>
                <Typography variant='h5' sx={{ color: 'error.main' }}>
                    {error.message}
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    p: 2,
                    maxWidth: '1200px',
                    width: '100%',
                    bgcolor: 'background.default',
                    borderRadius: '5px',
                    maxHeight: '85vh',
                    overflowY: 'auto',
                }}>
                <Typography variant='h5'>Ordini</Typography>
                <Divider variant='fullWidth' sx={{ my: 2 }} />

                <DataGrid
                    rows={orders}
                    columns={columns}
                    autoHeight
                    rowSelection={false}
                    disableSelectionOnClick
                    loading={isLoading}
                    rowCount={rowCount}
                    checkboxSelection={false}
                    pageSizeOptions={[40]}
                    paginationModel={paginationModel}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                />
            </Box>
        </>
    );
};

export default TrackingOrder;
