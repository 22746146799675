import { Box, Typography } from '@mui/material';
import React from 'react';
import { RightArrowIcon } from './ArrowIcon';

const Folder = props => {
    const { name, isOpen, onClick } = props;
    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: '0.875rem',
                fontWeight: '400',
                lineHeight: '1.43',
                letterSpacing: '0.01071em',
                padding: '12px 16px',
                borderRadius: '12px',
                borderTopLeftRadius: 0,
                backgroundColor: isOpen ? 'primary.main' : 'background.default',
                color: isOpen ? 'white' : 'text.primary',
                mt: 2.5,
                boxShadow: '5px 5px 5px #d2d4d685,-5px -5px 5px #ffffff82',
                cursor: 'pointer',
                // border: 'none',
                // outline: 'none',
                // borderColor: 'transparent',
            }}
            onClick={onClick}>
            <Box
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: '-12px',
                    width: '40%',
                    height: '12px',
                    boxShadow: '5px -2px 5px #d2d4d685,-5px -5px 5px #ffffff82',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    backgroundColor: isOpen ? 'primary.main' : 'background.default',
                }}
            />
            <Typography variant='body2' textTransform='uppercase'>
                {name}
            </Typography>
            <RightArrowIcon borderWidth='1px' innerPadding='1px' fontSize='18px' color='inherit' />
        </Box>
    );
};

export default Folder;
