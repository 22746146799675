import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Box,
    Divider,
    Grid,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Skeleton,
    Stack,
    Switch,
    Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
// import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import ChatIcon from '@mui/icons-material/Chat';
import GroupIcon from '@mui/icons-material/Group';
import { useDispatch, useSelector } from 'react-redux';
import { isDefined, isEmpty, customer_visual_name } from '../utils/function';
import { Link, Outlet, useParams } from 'react-router-dom';
import { setCustomer } from '../features/customer/customerSlice';
import { LeftArrowIcon } from '../components/ArrowIcon';
import { useMenu } from '../hooks/useMenu';
import OutsetBox from '../components/OutsetBox';
import useErrorHandler from '../hooks/useErrorHandler';
import axios from 'axios';
import SelectWithSearch from '../components/SelectWithSearch';
import Person from '@mui/icons-material/Person';
import Close from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import DetailPanel from '../components/DetailPanel';
import ShadowCard from '../components/ShadowCard';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const Main = () => {
    const id = useParams().id;
    const customer = useSelector(state => state.customer.customer);
    const [expanded, setExpanded] = useState(false);
    const [customers, setCustomers] = useState({});
    const [similarCustomers, setSimilarCustomers] = useState([]);
    const [customerDetail, setCustomerDetail] = useState({});
    const [selectCustomer, setSelectCustomer] = useState(null);

    const {
        anchorEl: anchorElAction,
        openMenu: openActionMenu,
        closeMenu: closeActionMenu,
    } = useMenu();
    const {
        anchorEl: anchorElMerge,
        openMenu: openMergeMenu,
        closeMenu: closeMergeMenu,
    } = useMenu();
    const {
        anchorEl: detailAnchorEl,
        openMenu: openDetailPanel,
        closeMenu: closeDetailPanel,
    } = useMenu();
    const dispatch = useDispatch();
    const errorHandler = useErrorHandler();

    const onChangeHandler = e => {
        const { value } = e.target;
        dispatch(setCustomer(customers[value]));
    };

    const changeCustomerType = async customer_type => {
        try {
            const response = await axios.patch(`/customers/${customer.id}/`, { customer_type });
            dispatch(setCustomer(response.data));
        } catch (err) {
            errorHandler(err);
        }
    };

    const toggleAccordion = () => setExpanded(!expanded);

    const getCustomers = useCallback(
        async search => {
            try {
                const response = await axios.get(`/customers/`, { params: { search } });
                const data = response.data.results;
                const format = {};

                data.forEach(el => {
                    format[el.id] = el;
                });

                setCustomers(format);
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler]
    );

    const getCustomer = useCallback(
        async customerId => {
            try {
                const response = await axios.get(`/customers/${customerId}/`);
                dispatch(setCustomer(response.data));
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, dispatch]
    );

    const getSimilarCustomer = useCallback(
        async customerId => {
            try {
                const response = await axios.get(`/similar-customer/`, {
                    params: { customer: customerId },
                });

                setSimilarCustomers(response.data.results);
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler]
    );

    const removeSimilarCustomerSuggestion = useCallback(
        async id => {
            closeActionMenu();
            try {
                await axios.patch(`/similar-customer/${id}/`, { cancelled: true });
                getSimilarCustomer(customer.id);
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, customer.id, closeActionMenu, getSimilarCustomer]
    );

    const mergeSimilarCustomer = useCallback(
        async option => {
            closeActionMenu();
            try {
                await axios.post(`/similar-customer/merge/`, {
                    from_customer: option.id,
                    to_customer: customer.id,
                    merge_type: option.type,
                });
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, customer.id, closeActionMenu]
    );

    useEffect(() => {
        if (customer.id) getSimilarCustomer(customer.id);
    }, [getSimilarCustomer, customer.id]);

    useEffect(() => {
        if (id) getCustomer(id);
    }, [id, getCustomer]);

    const customerSearch = (
        <SelectWithSearch
            displayEmpty
            name='customer'
            onChange={onChangeHandler}
            value={customer.id}
            fullWidth
            size='small'
            MenuProps={{
                type: 'glassMorphism',
                sx: {
                    '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                        width: 'min(100%, 710px)',
                        boxShadow:
                            'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
                        border: '1px solid',
                        borderColor: 'white',
                        borderRadius: '8px',
                        p: 0,
                        overflowY: 'unset',
                    },
                },
            }}
            sx={{ width: '269px' }}
            renderValue={v => {
                if (!v) return 'Seleziona cliente';
                return isDefined(customers[v])
                    ? customers[v].first_name + ' ' + customers[v].last_name
                    : customer_visual_name(customer);
            }}
            SearchProps={{
                onChange: e => getCustomers(e.target.value),
            }}>
            {Object.keys(customers).map((key, i) => (
                <MenuItem value={key} key={i} selected={false} sx={{ px: 1 }}>
                    <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='space-between'
                        width='100%'>
                        <Stack direction='row' spacing={1}>
                            <Person fontSize='small' />
                            <Typography variant='body1' fontWeight={500} alignItems='center'>
                                {customers[key].first_name && customers[key].last_name
                                    ? customers[key].first_name + ' ' + customers[key].last_name
                                    : 'Customer'}
                                <Typography
                                    variant='body2'
                                    color='text.secondary'
                                    display='inline-block'
                                    ml={2}>
                                    {customers[key].phone}
                                </Typography>
                            </Typography>
                        </Stack>

                        <Typography
                            variant='body2'
                            color='text.secondary'
                            display='inline-flex'
                            alignItems='center'>
                            {customers[key].email}
                            <IconButton
                                sx={{
                                    ml: 1,
                                    p: 0.1,
                                    display: customer.id === parseInt(key) ? 'none' : 'inline-flex',
                                }}
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    openMergeMenu(e);
                                    setSelectCustomer(key);
                                }}>
                                <MoreVertIcon fontSize='small' />
                            </IconButton>
                        </Typography>
                    </Stack>
                </MenuItem>
            ))}
        </SelectWithSearch>
    );

    return (
        <Box height='100%' display='flex' flexDirection='column'>
            <Box
                flexGrow={1}
                sx={{
                    overflowY: 'auto',
                }}>
                <Box px={3} pb={3} pt={2}>
                    {customer.id ? (
                        <Box position='relative'>
                            <Accordion
                                slotProps={{ transition: { unmountOnExit: true } }}
                                expanded={expanded}
                                onChange={toggleAccordion}
                                onClick={() => {
                                    if (expanded) return setExpanded(true);
                                    setExpanded(false);
                                }}
                                sx={{
                                    px: 3,
                                    '&.MuiAccordion-root:before': {
                                        backgroundColor: 'transparent',
                                    },
                                }}>
                                <AccordionSummary>
                                    <Grid
                                        container
                                        rowSpacing={1.5}
                                        bgcolor='background.default'
                                        justifyContent='space-between'
                                        alignItems='center'>
                                        <Grid item>
                                            {isEmpty(customer) ? (
                                                <React.Fragment>
                                                    <Skeleton
                                                        variant='text'
                                                        width={130}
                                                        height={14}
                                                    />
                                                    <Divider
                                                        sx={{
                                                            mb: 0.8,
                                                        }}
                                                    />

                                                    <Skeleton
                                                        variant='text'
                                                        width={140}
                                                        height={16}
                                                    />
                                                    <Skeleton
                                                        variant='text'
                                                        width={130}
                                                        height={14}
                                                    />
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <Typography
                                                        variant='h6'
                                                        color='primary.main'
                                                        fontWeight='500'
                                                        sx={{
                                                            overflow: 'hidden',
                                                            display: '-webkit-box',
                                                            WebkitBoxOrient: 'vertical',
                                                            WebkitLineClamp: '1',
                                                            mb: 2,
                                                        }}>
                                                        {customer_visual_name(customer)}
                                                    </Typography>
                                                    {customerSearch}
                                                </React.Fragment>
                                            )}
                                        </Grid>
                                        <Grid item display='flex' justifyContent='center'>
                                            <Stack
                                                direction='row'
                                                alignItems='center'
                                                sx={{
                                                    boxShadow:
                                                        '5px 5px 5px #d2d4d685,-5px -5px 5px #ffffff82',
                                                    backgroundColor: 'background.default',
                                                    borderRadius: '16px',
                                                    px: 2,
                                                    maxWidth: '180px',
                                                }}
                                                spacing={2}>
                                                <PersonIcon />
                                                <Switch
                                                    checked={customer.customer_type === 'company'}
                                                    onChange={e => {
                                                        const checked = e.target.checked;

                                                        if (checked) {
                                                            changeCustomerType('company');
                                                        } else {
                                                            changeCustomerType('private');
                                                        }
                                                    }}
                                                />
                                                <BusinessIcon />
                                            </Stack>
                                        </Grid>
                                        <Grid item>
                                            {customer.email && (
                                                <OutsetBox label='Email' value={customer.email} />
                                            )}
                                            <IconButton
                                                sx={{ float: 'right', mt: 1 }}
                                                onClick={openActionMenu}>
                                                <Badge
                                                    color='primary'
                                                    variant='dot'
                                                    invisible={similarCustomers.every(
                                                        customer =>
                                                            customer.cancelled || customer.merged
                                                    )}>
                                                    <GroupIcon />
                                                </Badge>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails sx={{ py: 2 }}>
                                    <Typography variant='subtitle1' fontWeight={500} mb={2}>
                                        CLIENTE (CONTATTI)
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <OutsetBox
                                                label='Nome'
                                                name='first_name'
                                                value={customer.first_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <OutsetBox
                                                label='Cognome'
                                                name='last_name'
                                                value={customer.last_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <OutsetBox label='Email' name='email' value={customer.email} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <OutsetBox label='Telefono' name='phone' value={customer.phone} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <OutsetBox
                                                label='Ragione Sociale'
                                                name='company'
                                                value={customer.company}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <OutsetBox
                                                label='Partita IVA'
                                                name='vat'
                                                value={customer.vat}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <OutsetBox
                                                label='Username'
                                                name='username'
                                                value={customer.username}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <OutsetBox
                                                label='Tipo Cliente'
                                                name='customer_type'
                                                value={customer.customer_type}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <OutsetBox
                                                label='Priorità'
                                                name='priority'
                                                value={customer.priority}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={9} lg={9}>
                                            <OutsetBox
                                                label='Note'
                                                name='note'
                                                value={customer.note}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: -34,
                                    right: '50%',
                                    transform: 'translate(50%, 0)',
                                    width: '56px',
                                    padding: 0.1,
                                    backgroundColor: 'background.default',
                                    borderBottomLeftRadius: '68px',
                                    borderBottomRightRadius: '68px',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxShadow: '0px 8px 8px #d2d4d685',
                                    zIndex: 100,
                                }}>
                                <IconButton onClick={toggleAccordion}>
                                    <LeftArrowIcon
                                        sx={{
                                            transform: expanded
                                                ? 'rotate(90deg)'
                                                : 'rotate(270deg)',
                                            fontSize: '26px',
                                            borderWidth: '1px',
                                        }}
                                    />
                                </IconButton>
                            </Box>
                        </Box>
                    ) : (
                        <ShadowCard sx={{ padding: 0, px: 3, pt: 3, pb: 1 }}>
                            {customerSearch}
                        </ShadowCard>
                    )}
                    <Outlet />
                </Box>
            </Box>

            <Menu
                anchorEl={anchorElAction}
                open={Boolean(anchorElAction)}
                onClose={closeActionMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                type='glassMorphism'
                sx={{
                    '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                        width: 'min(100%, 350px)',
                    },
                    '& .MuiButtonBase-root:hover': {
                        backgroundColor: 'primary.main',
                        '& .MuiTypography-root, .MuiSvgIcon-root': {
                            color: 'white',
                        },
                    },
                }}>
                {similarCustomers.length === 0
                    ? null
                    : similarCustomers.map(customer => {
                          return customer.cancelled || customer.merged ? null : (
                              <Box>
                                  <Stack
                                      direction='row'
                                      alignItems='center'
                                      justifyContent='space-between'
                                      px={1}>
                                      <Typography
                                          variant='subtitle1'
                                          fontWeight='500'
                                          sx={{
                                              overflow: 'hidden',
                                              display: '-webkit-box',
                                              WebkitBoxOrient: 'vertical',
                                              WebkitLineClamp: '1',
                                          }}>
                                          {customer.similar_customer.first_name
                                              ? customer.similar_customer.first_name +
                                                ' ' +
                                                customer.similar_customer.last_name
                                              : customer.similar_customer.email
                                              ? customer.similar_customer.email
                                              : customer.similar_customer.phone}
                                      </Typography>
                                      <div>
                                          <IconButton
                                              onMouseEnter={e => {
                                                  setCustomerDetail(customer.similar_customer);
                                                  openDetailPanel(e);
                                              }}
                                              onMouseLeave={closeDetailPanel}>
                                              <LaunchIcon fontSize='small' />
                                          </IconButton>
                                          <IconButton
                                              onClick={() =>
                                                  removeSimilarCustomerSuggestion(customer.id)
                                              }>
                                              <Close fontSize='small' />
                                          </IconButton>
                                      </div>
                                  </Stack>
                                  <MenuItem
                                      onClick={() =>
                                          mergeSimilarCustomer({
                                              id: customer.similar_customer.id,
                                              type: 'add_data',
                                          })
                                      }>
                                      <ListItemText
                                          primaryTypographyProps={{
                                              fontSize: 14,
                                              color: 'text.secondary',
                                          }}>
                                          Aggiungi dati ai contatti principali
                                      </ListItemText>
                                  </MenuItem>
                                  <MenuItem
                                      onClick={() =>
                                          mergeSimilarCustomer({
                                              id: customer.similar_customer.id,
                                              type: 'additional_contact',
                                          })
                                      }>
                                      <ListItemText
                                          primaryTypographyProps={{
                                              fontSize: 14,
                                              color: 'text.secondary',
                                          }}>
                                          Aggiungi dati come contatti secondari
                                      </ListItemText>
                                  </MenuItem>
                              </Box>
                          );
                      })}
            </Menu>

            <Menu
                anchorEl={anchorElMerge}
                open={Boolean(anchorElMerge)}
                onClose={closeMergeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                type='glassMorphism'
                sx={{
                    '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                        width: 'min(100%, 350px)',
                    },
                    '& .MuiButtonBase-root:hover': {
                        backgroundColor: 'primary.main',
                        '& .MuiTypography-root, .MuiSvgIcon-root': {
                            color: 'white',
                        },
                    },
                }}>
                <MenuItem
                    onClick={() => {
                        mergeSimilarCustomer({
                            id: selectCustomer,
                            type: 'add_data',
                        });
                        closeMergeMenu();
                        getCustomer(customer.id);
                    }}>
                    <ListItemText
                        primaryTypographyProps={{
                            fontSize: 14,
                            color: 'text.secondary',
                        }}>
                        Unisci contatti
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        mergeSimilarCustomer({
                            id: selectCustomer,
                            type: 'additional_contact',
                        });
                        closeMergeMenu();
                        getCustomer(customer.id);
                    }}>
                    <ListItemText
                        primaryTypographyProps={{
                            fontSize: 14,
                            color: 'text.secondary',
                        }}>
                        Aggiungi dati come contatti secondari
                    </ListItemText>
                </MenuItem>
            </Menu>

            <Box
                sx={{
                    backgroundColor: 'primary.main',
                    borderRadius: '500px',
                    position: 'fixed',
                    right: 16,
                    bottom: 16,
                    zIndex: 1000,
                }}>
                <IconButton
                    sx={{ p: 2 }}
                    LinkComponent={Link}
                    to={`/`}
                    onClick={() => dispatch(setCustomer({}))}>
                    <ChatIcon />
                </IconButton>
            </Box>

            <DetailPanel
                detailAnchorEl={detailAnchorEl}
                closeDetail={closeDetailPanel}
                details={customerDetail}
            />
        </Box>
    );
};

export default Main;
