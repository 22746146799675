import './libs/axios';
import { Route, Routes } from 'react-router-dom';
// import { Suspense, lazy } from 'react';
// const AuthorizeRouter = lazy(() => import('./router/AuthorizeRouter'));
// const Header = lazy(() => import('./layouts/Header'));
// const Chat = lazy(() => import('./pages/Chat'));
// const Empty = lazy(() => import('./pages/Empty'));
// const Lists = lazy(() => import('./pages/Lists'));

import AuthorizeRouter from './router/AuthorizeRouter';
import Header from './layouts/Header';
import Chat from './pages/Chat/Chat';
import ChatLayout from './pages/Chat';
import Empty from './pages/Empty';
import Index from './pages/CustomerProfile';
import CustomerList from './pages/customer';
import Quote from './pages/Quote';
import CreateQuote from './pages/Quote/CreateQuote';
import ContactList from './pages/Lists/ContactList';
import OrderList from './pages/Order';
import AssistanceList from './pages/Lists/AssistanceList';
import QuotePreview from './pages/Quote/QuotePreview';
import CreateOrder from './pages/Order/CreateOrder';
import Main from './pages/Main';
import SimilarCustomer from './pages/SimilarCustomer';
import User from './pages/User';
import OrderPreview from './pages/Order/OrderPreview';

function App() {
    return (
        // <Suspense fallback={'loading'}>
        <Header>
            <Routes>
                <Route path='/' element={<AuthorizeRouter />}>
                    <Route path='/' element={<ChatLayout />}>
                        <Route path=':id' element={<Chat />} />
                        <Route
                            index
                            element={<Empty message='Seleziona una chat per iniziare la conversazione' />}
                        />
                    </Route>

                    <Route path='dashboard' element={<Main />}>
                        <Route path=':id' element={<Index />} />
                        <Route path='quote'>
                            <Route index element={<Quote />} />
                            <Route path='preview/:quoteId' element={<QuotePreview />} />
                            <Route path='new' element={<CreateQuote />} />
                            <Route path='edit/:quoteId' element={<CreateQuote />} />
                        </Route>
                        <Route path='order'>
                            <Route index element={<OrderList />} />
                            <Route path='preview/:orderId' element={<OrderPreview />} />
                            <Route path='new' element={<CreateOrder />} />
                            <Route path='edit/:orderId' element={<CreateOrder />} />
                        </Route>
                        <Route path='assistance' element={<AssistanceList />} />
                        <Route path='customer' element={<CustomerList />} />
                        <Route path='similar-customer/:id' element={<SimilarCustomer />} />
                        <Route path='contact' element={<ContactList />} />
                    </Route>
                    <Route path='user' element={<User />} />
                </Route>
            </Routes>
        </Header>
        // </Suspense>
    );
}

export default App;
