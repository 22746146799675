import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import DataGrid from '../DataGrid';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../features/messages/messageSlice';

const Quote = ({ closeModal }) => {
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });
    const [rowCount, setRowCount] = useState(0);
    const dispatch = useDispatch();
    const message = useSelector(state => state.message.value);
    const customer = useSelector(state => state.customer.customer);

    const { data, error, isError, isLoading } = useQuery({
        queryKey: ['quotes', customer.id, paginationModel.page],
        queryFn: async () => {
            const params = {
                page: Boolean(paginationModel.page) ? paginationModel.page + 1 : 1,
                customer__id: customer.id,
            };

            const response = await axios.get('/quotes/', { params });
            setRowCount(response.data.count);
            return response.data;
        },
    });

    const quotes = useMemo(() => (data ? data.results : []), [data]);

    useEffect(() => {
        setRowCount(prev => (data?.count !== undefined ? data?.count : prev));
    }, [data?.count, setRowCount]);

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: 'Id',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 100,
            },
            {
                field: 'customer',
                headerName: 'Cliente',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params =>
                    params.row.customer?.first_name + ' ' + params.row.customer?.last_name,
            },
            {
                field: 'quantity',
                headerName: 'Quantità',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
            },
            {
                field: 'subtotal',
                headerName: 'Subtotal',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
            },
            {
                field: 'total',
                headerName: 'Totale',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 150,
            },
            {
                headerName: 'ACTION',

                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 150,
                renderCell: params => [
                    <Button
                        onClick={() => {
                            closeModal();
                            dispatch(
                                setMessage(
                                    message ? message + ' ' + params.row.link : params.row.link
                                )
                            );
                        }}
                        variant='outlined'
                        startIcon={
                            <SendOutlinedIcon
                                fontSize='small'
                                sx={{ color: 'primary.main', transform: 'rotate(-40deg)' }}
                            />
                        }>
                        Send
                    </Button>,
                ],
            },
        ],
        [closeModal, dispatch, message]
    );

    if (isError) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}>
                <Typography variant='h5' sx={{ color: 'error.main' }}>
                    {error.message}
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    p: 2,
                    maxWidth: '1200px',
                    width: '100%',
                    bgcolor: 'background.default',
                    borderRadius: '5px',
                    maxHeight: '85vh',
                    overflowY: 'auto',
                }}>
                <Typography variant='h5'>Lista Preventivi</Typography>
                <Divider variant='fullWidth' sx={{ my: 2 }} />

                <DataGrid
                    rows={quotes}
                    columns={columns}
                    autoHeight
                    rowSelection={false}
                    disableSelectionOnClick
                    loading={isLoading}
                    rowCount={rowCount}
                    checkboxSelection={false}
                    pageSizeOptions={[40]}
                    paginationModel={paginationModel}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                />
            </Box>
        </>
    );
};

export default Quote;
