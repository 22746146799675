import { Button, Card, Grid, Stack, Typography, Select, MenuItem, ListItemText, FormHelperText } from '@mui/material';
import React, { useMemo } from 'react';
import { Form, Submit, useForm } from '../../hooks/useForm';
import { Input } from '../../hooks/useForm/inputs/index';
import CustomInput from '../../components/Input/input';
import { useMessage } from '../../layouts/Header';
import useErrorHandler from '../../hooks/useErrorHandler';
import { useDispatch, useSelector } from 'react-redux';
import { customer as customer_choices} from '../../data/choices';
import { customer_translate} from '../../data/choices';
import { setCustomer } from '../../features/customer/customerSlice';

const Contact = ({ closeModal }) => {
    const customer = useSelector(state => state.customer.customer);
    const { showSuccess, showError } = useMessage();
    const errorHandler = useErrorHandler();
    const dispatch = useDispatch();

    const handlers = useForm(
        useMemo(
            () => ({
                first_name: { required: false, value: customer.first_name },
                last_name: { required: false, value: customer.last_name },
                company: { required: false, value: customer.company },
                username: { required: false, value: customer.username },
                email: { required: false, value: customer.email },
                phone: { required: false, value: customer.phone },
                vat: { required: false, value: customer.vat },
                customer_type: { required: true, value: customer.customer_type },
                priority: { required: true, value: customer.priority },
                note: { required: false, value: customer.note },
            }),
            [customer]
        ),
        { Input: CustomInput }
    );

    const setValues = handlers.setValues;
    const customChangeHandler = e => {
        const { name, value } = e.target;
        setValues({ [name]: value });
    };

    const onSubmit = res => {
        if (res.data) {
            const {
                first_name,
                last_name,
                company,
                username,
                email,
                phone,
                vat,
                customer_type,
                priority,
            } = res.data;
            dispatch(setCustomer(res.data));
            setValues({
                first_name,
                last_name,
                company,
                username,
                email,
                phone,
                vat,
                customer_type,
                priority,
            });
            closeModal();
            return showSuccess('Customer saved successfully');
        }

        closeModal();
        showError('Unable to update the customer at the moment');
    };

    return (
        <Card
            sx={{
                p: 2.5,
                bgcolor: 'background.default',
                borderRadius: 2,
                maxWidth: '895px',
                width: '100%',
            }}>
            <Form
                handlers={handlers}
                onSubmit={onSubmit}
                action={`/customers/${customer.id}/`}
                method='patch'
                onError={errorHandler}>
                <Typography variant='subtitle1' fontWeight={500} mb={2}>
                    CLIENTE (CONTATTI)
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Input label='Nome' name='first_name' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input label='Cognome' name='last_name' />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={9}>
                        <Input label='Ragione Sociale' name='company' />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input label='Partita IVA' name='vat' />
                    </Grid>
                </Grid>
                <Input label='Username' name='username' />
                <Input label='Email' name='email' />
                <Input label='Telefono' name='phone' />

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Input label='Tipo Cliente' name='customer_type' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select
                            fullWidth
                            displayEmpty
                            value={handlers.values.priority}
                            name='priority'
                            onChange={customChangeHandler}
                            isError={Boolean(handlers.errors.priority)}
                            error={handlers.errors.priority}
                            sx={{ py: 1, mb: 0 }}>
                            <MenuItem disabled={true} value=''>
                                Priorità
                            </MenuItem>
                            {customer_choices.priority.map(type => (
                                <MenuItem key={type} value={type}>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 14,
                                            color: 'text.secondary',
                                            textTransform: 'capitalize',
                                        }}>
                                        {customer_translate.priority[type]}
                                    </ListItemText>
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error={handlers.errors.priority} sx={{ ml: 2 }}>
                            {handlers.errors.priority}
                        </FormHelperText>
                    </Grid>
                </Grid>
                <Input label='Note' name='note' />
                <Stack direction='row' gap={2} justifyContent='flex-end' mt={2}>
                    <Button variant='text' onClick={closeModal}>
                        Cancel
                    </Button>
                    <Submit>
                        {loader => (
                            <Button
                                variant='contained'
                                type='submit'
                                disabled={Boolean(loader)}
                                endIcon={loader}>
                                Update
                            </Button>
                        )}
                    </Submit>
                </Stack>
            </Form>
        </Card>
    );
};

export default Contact;
