import Person2Outlined from '@mui/icons-material/Person2Outlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import CalculateIcon from '@mui/icons-material/Calculate';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

const Platforms = [
    {
        name: 'whatsapp',
        icon: 'whatsapp.svg',
        background: 'whatsapp-background.svg',
    },
    {
        name: 'messenger',
        icon: 'messenger.svg',
        background: 'messenger-background.svg',
    },
    {
        name: 'telegram',
        icon: 'telegram.svg',
        background: 'telegram-background.svg',
    },
    {
        name: 'discord',
        icon: 'discord.svg',
        background: 'whatsapp-background.svg',
    },
    {
        name: 'skype',
        icon: 'skype.svg',
        background: 'whatsapp-background.svg',
    },
    {
        name: 'zoom',
        icon: 'zoom.svg',
        background: 'whatsapp-background.svg',
    },
    {
        name: 'google_meet',
        icon: 'meet.svg',
        background: 'whatsapp-background.svg',
    },
    {
        name: 'gmail',
        icon: 'gmail.svg',
        background: 'gmail-background.svg',
    },
    {
        name: 'google_business',
        icon: 'business.svg',
        background: 'business-background.svg',
    },
];

const Actions = [
    {
        name: 'Profilo Cliente',
        icon: <Person2Outlined />,
        to: '/dashboard',
    },
    {
        name: 'Crea Preventivo',
        icon: <CalculateIcon />,
        to: '/dashboard/quote/new',
    },
    {
        name: 'Crea Ordine',
        icon: <AddShoppingCartIcon />,
        to: '/dashboard/order/new',
    },
    {
        name: 'Chat',
        icon: <ChatBubbleOutlineIcon />,
        to: '',
    },
];

const Lists = [
    { name: 'RICHIESTE DI ASSISTENZA', to: '/dashboard/assistance' },
    { name: 'CLIENTI', to: '/dashboard/customer' },
    { name: 'MATCHING CLIENTI', to: '/dashboard/similar-customer' },
    // { name: 'CONTATTI', to: '/dashboard/contact' },
    { name: 'ORDINI', to: '/dashboard/order' },
    { name: 'PREVENTIVI', to: '/dashboard/quote' },
];

export { Platforms, Actions, Lists };
