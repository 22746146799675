import {
    Button,
    Card,
    FormHelperText,
    Grid,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { Form, Submit, useForm } from '../../hooks/useForm';
import { Input } from '../../hooks/useForm/inputs/index';
import CustomInput from '../../components/Input/input';
import { useMessage } from '../../layouts/Header';
import useErrorHandler from '../../hooks/useErrorHandler';
import { customer } from '../../data/choices';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const AddCustomer = ({ closeModal }) => {
    const { showSuccess, showError } = useMessage();
    const errorHandler = useErrorHandler();

    const handlers = useForm(
        useMemo(
            () => ({
                first_name: '',
                last_name: '',
                company: '',
                username: '',
                email: '',
                phone: '',
                vat: '',
                customer_type: { required: true },
                priority: { required: true },
                site: { required: true },
            }),
            []
        ),
        { Input: CustomInput }
    );

    const setValues = handlers.setValues;
    const errors = handlers.errors;
    const values = handlers.values;

    const customChangeHandler = e => {
        const { name, value } = e.target;
        setValues({ [name]: value });
    };

    const onSubmit = res => {
        closeModal();

        if (res.data) {
            return showSuccess('New Customer created');
        }

        showError('Unable to create the customer at the moment');
    };

    const { data } = useQuery({
        queryKey: ['sites'],
        queryFn: async () => {
            const { data } = await axios.get(`/sites/`);
            return data;
        },
    });

    const sites = useMemo(() => (data ? data.results : []), [data]);

    return (
        <Card
            sx={{
                p: 2.5,
                bgcolor: 'background.default',
                borderRadius: 2,
                maxWidth: '895px',
                width: '100%',
            }}>
            <Form
                handlers={handlers}
                onSubmit={onSubmit}
                action={`/customers/`}
                method='post'
                final={v => ({ ...v, site: [v.site] })}
                onError={errorHandler}>
                <Typography variant='subtitle1' fontWeight={500} mb={2}>
                    NEW CUSTOMER
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Input label='Nome' name='first_name' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input label='Cognome' name='last_name' />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={9}>
                        <Input label='Ragione Sociale' name='company' />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input label='Partita IVA' name='vat' />
                    </Grid>
                </Grid>
                <Input label='Username' name='username' />
                <Input label='Email' name='email' />
                <Input label='Telefono' name='phone' type='number' />

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Select
                            fullWidth
                            displayEmpty
                            value={values.customer_type}
                            name='customer_type'
                            onChange={customChangeHandler}
                            isError={Boolean(errors.customer_type)}
                            error={errors.customer_type}
                            sx={{ py: 1, mb: 0 }}>
                            <MenuItem disabled={true} value=''>
                                Tipo Cliente
                            </MenuItem>
                            {customer.customer_type.map(type => (
                                <MenuItem key={type} value={type}>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 14,
                                            color: 'text.secondary',
                                            textTransform: 'capitalize',
                                        }}>
                                        {type}
                                    </ListItemText>
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error={errors.customer_type} sx={{ ml: 2 }}>
                            {errors.customer_type}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select
                            fullWidth
                            displayEmpty
                            value={values.priority}
                            name='priority'
                            onChange={customChangeHandler}
                            isError={Boolean(errors.priority)}
                            error={errors.priority}
                            sx={{ py: 1, mb: 0 }}>
                            <MenuItem disabled={true} value=''>
                                Priorità
                            </MenuItem>
                            {customer.priority.map(type => (
                                <MenuItem key={type} value={type}>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 14,
                                            color: 'text.secondary',
                                            textTransform: 'capitalize',
                                        }}>
                                        {type}
                                    </ListItemText>
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error={errors.priority} sx={{ ml: 2 }}>
                            {errors.priority}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            fullWidth
                            displayEmpty
                            value={values.site}
                            name='site'
                            onChange={customChangeHandler}
                            isError={Boolean(errors.site)}
                            error={errors.site}
                            sx={{ py: 1 }}>
                            <MenuItem disabled={true} value=''>
                                Sites
                            </MenuItem>
                            {sites.map(site => (
                                <MenuItem key={site} value={site.id}>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 14,
                                            color: 'text.secondary',
                                        }}>
                                        {site.name}
                                    </ListItemText>
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error={errors.site} sx={{ ml: 2 }}>
                            {errors.site}
                        </FormHelperText>
                    </Grid>
                </Grid>
                <Stack direction='row' gap={2} justifyContent='flex-end' mt={2}>
                    <Button variant='text' onClick={closeModal}>
                        Cancel
                    </Button>
                    <Submit>
                        {loader => (
                            <Button
                                variant='contained'
                                type='submit'
                                disabled={Boolean(loader)}
                                endIcon={loader}>
                                Create
                            </Button>
                        )}
                    </Submit>
                </Stack>
            </Form>
        </Card>
    );
};

export default AddCustomer;
