import {
    Select,
    MenuItem
} from '@mui/material';

function SelectTable(props) {
    const { field, changeFunction, params, items } = props;

    return (
        <Select
            value={params.row[field]}
            size='small'
            fullWidth
            onChange={e =>
                changeFunction(params.id, { field: field, value: e.target.value })
            }>
            {items[field].map(value => (
                <MenuItem value={value} key={value} sx={{ textTransform: 'capitalize' }}>
                    {value}
                </MenuItem>
            ))}
        </Select>
    );
}

export default SelectTable;
