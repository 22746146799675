import { Button, Card, Grid, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Form, Submit, useForm } from '../../hooks/useForm';
import { Input } from '../../hooks/useForm/inputs/index';
import CustomInput from '../../components/Input/input';
import { useMessage } from '../../layouts/Header';
import useErrorHandler from '../../hooks/useErrorHandler';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomer } from '../../features/customer/customerSlice';
import { hasSame } from '../../utils/function';

const Address = props => {
    const { address, closeModal } = props;
    const { action, type, id } = address;
    const { showSuccess, showError } = useMessage();
    const errorHandler = useErrorHandler();
    const dispatch = useDispatch();
    const customer = useSelector(state => state.customer.customer);

    const controller = useMemo(() => {
        switch (type) {
            case 'billing':
                if (hasSame(action, 'new')) return { action: '/customer-billing/', method: 'post' };

                if (hasSame(action, 'edit'))
                    return { action: `/customer-billing/${id}/`, method: 'patch' };
                break;

            case 'shipping':
                if (hasSame(action, 'new'))
                    return { action: '/customer-shipping/', method: 'post' };

                if (hasSame(action, 'edit'))
                    return { action: `/customer-shipping/${id}/`, method: 'patch' };
                break;

            default:
                return { action: ``, method: '' };
        }
    }, [action, type, id]);

    const handlers = useForm(
        useMemo(
            () => ({
                first_name: { required: true, value: address.first_name },
                last_name: { required: true, value: address.last_name },
                company: { required: true, value: address.company },
                ...(address.type === 'billing'
                    ? { vat: { required: true, value: address.vat } }
                    : {}),
                address_1: { required: true, value: address.address_1 },
                address_2: { required: true, value: address.address_2 },
                city: { required: true, value: address.city },
                postcode: { required: true, value: address.postcode },
                state: { required: true, value: address.state },
                country: { required: true, value: address.country },
                customer: { value: customer.id },
            }),
            [address, customer.id]
        ),
        { Input: CustomInput }
    );

    const onSubmit = res => {
        if (res.data) {
            closeModal();

            if (action === 'edit') {
                const address = [...customer[type]];
                address.splice(address.index, 1);
                address.push(res.data);
                dispatch(setCustomer({ ...customer, [type]: address }));
            } else {
                dispatch(setCustomer({ ...customer, [type]: [...customer[type], res.data] }));
            }

            return showSuccess('Indirizzosaved successfully');
        }

        showError('Unable to update the customer at the moment');
    };

    return (
        <Card
            sx={{
                p: 2.5,
                bgcolor: 'background.default',
                borderRadius: 2,
                maxWidth: '895px',
                width: '100%',
            }}>
            <Form
                handlers={handlers}
                onSubmit={onSubmit}
                action={controller.action}
                method={controller.method}
                onError={errorHandler}>
                <Typography variant='subtitle1' fontWeight={500} mb={2}>
                    {action === 'new' ? 'ADD NEW ' : null} ADDRESS{' '}
                    {action === 'edit' ? address.index + 1 : null}
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Input label='Nome' name='first_name' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input label='Cognome' name='last_name' />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={address.type === 'billing' ? 8 : 12}>
                        <Input label='Ragione Sociale' name='company' />
                    </Grid>
                    {address.type === 'billing' ? (
                        <Grid item xs={12} sm={4}>
                            <Input label='Partita IVA' name='vat' />
                        </Grid>
                    ) : null}
                </Grid>

                <Input label='Indirizzo1' name='address_1' />
                <Input label='Indirizzo2' name='address_2' />

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Input label='Stato' name='country' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input label='Provincia' name='state' />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Input label='Città' name='city' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input label='Codice Postale' name='postcode' type='number' />
                    </Grid>
                </Grid>
                <Stack direction='row' gap={2} justifyContent='flex-end' mt={2}>
                    <Button variant='text' onClick={closeModal}>
                        Cancel
                    </Button>
                    <Submit>
                        {loader => (
                            <Button
                                variant='contained'
                                type='submit'
                                disabled={Boolean(loader)}
                                endIcon={loader}>
                                {action === 'new' ? 'Add' : 'Update'}
                            </Button>
                        )}
                    </Submit>
                </Stack>
            </Form>
        </Card>
    );
};

export default Address;
