import { Box, Stack, Tab, Tabs } from '@mui/material';
import TabPanel from '../../../components/TabPanel';
import Media from './Media';
import Link from './Link';
import Doc from './Doc';
import Starred from './Starred';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTab } from '../../../features/sidepanel/userInfoSlice';

const Index = props => {
    const { expanded } = props;
    const tabSelected = useSelector(state => state.userInfo.selectedTab);
    const dispatch = useDispatch();
    const handleTabChange = (_, newValue) => dispatch(setSelectedTab(newValue));

    return (
        <Stack direction='column' height={`calc(100dvh - ${expanded ? '350px' : '290px'})`} ml={1}>
            <Tabs
                value={tabSelected}
                onChange={handleTabChange}
                sx={{
                    mt: 3,
                    minHeight: '35px',
                    width: '100%',
                    '& .MuiTabs-indicator': {
                        height: '4px',
                    },
                    '.MuiButtonBase-root.MuiTab-root': {
                        flexGrow: 1,
                        px: 2,
                        py: 0.3,
                        minHeight: '28px',
                        alignItems: 'center',
                        fontSize: '13px',
                        fontWeight: 500,
                        backgroundColor: 'background.default',
                    },
                }}>
                <Tab label='Media' />
                <Tab label='Link' />
                <Tab label='Doc' />
                <Tab label='Salvati' />
            </Tabs>
            <Box
                sx={{
                    flexGrow: 1,
                    borderRadius: '5px',
                    overflowY: 'auto',
                    backgroundColor: 'background.default',
                    boxShadow: `inset 8px 8px 8px #bebebe78, inset -8px -8px 8px #ffffff75`,
                }}>
                <TabPanel value={tabSelected} index={0}>
                    <Media />
                </TabPanel>
                <TabPanel value={tabSelected} index={1}>
                    <Link />
                </TabPanel>
                <TabPanel value={tabSelected} index={2}>
                    <Doc />
                </TabPanel>
                <TabPanel value={tabSelected} index={3}>
                    <Starred />
                </TabPanel>
            </Box>
        </Stack>
    );
};

export default Index;
