import React from 'react';
import { GridOverlay, DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

const StyledDataGrid = styled(MuiDataGrid)(({ theme }) => ({
    '.MuiDataGrid-withBorderColor': {
        border: 'none',
    },
    '.MuiDataGrid-cell:is(:focus, :focus-within)': {
        outline: 'none',
    },
    '.MuiDataGrid-row': {
        borderBottom: '1px solid rgb(224 224 224)',
        fontWeight: 400,
        color: theme.palette.text.primary,
    },

    '& .MuiDataGrid-main': {
        width: '100%',
        background: theme.palette.background.default,
        padding: '8px',
        borderRadius: '6px',
        borderColor: 'transparent',
    },

    '.MuiDataGrid-container--top [role=row]': {
        backgroundColor: theme.palette.background.paper,
    },

    '& .MuiDataGrid-main .MuiDataGrid-columnHeaders': {
        borderRadius: '8px',

        '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: 500,
            color: theme.palette.text.primary,
            fontSize: '14px',
            textTransform: 'uppercase',
        },
        '& .MuiDataGrid-columnHeader:is(:focus, :focus-within)': {
            outline: 'none',
        },
    },
}));

const DataGrid = props => {
    const { slots, ...rest } = props;
    return (
        <StyledDataGrid
            disableColumnFilter
            disableColumnMenu
            slots={{
                ...slots,
                noRowsOverlay: () => (
                    <GridOverlay>
                        <Typography
                            variant='h4'
                            color='text.secondary'
                            textAlign='center'
                            sx={{ wordSpacing: '2px', minHeight: '85vh' }}>
                            No data available
                        </Typography>
                    </GridOverlay>
                ),
            }}
            {...rest}
        />
    );
};

export default DataGrid;
