const conditions = [
    { name: 'Contiene', value: 'contain' },
    { name: 'Non Contiene', value: 'not_contain' },
    { name: 'Esatto', value: 'exact' },
    { name: 'Vuoto', value: 'empty' },
    { name: 'Non Vuoto', value: 'not_empty' },
    { name: 'Maggiore di', value: 'gt' },
    { name: 'Minore di', value: 'lg' },
];

export { conditions };
