import { Container, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import Image from '../../../components/Image';
import { useDispatch, useSelector } from 'react-redux';
import { useIntersection } from '@mantine/hooks';
import { useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';
import { compareDate, generateDate } from '../../../utils/function';
import { setRedirect } from '../../../features/messages/messageSlice';

const Media = () => {
    const conversation = useSelector(state => state.customer.conversation);
    const containerRef = useRef(null);
    const dispatch = useDispatch();

    const { entry, ref } = useIntersection({ root: containerRef.current, threshold: 1 });

    const { data, isError, error, hasNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: ['chat-image', conversation.id],
        queryFn: async ({ queryKey, pageParam }) => {
            const response = await axios.get('/messages/', {
                params: {
                    conversation__id: queryKey[1],
                    page: pageParam,
                    attachment_type: 'media',
                },
            });
            return response.data;
        },
        initialPageParam: 1,
        getNextPageParam: (prevData, pages) => {
            return prevData.next ? pages.length + 1 : null;
        },
        refetchOnWindowFocus: false,
    });

    const messages = useMemo(() => (data ? data.pages.flatMap(page => page.results) : []), [data]);

    useEffect(() => {
        if (hasNextPage && entry?.isIntersecting) fetchNextPage();
    }, [hasNextPage, entry, fetchNextPage]);

    if (isError) {
        return <h3>{error.message}</h3>;
    }

    return (
        <Container maxWidth='lg' sx={{ height: '100%' }} ref={containerRef}>
            <Grid container spacing={1}>
                {messages.map((message, i) => (
                    <React.Fragment key={i}>
                        <Grid
                            item
                            xs={12}
                            display={
                                compareDate(message.creation_time, messages[i - 1]?.creation_time)
                                    ? 'none'
                                    : 'block'
                            }>
                            <Typography variant='body2' color='text.secondary' mt={4}>
                                {generateDate(message.creation_time)}
                            </Typography>
                            <Divider sx={{ my: 1 }} />
                        </Grid>
                        <Grid item xs={4}>
                            <Image
                                src={message.attachment.at(0)?.file}
                                ref={messages.length - 1 === i ? ref : null}
                                sx={{ cursor: 'pointer' }}
                                onClick={() =>
                                    dispatch(
                                        setRedirect({ id: message.id, page: message.original_page })
                                    )
                                }
                            />
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Container>
    );
};

export default Media;
