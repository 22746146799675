import React, { memo, useState } from 'react';

//mui component
import {
    Box,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    Tooltip,
    Menu,
    Badge
} from '@mui/material';

//services
import Avatar from '../components/Avatar';
import PestControlIcon from '@mui/icons-material/PestControl';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import { Actions } from '../data/sidebar';
import { Link, NavLink } from 'react-router-dom';
import { useMenu } from '../hooks/useMenu';
import { clearCookie } from '../utils/cookies';
import { useAuthorize } from '../hooks/Authorize';
import { useSelector } from 'react-redux';
import BugReport from './BugReport';
import { useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';


const Sidebar = () => {
    const authorize = useAuthorize();
    const { openMenu: openBugMenu, closeMenu: closeBugMenu, anchorEl: anchorElBug } = useMenu();
    const customer = useSelector(state => state.customer.customer);
    const [lastCheck, setLastCheck] = useState(Date.now())

    const signOut = () => {
        clearCookie('accessToken');
        authorize(false);
        window.location.reload();
    };

    const {} = useInfiniteQuery({
        queryKey: ['notification'],
        queryFn: async () => {
            const response = await axios.get('/messages/', {
                params: { page: 1, page_size:1 },
            });
            const message = response.data.results[0]
            if(new Date(message.creation_time) > lastCheck && message.status=='delivered' && message.sender=='customer'){
                const audio = new Audio(`${process.env.PUBLIC_URL}/sounds/notification.mp3`)
                audio.play()
            }
            setLastCheck(Date.now())
            return response.data;
        },
        refetchInterval: 5000,
        maxPages:0,
        getNextPageParam: ()=>{},
        getPreviousPageParam: ()=>{}
    });

    let notification = (
        <Badge
            color='primary'
            variant='dot'
            sx={{
                marginTop: '-15px'
            }}
            >
        </Badge>
    );

    return (
        <Box
            height='100%'
            color='text.secondary'
            display='flex'
            flexDirection='column'
            alignItems='center'
            sx={{
                transition: 'all 225ms',
                overflowY: 'auto',
                '& .MuiButtonBase-root,MuiListItemButton-root': {
                    minHeight: '45px',
                    borderRadius: '100%',
                    mb: 2,
                },
            }}>
            <Box
                display='flex'
                flexDirection={'column'}
                alignItems='center'
                justifyContent='center'
                height={100}
                sx={{
                    textDecoration: 'none',
                    color: 'text.primary',
                    py: 1,
                    // height: isSidePanelOpen ? '120px' : 'auto',
                }}>
            </Box>
            <Box
                sx={{
                    overflowY: 'auto',
                    height: 'calc(100% - 152px)',
                    flexGrow: 1,
                    pb: 2,
                }}>
                <List disablePadding sx={{ mt: 1 }}>
                    <>
                        <ListItem sx={{ p: 0.5, mb: 1.5 }}>
                            <ListItemButton
                                variant='sidebarIconButton'
                                LinkComponent={Link}
                                to={`/dashboard/` + customer.id}
                                alignItems='flex-start'
                                sx={{ pointerEvents: customer.id ? 'auto' : 'none' }}>
                                <ListItemAvatar sx={{ m: 0 }}>
                                    <Avatar
                                        src={customer.profile_pic}
                                        name='profile.jpeg'
                                        sx={{ width: '50px', height: '50px' }}
                                    />
                                </ListItemAvatar>
                            </ListItemButton>
                        </ListItem>

                        {Actions.map(action => (
                            <NavLink
                                to={
                                    ['/dashboard', ''].includes(action.to) && customer.id
                                        ? `${action.to}/${customer.id}`
                                        : action.to
                                }
                                end
                                key={action.to}
                                style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    pointerEvents:
                                        action.to === '/dashboard' && !customer.id
                                            ? 'none'
                                            : 'auto',
                                }}>
                                {({ isActive }) => (
                                    <ListItem sx={{ p: 0.5 }} key={action.to}>
                                        <ListItemButton
                                            variant='sidebarIconButton'
                                            alignItems='flex-start'>
                                            <ListItemAvatar sx={{ m: 0 }}>
                                                <Tooltip title={action.name}>
                                                    <Box
                                                        sx={{
                                                            width: '45px',
                                                            height: '45px',
                                                            bgcolor: isActive
                                                                ? 'primary.main'
                                                                : 'transparent',
                                                            color: isActive
                                                                ? 'white'
                                                                : 'text.primary',
                                                            borderRadius: '500px',
                                                            display: 'inline-flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}>
                                                        {action.icon}
                                                        {action.name=='Chat' ? notification: ''}
                                                    </Box>
                                                </Tooltip>
                                            </ListItemAvatar>
                                        </ListItemButton>
                                    </ListItem>
                                )}
                            </NavLink>
                        ))}
                    </>
                </List>
            </Box>
            <IconButton sx={{ width: '45px', height: '45px' }} onClick={openBugMenu}>
                <PestControlIcon />
            </IconButton>
            <IconButton sx={{ width: '45px', height: '45px' }} LinkComponent={Link} to='/user'>
                <ManageAccountsIcon />
            </IconButton>
            <IconButton onClick={signOut} color='primary' sx={{ width: '45px', height: '45px' }}>
                <LogoutOutlined fontSize='small' />
            </IconButton>
            <Menu
                anchorEl={anchorElBug}
                open={Boolean(anchorElBug)}
                onClose={closeBugMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    marginLeft: '90px',
                    '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                        width: 'min(100%, 359px)',
                    },
                }}
                type='glassMorphism'>
                <BugReport closeMenu={closeBugMenu} />
            </Menu>
        </Box>
    );
};

export default memo(Sidebar);
