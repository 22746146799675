import { Stack, Typography } from '@mui/material';
import React from 'react';

const Empty = ({ message }) => {
    return (
        <Stack direction='row' justifyContent='center' alignItems='center' height='100%'>
            <Typography variant='h5'>{message}</Typography>
        </Stack>
    );
};

export default Empty;
