import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import ShadowCard from './ShadowCard';
import {
    Button,
    Grid,
    IconButton,
    MenuItem,
    Stack,
    Typography,
    Divider,
    Select,
    Menu,
    Box,
    InputBase,
} from '@mui/material';
import Input from './Input/input';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useMessage } from '../layouts/Header';
import Image from './Image';
import SelectWithSearch from './SelectWithSearch';
import InsetBox from './InsetBox';
import { getBase64, getSessionData, isEmpty, setSessionData } from '../utils/function';
import GridViewIcon from '@mui/icons-material/GridView';
import { useMenu } from '../hooks/useMenu';
import colors from '../data/colors';

const itemFormat = {
    product: 0,
    quantity: 1,
};

const GroupCard = props => {
    const {
        variant,
        group,
        productList,
        productListAll,
        groupHandler,
        productHandler,
        addCard,
        deleteCard,
        groupIndex,
        setSearch,
        setGroups,
        groupAttributes,
        productVariantHandler,
        quantityHandler,
        attributesHandler,
        priceHandler,
        basePriceHandler,
    } = props;

    const { showError } = useMessage();
    const fileRef = useRef(null);
    const [file, setFile] = useState(null);
    const [attributes, setAttributes] = useState({});
    const [price, setPrice] = useState({});
    const [selectedProduct, setSelectedProduct] = useState({});

    const {
        anchorEl: anchorElFilter,
        openMenu: openFilterMenu,
        closeMenu: closeFilterMenu,
    } = useMenu();

    const fileHandler = async e => {
        e.stopPropagation();

        const file = e.target.files[0];
        if (!file) return showError('No file selected');
        const base64 = await getBase64(file);
        setFile(base64);
        setGroups(prev => {
            console.log(groupIndex, prev)
            if (prev[groupIndex].files==undefined) prev[groupIndex].files = []
            prev[groupIndex].files[0] = { file: base64 };
            return [...prev];
        });
    };

    const deleteProduct = i => {
        setGroups(prev => {
            const products = [...prev[groupIndex].items];
            products.splice(i, 1);
            prev[groupIndex].items = products;

            return [...prev];
        });

        const obj = {};
        const values = Object.values(attributes);
        values.splice(i, 1);

        values.forEach((value, i) => {
            obj[i] = value;
        });

        setAttributes(obj);
    };

    const onChangeAttributes = (e, productIndex, product) => {
        const { name, value } = e.target;
        const values = Object.values({
            ...attributes[productIndex],
            [name]: value,
            // ...(name === 'Taglia' ? {} : { [name]: value }),
        });

        outer: for (let variation of product.variations) {
            for (let attribute of variation.attributes) {
                if (values.some(value => attribute.option === value)) {
                    if (variation.price) priceHandler(variation.price, groupIndex, productIndex);
                    productVariantHandler(variation.id, groupIndex, productIndex);
                    break outer;
                }
            }
        }

        setAttributes({
            ...attributes,
            [productIndex]: { ...attributes[productIndex], [name]: value },
        });
        attributesHandler({ ...attributes[productIndex], [name]: value }, groupIndex, productIndex);
    };

    const addProduct = () => {
        setGroups(prev => {
            prev[groupIndex].items.push(itemFormat);
            return [...prev];
        });
    };

    const onProductChange = (e, productIndex) => {
        const { value } = e.target;
        setSelectedProduct(productList[value]);
        productHandler(value, groupIndex, productIndex);
    };

    const onPriceChange = (e, productIndex) => {
        const { value } = e.target;
        priceHandler(value, groupIndex, productIndex);
    };

    const onBasePriceChange = (e, productIndex) => {
        const { value } = e.target;
        basePriceHandler(value, groupIndex, productIndex);
    };

    const onQuantityChange = (e, productIndex) => {
        const { value } = e.target;
        quantityHandler(value, groupIndex, productIndex);
    };

    useEffect(() => {
        setFile(group.files?.length ? group.files[0].file : null);
    }, [group.files]);

    useEffect(() => {
        // console.log('useeffect groupAttributes')
        if (!isEmpty(groupAttributes)) {
            setAttributes(groupAttributes);
        }
    }, [groupAttributes]);

    useEffect(() => {
        const data = getSessionData(variant);
        if (!data) return;
        const { file, attributes, selectedProduct, price } = JSON.parse(data);
        setAttributes(attributes);
        setFile(file);
        setSelectedProduct(selectedProduct);
        setPrice(price);
    }, [variant]);

    useEffect(() => {
        return () => {
            setSessionData(variant, JSON.stringify({ file, attributes, selectedProduct, price }));
        };
    }, [variant, file, price, selectedProduct, attributes]);

    // console.log('groups', group)
    // console.log('groupAttributes', groupAttributes)
    // console.log(attributes)

    return productList ? (
        <ShadowCard
            sx={{
                p: 2.5,
                mt: 4,
            }}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Typography variant='subtitle1' fontWeight={500} color='text.tertiary'>
                    Gruppo design prodotti
                </Typography>

                <Stack direction='row' spacing={1}>
                    <IconButton disabled={groupIndex === 0} onClick={() => deleteCard(groupIndex)}>
                        <DeleteOutlineIcon />
                    </IconButton>
                    <Divider flexItem variant='fullWidth' orientation='vertical' />
                    <IconButton onClick={addCard}>
                        <AddCircleIcon color='primary' />
                    </IconButton>
                </Stack>
            </Stack>
            <Grid container columnSpacing={8} rowSpacing={4} mt={1}>
                {group.items.map((product, productIndex) => {
                    const productId = product.product;

                    return (
                        <Grid item xs={12} xm={6} key={productIndex}>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <SelectWithSearch
                                    displayEmpty
                                    onChange={e => {
                                        onProductChange(e, productIndex);
                                        if (productList[productId]?.price)
                                            priceHandler(
                                                productList[productId].price,
                                                groupIndex,
                                                productIndex
                                            );
                                    }}
                                    value={productId}
                                    fullWidth
                                    size='small'
                                    renderValue={v => {
                                        if (!v) return 'Product name';
                                        return productListAll[v]?.name;
                                    }}
                                    SearchProps={{
                                        onChange: e => setSearch(e.target.value),
                                        onFocus: e => setSearch(e.target.value)
                                    }}>
                                    {Object.keys(productList).map((key, i) => {
                                        return (
                                            <MenuItem
                                                value={key}
                                                key={i}
                                                selected={false}
                                                sx={{ px: 1.2 }}>
                                                {productList[key].name}
                                            </MenuItem>
                                        );
                                    })}
                                </SelectWithSearch>

                                <Stack direction='row' spacing={1} alignItems='center'>
                                    {productListAll[productId]?.product_type === 'Abbigliamento' ? (
                                        <IconButton
                                            sx={{ p: 0 }}
                                            color='primary'
                                            onClick={openFilterMenu}>
                                            <GridViewIcon />
                                        </IconButton>
                                    ) : null}
                                    <IconButton sx={{ p: 0 }} onClick={addProduct}>
                                        <NoteAddIcon />
                                    </IconButton>
                                    <Divider orientation='vertical' variant='middle' flexItem />

                                    <IconButton
                                        sx={{ p: 0 }}
                                        disabled={productIndex === 0 && group.items.length==1}
                                        onClick={() => deleteProduct(productIndex)}>
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Stack>
                            </Stack>
                            <Grid container spacing={3} mt={5}>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Input
                                        inset
                                        name='quantity'
                                        value={product.quantity}
                                        label='Quantità'
                                        type='number'
                                        onChange={e => onQuantityChange(e, productIndex)}
                                        max={100}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <InsetBox
                                        label='Sconto'
                                        value={(product.discount_percentage || 0) + '%'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Input
                                        inset
                                        name='base_price'
                                        value={product.base_price ?? productListAll[productId]?.price}
                                        label='Prezzo'
                                        type='number'
                                        onChange={e => onBasePriceChange(e, productIndex)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Input
                                        inset
                                        name='price'
                                        value={product.price}
                                        label='Prezzo Totale'
                                        type='number'
                                        onChange={e => onPriceChange(e, productIndex)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container alignItems='center' spacing={2} mt={3}>
                                {productListAll[productId]
                                    ? productListAll[productId]?.attributes?.map((attribute, i) => {
                                          return (
                                              <Grid item xs key={i}>
                                                  <Select
                                                      displayEmpty
                                                      fullWidth
                                                      sx={{ pr: 4 }}
                                                      name={attribute.name}
                                                      onChange={e =>
                                                          onChangeAttributes(
                                                              e,
                                                              productIndex,
                                                              productListAll[productId]
                                                          )
                                                      }
                                                      value={
                                                          attributes[productIndex]
                                                              ? attributes[productIndex][attribute.name]
                                                              : ''
                                                      }
                                                      size='small'
                                                      renderValue={v => {
                                                          if (!v)
                                                              return attribute.name || 'Attribute';
                                                          return v;
                                                      }}>
                                                      <MenuItem
                                                          value=''
                                                          sx={{
                                                              pointerEvents: 'none',
                                                              fontStyle: 'italic',
                                                          }}>
                                                          Attributi
                                                      </MenuItem>
                                                      {attribute.options.map(option => (
                                                          <MenuItem key={option} value={option}>
                                                              {option}
                                                          </MenuItem>
                                                      ))}
                                                  </Select>
                                              </Grid>
                                          );
                                      })
                                    : null}
                            </Grid>
                            <Menu
                                anchorEl={anchorElFilter}
                                open={Boolean(anchorElFilter)}
                                onClose={closeFilterMenu}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                MenuListProps={{
                                    role: 'listbox',
                                }}
                                sx={{
                                    '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                                        marginTop: 2,
                                        boxShadow:
                                            'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
                                        border: '1px solid',
                                        width: '100%',
                                        maxWidth: '780px',
                                        borderColor: 'common.white',
                                        backgroundColor: 'background.default',
                                        borderRadius: '8px',
                                        p: 1,
                                    },
                                }}>
                                <ProductTable
                                    closeMenu={closeFilterMenu}
                                    product={selectedProduct}
                                    currentProduct={group.items[productIndex]}
                                    groupIndex={groupIndex}
                                    productIndex={productIndex}
                                    attributes={attributes}
                                    setAttributes={setAttributes}
                                    setPrice={setPrice}
                                    productVariantHandler={productVariantHandler}
                                    productHandler={productHandler}
                                    quantityHandler={quantityHandler}
                                    attributesHandler={attributesHandler}
                                    priceHandler={priceHandler}
                                />
                            </Menu>

                            <Divider variant='fullWidth' sx={{ mb: 1, mt: 2 }} />
                        </Grid>
                    );
                })}
                <Grid item xs={12} xm={6}>
                    <Stack direction='row' spacing={2} alignItems='flex-start'>
                        <Button
                            variant='text'
                            sx={{ px: 2, mb: 2 }}
                            onClick={() => fileRef.current?.click()}>
                            Importa File
                        </Button>
                        {file ? (
                            <React.Fragment>
                                <Typography
                                    variant='body2'
                                    flexGrow={1}
                                    pt={0.5}
                                    sx={{
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: '1',
                                    }}>
                                    {file?.name}
                                </Typography>

                                <Image src={file} sx={{ height: 80 }} />
                            </React.Fragment>
                        ) : null}
                    </Stack>

                    <Typography variant='subtitle1' fontWeight={500} color='text.tertiary' my={2}>
                        Descrizione
                    </Typography>
                    <Input
                        name='description'
                        value={group.description}
                        sx={{ borderRadius: '8px', px: 2 }}
                        onChange={e => groupHandler(e, groupIndex)}
                        inset
                        multiline
                        minRows={5}
                        maxRows={5}
                    />
                </Grid>
            </Grid>
            <input type='file' ref={fileRef} style={{ display: 'none' }} onChange={fileHandler} />
        </ShadowCard>
    ) : null;
};

const ProductTable = memo(props => {
    const {
        closeMenu,
        product,
        currentProduct,
        groupIndex,
        productIndex,
        setAttributes,
        setPrice,
        productVariantHandler,
        productHandler,
        quantityHandler,
        attributesHandler,
        priceHandler,
    } = props;
    const [productGroup, setProductGroup] = useState({});
    const [genere, setGenere] = useState('');
    const { showError } = useMessage();

    const generes = useMemo(
        () =>
            product
                ? product.attributes?.filter(attribute => attribute.name === 'Genere')[0]
                      ?.options || []
                : [],
        [product]
    );

    const productGroupHandler = (e, i, color) => {
        const { value } = e.target;
        if (value > 99) return;

        setProductGroup(prev => {
            const selectedColor = prev[color];
            selectedColor[i] = {
                ...selectedColor[i],
                quantity: value,
                Colore: color,
            };

            return {
                ...prev,
                [color]: [...selectedColor],
            };
        });
    };

    // console.log({ currentProduct });
    const createProduct = (attributes, productIndex) => {
        const quantity = attributes.quantity;
        const attributeValues = Object.values(attributes).filter(
            attribute => attribute !== 'quantity'
        );

        outer: for (let variation of product.variations) {
            for (let attribute of variation.attributes) {
                if (attributeValues.some(value => attribute.option === value)) {
                    setPrice(prev => ({ ...prev, [productIndex]: variation.price }));
                    if (variation.price) priceHandler(variation.price, groupIndex, productIndex);
                    productVariantHandler(variation.id, groupIndex, productIndex);
                    break outer;
                }
            }
        }

        const attribute = {
            Colore: attributes.Colore,
            Genere: attributes.Genere,
            Taglia: attributes.Taglia,
        };

        productHandler(product.id, groupIndex, productIndex);
        quantityHandler(quantity, groupIndex, productIndex);
        setAttributes(prev => {
            return {
                ...prev,
                [productIndex]: attribute,
            };
        });
        attributesHandler(attribute, groupIndex, productIndex);
    };

    const onSubmit = () => {
        const filter = {};
        let index = isEmpty(currentProduct.attributes) ? productIndex : productIndex + 1;
        Object.keys(productGroup).forEach(key => {
            const filterOptions = productGroup[key].find(field => field.quantity);
            if (filterOptions) {
                filter[key] = filterOptions;
                createProduct({ ...filterOptions, Genere: genere }, index);
                index = index + 1;
            }
        });
        closeMenu();
        if (isEmpty(filter)) {
            return showError('Choose a product and increase some quantity to add in a group');
        }
    };

    useEffect(() => {
        const obj = {};
        const [colorNames] =
            product.attributes?.filter(attribute => attribute.name === 'Colore') || [];
        const [sizes] = product.attributes?.filter(attribute => attribute.name === 'Taglia') || [];
        if (!(colorNames && sizes)) return;
        colorNames.options.forEach(color => {
            obj[color] = sizes.options.map(Taglia => ({
                quantity: 0,
                Taglia,
            }));
        });

        setProductGroup(obj);
    }, [product.attributes]);

    return product ? (
        <Box>
            <Grid container>
                <Grid item xs={3}>
                    <Select
                        displayEmpty
                        fullWidth
                        sx={{ flexGrow: 1, mb: 1 }}
                        name={genere}
                        onChange={e => setGenere(e.target.value)}
                        value={genere}
                        size='small'>
                        <MenuItem
                            value=''
                            sx={{
                                pointerEvents: 'none',
                                fontStyle: 'italic',
                            }}>
                            Genere
                        </MenuItem>
                        {generes.map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs>
                    <Stack direction='row' alignItems='center' spacing={2}>
                        {product.attributes?.map(attribute => {
                            return attribute.name === 'Taglia'
                                ? attribute.options.map(option => (
                                      <Typography
                                          textAlign='center'
                                          variant='subtitle1'
                                          color='text.secondary'
                                          minWidth={45}>
                                          {option}
                                      </Typography>
                                  ))
                                : null;
                        })}
                    </Stack>
                </Grid>
            </Grid>

            <Divider variant='fullWidth' sx={{ mb: 1 }} />

            {Object.keys(productGroup).map(color => (
                <Grid container sx={{ width: '100%' }}>
                    <Grid item xs={3}>
                        <Stack direction='row' alignItems='center' spacing={2} mb={1}>
                            <Box
                                width={40}
                                height={30}
                                sx={{
                                    backgroundColor: colors[color],
                                }}
                                border='1px solid rgba(0, 0, 0, 0.5)'
                            />
                            <Typography variant='caption' color='text.secondary'>
                                {color}
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Stack direction='row' spacing={2}>
                            {productGroup[color].map((field, i) => (
                                <InputBase
                                    type='number'
                                    value={field.quantity}
                                    onChange={e => productGroupHandler(e, i, color)}
                                    sx={{
                                        width: 45,
                                        bgcolor: 'background.paper',
                                        fontSize: 15,
                                        borderRadius: 1,
                                        border: '1px solid rgba(0, 0, 0, 0.2)',
                                        '.MuiInputBase-input': {
                                            p: 0,
                                            textAlign: 'center',
                                        },
                                    }}
                                />
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
            ))}
            <Button
                variant='contained'
                onClick={onSubmit}
                disabled={!genere}
                sx={{
                    px: 7,
                    py: 1,
                    float: 'right',
                    mt: 1,
                }}>
                Confirm
            </Button>
        </Box>
    ) : (
        <h1>Loading...</h1>
    );
});

export default GroupCard;
