import Box from '@mui/material/Box';
import React from 'react';

const ShadowCard = props => {
    const { sx, children, ...rest } = props;

    return (
        <Box
            sx={{
                boxShadow: '8px 8px 8px #d2d4d685, -8px -8px 8px #ffffff82',
                backgroundColor: 'background.default',
                p: 2,
                ...sx,
            }}
            {...rest}>
            {children}
        </Box>
    );
};

export default ShadowCard;
