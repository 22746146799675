import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    IconButton,
    Stack,
    TextareaAutosize,
    Typography,
    styled,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { RightArrowIcon } from '../components/ArrowIcon';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import Search from '../components/Search';
import { useDispatch, useSelector } from 'react-redux';
import {
    setActivePanel,
    setSidePanelWidth,
    toggleSidePanel,
} from '../features/sidepanel/sidepanelSlice';
import Risposte from '../layouts/components/Risposte';
import UserInfo from '../layouts/components/UserInfo';
import Emoji from '../layouts/components/Emoji';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { setDraft, setMessage } from '../features/messages/messageSlice';

const TextArea = styled(TextareaAutosize)(({ theme }) => ({
    color: 'inherit',
    borderRadius: '10px',
    maxWidth: '100%',
    width: '100%',
    fontSize: 12,
    padding: theme.spacing(1, 3),
    transition: theme.transitions.create('width'),
    backgroundColor: '#e3e0eb',
    outline: 'none',
    border: 'none',
    resize: 'none',
}));

const SidePanel = () => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const active = useSelector(state => state.sidepanel.active);
    const draft = useSelector(state => state.message.draft);
    const caret = useRef(0);
    const toggleAccordion = () => setExpanded(!expanded);
    const [expanded, setExpanded] = useState(true);

    const emojiPicker = e => {
        try {
            const emoji = e.emoji;
            const newValue = draft.slice(0, caret.current) + emoji + draft.slice(caret.current);
            dispatch(setDraft(newValue));
            caret.current = caret.current + emoji.length;
        } catch (e) {
            console.log(e);
        }
    };

    const onChangeHandler = e => {
        caret.current = e.target.selectionStart;
        dispatch(setDraft(e.target.value));
    };

    const content = [
        <Risposte search={search} setSearch={setSearch} />,
        <Emoji open={true} expanded={expanded} emojiPicker={emojiPicker} />,
        <UserInfo expanded={expanded} />,
    ];

    return (
        <>
            <Box color='text.secondary' display='flex' flexDirection='column' p={3} height='100dvh'>
                <Stack direction='row' spacing={2}>
                    <Button
                        variant={active === 2 ? 'contained' : 'text'}
                        onClick={() => {
                            dispatch(setSidePanelWidth(450));
                            dispatch(setActivePanel(2));
                        }}
                        sx={{
                            maxWidth: '148px',
                            width: '100%',
                        }}
                        startIcon={<FolderOutlinedIcon sx={{ fontSize: '16px' }} />}>
                        Info Chat
                    </Button>
                    <Button
                        variant={active === 1 ? 'contained' : 'text'}
                        onClick={() => {
                            dispatch(setSidePanelWidth(450));
                            dispatch(setActivePanel(1));
                        }}
                        sx={{
                            maxWidth: '148px',
                            width: '100%',
                        }}
                        startIcon={<AddReactionOutlinedIcon sx={{ fontSize: '16px' }} />}>
                        Emoji
                    </Button>

                    <Button
                        variant={active === 0 ? 'contained' : 'text'}
                        onClick={() => dispatch(setActivePanel(0))}
                        sx={{
                            maxWidth: '148px',
                            width: '100%',
                        }}
                        startIcon={<FolderOutlinedIcon sx={{ fontSize: '16px' }} />}>
                        Risposte
                    </Button>
                </Stack>

                <Search
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    wrapperProps={{
                        sx: {
                            marginLeft: 'auto',
                            my: 3,
                            maxWidth: '148px',
                            width: '100%',
                        },
                    }}
                />
                <Box flexGrow={1}>{content[active]}</Box>

                <Box position='sticky' bottom={16}>
                    <Accordion
                        slotProps={{ transition: { unmountOnExit: true } }}
                        expanded={expanded}
                        onChange={toggleAccordion}
                        onClick={() => {
                            if (expanded) return setExpanded(true);
                            setExpanded(false);
                        }}
                        sx={{
                            '&.MuiAccordion-root:before': {
                                backgroundColor: 'transparent',
                            },
                        }}>
                        <AccordionSummary sx={{ mt: 0 }}>
                            <Typography variant='body2' fontWeight={500} color='text.tertiary'>
                                Anteprima Messaggio
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ py: 0, pt: 1 }}>
                            <Stack direction='row' gap={1} alignItems='center'>
                                <TextArea
                                    placeholder='Lorem ipsum dolor sit amet, consectetur'
                                    value={draft}
                                    onChange={onChangeHandler}
                                    minRows={3}
                                    maxRows={3}
                                    onMouseUp={e => (caret.current = e.target.selectionStart)}
                                    onMouseDown={e => (caret.current = e.target.selectionStart)}
                                    onKeyUp={e => (caret.current = e.target.selectionStart)}
                                    onKeyDown={e => (caret.current = e.target.selectionStart)}
                                />

                                <IconButton
                                    onClick={() => {
                                        dispatch(setMessage(draft));
                                        dispatch(setDraft(''));
                                    }}>
                                    <SendOutlinedIcon
                                        fontSize='small'
                                        color='primary'
                                        sx={{ transform: 'rotate(-40deg)' }}
                                    />
                                </IconButton>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: -35,
                            right: '10%',
                            transform: 'translate(50%, 0)',
                            width: '56px',
                            padding: 0.1,
                            backgroundColor: 'background.default',
                            borderTopLeftRadius: '18px',
                            borderTopRightRadius: '10px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                            zIndex: 100,
                            p: 1,
                        }}>
                        <DoubleArrowIcon
                            sx={{
                                transform: expanded ? 'rotate(90deg)' : 'rotate(270deg)',
                                fontSize: '22px',
                                cursor: 'pointer',
                            }}
                            onClick={toggleAccordion}
                        />
                    </Box>
                </Box>
            </Box>

            <SlideArrow
                sidePanelToggle={() => {
                    dispatch(setSidePanelWidth(450));
                    dispatch(toggleSidePanel());
                }}
            />
        </>
    );
};

export default SidePanel;

function SlideArrow({ sidePanelToggle }) {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: '30%',
                left: '-60px',
                width: '85px',
                height: '140px',
                padding: 1,
                backgroundColor: 'background.default',
                borderTopLeftRadius: '68px',
                borderBottomLeftRadius: '68px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderLeft: '1px solid',
                borderColor: 'white',
                zIndex: 100,
            }}>
            <IconButton onClick={sidePanelToggle}>
                <RightArrowIcon />
            </IconButton>
        </Box>
    );
}
