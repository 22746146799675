import {
    Box,
    Button,
    Select,
    InputBase,
    IconButton,
    Divider,
    Grid,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DataGrid from '../../components/DataGrid';
import { useMenu } from '../../hooks/useMenu';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { conditions } from '../../data/filters';
import { useDebouncedValue } from '@mantine/hooks';
import Filter from '../../components/Filter';
import { customer } from '../../data/choices';
import useErrorHandler from '../../hooks/useErrorHandler';
import { useMessage } from '../../layouts/Header';
import { setCustomer } from '../../features/customer/customerSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ContactList = () => {
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });
    const [rowCount, setRowCount] = useState(0);
    const {
        anchorEl: anchorElFilter,
        openMenu: openFilterMenu,
        closeMenu: closeFilterMenu,
    } = useMenu();
    const errorHandler = useErrorHandler();
    const { showSuccess } = useMessage();

    const [filters, setFilters] = useState([{ field: '', condition: '', value: '' }]);
    const [options, setOptions] = useState({ filter_operator: 'and', filter: false });
    const [search, setSearch] = useState('');
    const searchText = useDebouncedValue(search, 400)[0];
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onFilterHandler = (e, i) => {
        const { name, value } = e.target;
        setFilters(prev => {
            const newFilters = [...prev];
            if (name === 'field') {
                newFilters[i].condition = '';
                newFilters[i].value = '';
            }
            newFilters[i][name] = value;
            return newFilters;
        });
    };

    const addFilter = () => {
        setFilters(prev => [...prev, { field: '', condition: '', value: '' }]);
    };

    const removeFilter = i => {
        if (!filters[i]) return;
        const updated = [...filters];
        updated.splice(i, 1);
        setFilters(updated);
    };

    const { data, error, isError, isLoading, refetch } = useQuery({
        queryKey: ['customers', paginationModel.page, searchText, options.filter],
        queryFn: async () => {
            const params = {
                page: Boolean(paginationModel.page) ? paginationModel.page + 1 : 1,
                has_order: false,
            };

            if (searchText) params.filter = JSON.stringify({ search: searchText });

            if (options.filter)
                params.filter = JSON.stringify({
                    fields: filters,
                    filter_operator: options.filter_operator,
                    // ...(searchText ? { search: searchText } : {}),
                });

            const response = await axios.get('/customers/', { params });
            setRowCount(response.data.count);
            return response.data;
        },
    });

    const customers = useMemo(() => (data ? data.results : []), [data]);

    useEffect(() => {
        setRowCount(prev => (data?.count !== undefined ? data?.count : prev));
    }, [data?.count, setRowCount]);

    const changePriorità = useCallback(
        async (id, priority) => {
            try {
                await axios.patch(`/customers/${id}/`, { priority });

                showSuccess(`Priorità cambiato in  ${priority}`);
                refetch();
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, refetch, showSuccess]
    );

    const changeType = useCallback(
        async (id, customer_type) => {
            try {
                await axios.patch(`/customers/${id}/`, { customer_type });

                showSuccess(`Tipo Cliente cambiato in  ${customer_type}`);
                refetch();
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, refetch, showSuccess]
    );

    const getCustomer = useCallback(
        async customerId => {
            try {
                const response = await axios.get(`/customers/${customerId}/`);
                dispatch(setCustomer(response.data));
                navigate(`/dashboard/${customerId}`);
            } catch (e) {
                errorHandler(e);
            }
        },
        [errorHandler, dispatch, navigate]
    );

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: 'Id',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 100,
            },
            {
                field: 'site',
                headerName: 'Site',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 300,
                renderCell: params => (params.row.site[0] ? params.row.site[0].name : null),
            },
            {
                field: 'email',
                headerName: 'Email',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 250,
            },
            {
                field: 'username',
                headerName: 'Username',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 250,
            },
            {
                field: 'first_name',
                headerName: 'Nome',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
            },
            {
                field: 'last_name',
                headerName: 'Cognome',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
            },
            {
                field: 'phone',
                headerName: 'Telefono',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
            },
            {
                field: 'blocked',
                headerName: 'Blocked',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 150,
            },
            {
                field: 'priority',
                headerName: 'Priorità',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <Select
                        value={params.row.priority}
                        size='small'
                        fullWidth
                        onChange={e => changePriorità(params.id, e.target.value)}>
                        {customer.priority.map(value => (
                            <MenuItem value={value} sx={{ textTransform: 'capitalize' }}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                ),
            },
            {
                field: 'customer_type',
                headerName: 'Tipo Cliente',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 200,
                renderCell: params => (
                    <Select
                        value={params.row.customer_type}
                        size='small'
                        fullWidth
                        onChange={e => changeType(params.id, e.target.value)}>
                        {customer.customer_type.map(value => (
                            <MenuItem value={value} sx={{ textTransform: 'capitalize' }}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                ),
            },
            {
                field: 'note',
                headerName: 'Note',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                width: 400,
            },
        ],
        [changePriorità, changeType]
    );

    if (isError) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}>
                <Typography variant='h5' sx={{ color: 'error.main' }}>
                    {error.message}
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <Box p={2} my={4}>
                <Typography variant='h5'>Contact List</Typography>
                <Divider variant='fullWidth' sx={{ my: 2 }} />

                <Box bgcolor='#DBDBF0' p={1} mb={4} borderRadius='50px'>
                    <Grid container columnSpacing={2} alignItems='center'>
                        <Grid item xs={12} md>
                            <Typography variant='subtitle1' fontWeight={500}>
                                Visualizzati <b> {customers.length} Contatti </b>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md='auto'>
                            <Filter
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                                onClick={openFilterMenu}
                                wrapperProps={{
                                    sx: {
                                        marginLeft: 'auto',
                                        maxWidth: '188px',
                                        width: '100%',
                                        border: '2px solid white',
                                        boxShadow: 'none',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <DataGrid
                    rows={customers}
                    columns={columns}
                    autoHeight
                    onRowClick={async params => {
                        await getCustomer(params.id);
                    }}
                    rowSelection={false}
                    disableSelectionOnClick
                    loading={isLoading}
                    rowCount={rowCount}
                    checkboxSelection={false}
                    pageSizeOptions={[40]}
                    paginationModel={paginationModel}
                    paginationMode='server'
                    onPaginationModelChange={setPaginationModel}
                />
                <Menu
                    anchorEl={anchorElFilter}
                    open={Boolean(anchorElFilter)}
                    onClose={closeFilterMenu}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    // type='glassMorphism'

                    sx={{
                        marginTop: '20px',
                        '& ul.MuiList-root.MuiMenu-list': {
                            padding: 0,
                        },
                        '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
                            width: 'min(100%, 1000px)',
                            boxShadow:
                                'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
                            border: '1px solid',
                            borderColor: 'white',
                            backgroundColor: 'background.default',
                            borderRadius: '8px',
                            p: 0,
                            overflowY: 'unset',
                        },
                    }}>
                    <Box p={1.6}>
                        {filters.map((filter, i) => (
                            <Stack direction='row' spacing={2} padding={1.4}>
                                <Select
                                    displayEmpty
                                    name='field'
                                    value={filter.field}
                                    onChange={e => onFilterHandler(e, i)}
                                    fullWidth
                                    renderValue={v => {
                                        if (!v) return 'Colonna';
                                        return v;
                                    }}
                                    sx={{ borderRadius: 1.5, border: '2px solid white' }}>
                                    {columns.map(col => (
                                        <MenuItem value={col.field} key={col.field}>
                                            {col.headerName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    displayEmpty
                                    name='condition'
                                    value={filter.condition}
                                    onChange={e => onFilterHandler(e, i)}
                                    fullWidth
                                    size='small'
                                    renderValue={v => {
                                        if (!v) return 'Condizione';
                                        return v;
                                    }}
                                    sx={{ borderRadius: 1.5, border: '2px solid white' }}>
                                    {conditions.map(cond => (
                                        <MenuItem value={cond.value} key={cond.name}>
                                            {cond.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {['customer_type', 'priority'].includes(filter.field) ? (
                                    <Select
                                        displayEmpty
                                        name='Valore'
                                        value={filter.value}
                                        onChange={e => onFilterHandler(e, i)}
                                        fullWidth
                                        renderValue={v => {
                                            if (!v) return 'Value';
                                            return v;
                                        }}
                                        sx={{ borderRadius: 1.5, border: '2px solid white' }}>
                                        {customer[filter.field].map(value => (
                                            <MenuItem
                                                value={value}
                                                key={value}
                                                sx={{ textTransform: 'capitalize' }}>
                                                {value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                ) : (
                                    <InputBase
                                        name='Valore'
                                        placeholder='Valore'
                                        value={filter.value}
                                        size='small'
                                        onChange={e => onFilterHandler(e, i)}
                                        fullWidth
                                        sx={{ borderRadius: 1.5, border: '2px solid white', px: 1 }}
                                    />
                                )}
                                <IconButton
                                    disabled={i === 0}
                                    onClick={() => removeFilter(i)}
                                    color='text.tertiary'>
                                    <DeleteTwoToneIcon />
                                </IconButton>
                            </Stack>
                        ))}
                    </Box>
                    <Stack
                        direction='row'
                        p={2}
                        justifyContent='space-between'
                        alignItems='center'
                        sx={{ backgroundColor: 'white' }}>
                        <Stack direction='row' spacing={1.5}>
                            <Button
                                variant={options.filter_operator === 'and' ? 'contained' : 'filter'}
                                onClick={() =>
                                    setOptions(prev => ({ ...prev, filter_operator: 'and' }))
                                }
                                sx={{ borderRadius: 1.5 }}>
                                E
                            </Button>
                            <Button
                                variant={options.filter_operator === 'or' ? 'contained' : 'filter'}
                                onClick={() =>
                                    setOptions(prev => ({ ...prev, filter_operator: 'or' }))
                                }
                                sx={{ borderRadius: 1.5 }}>
                                O
                            </Button>
                            <Divider orientation='vertical' flexItem variant='middle' />
                            <Button
                                variant='text'
                                startIcon={<AddIcon />}
                                color='primary'
                                onClick={addFilter}
                                sx={{
                                    '&, &:hover': {
                                        boxShadow: 'none',
                                        borderRadius: '0',
                                    },
                                }}>
                                Nuovo Filtro
                            </Button>
                        </Stack>
                        <Box>
                            <Button
                                variant='filter'
                                sx={{ minWidth: 120, borderRadius: 1.5, mr: 1 }}
                                onClick={() => {
                                    setOptions(prev => ({ ...prev, filter: false }));
                                    setFilters([{ field: '', condition: '', value: '' }]);
                                    closeFilterMenu();
                                }}>
                                Svuota
                            </Button>
                            <Button
                                variant='contained'
                                sx={{ minWidth: 120, borderRadius: 1.5 }}
                                onClick={() => {
                                    const hasValues = filters.every(
                                        filter => filter.field && filter.condition
                                    );
                                    if (!hasValues) return;
                                    setOptions(prev => ({ ...prev, filter: true }));
                                    refetch();
                                    closeFilterMenu();
                                }}>
                                Crea
                            </Button>
                        </Box>
                    </Stack>
                </Menu>
            </Box>
        </>
    );
};

export default ContactList;
