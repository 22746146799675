import { useState } from 'react';

const useToggle = initial => {
    const [state, setState] = useState(initial ?? false);

    const toggle = () => setState(!state);

    return [state, toggle];
};

export default useToggle;
