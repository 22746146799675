import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedTab: 0,
};

export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload;
        },
    },
});

export const { setSelectedTab } = userInfoSlice.actions;
export default userInfoSlice.reducer;
