import { Button, Card, Divider, Grid, Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useMessage } from '../../layouts/Header';
import useErrorHandler from '../../hooks/useErrorHandler';
import axios from 'axios';
import OutsetBox from '../../components/OutsetBox';

const CustomerView = props => {
    const { closeModal, refetch, customer } = props;
    const { showSuccess } = useMessage();
    const errorHandler = useErrorHandler();

    const mergeSimilarCustomer = useCallback(
        async merge_type => {
            try {
                await axios.post(`/similar-customer/merge/`, {
                    from_customer: customer.similar_customer.id,
                    to_customer: customer.customer.id,
                    merge_type,
                });
                showSuccess('Merged');
            } catch (e) {
                errorHandler(e);
            } finally {
                refetch();
                closeModal();
            }
        },
        [errorHandler, customer, refetch, closeModal, showSuccess]
    );

    return (
        <Card
            sx={{
                p: 2.5,
                bgcolor: 'background.default',
                borderRadius: 2,
                maxWidth: '895px',
                width: '100%',
            }}>
            <Typography variant='subtitle1' fontWeight={500} mb={2}>
                CUSTOMER
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <OutsetBox label='Nome' value={customer.customer.first_name} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <OutsetBox label='Cognome' value={customer.customer.last_name} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <OutsetBox label='Email' value={customer.customer.email} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <OutsetBox label='Telefono' value={customer.customer.phone} />
                </Grid>
            </Grid>
            <Divider variant='middle' sx={{ my: 2.5 }} />
            <Typography variant='subtitle1' fontWeight={500} mb={2}>
                SIMILAR CUSTOMER
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <OutsetBox label='Nome' value={customer.similar_customer.first_name} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <OutsetBox label='Cognome' value={customer.similar_customer.last_name} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <OutsetBox label='Email' value={customer.similar_customer.email} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <OutsetBox label='Telefono' value={customer.similar_customer.phone} />
                </Grid>
            </Grid>
            <Stack direction='row' gap={2} justifyContent='flex-end' mt={2}>
                <Button variant='text' onClick={closeModal}>
                    {customer.merged ? 'Close' : 'Cancel'}
                </Button>
                {customer.merged ? null : (
                    <>
                        <Button
                            variant='contained'
                            onClick={() => mergeSimilarCustomer('add_data')}>
                            Merge with primary contacts
                        </Button>

                        <Button
                            variant='contained'
                            onClick={() => mergeSimilarCustomer('additional_contact')}>
                            Merge with secondary contacts
                        </Button>
                    </>
                )}
            </Stack>
        </Card>
    );
};

export default CustomerView;
